enum StatusType {
    Research,
    Planning,
    Todo,
    InProgress,
    Review,
    QA,
    Done,
    NotDone,
}

export function mapStatusTypeToFriendlyName(statusType: StatusType): string {
    switch (statusType) {
        case 0:
            return "Research";
        case 1:
            return "Planning";
        case 2:
            return "TODO";
        case 3:
            return "In Progress";
        case 4:
            return "Review";
        case 5:
            return "QA";
        case 6:
            return "Done";
        case 7:
            return "Not Done";
        default:
            return statusType.toString();
    }
}

export default StatusType;
