import { Table } from "antd";
import React from "react";
import StatusType, { mapStatusTypeToFriendlyName } from "../../../../common/types/StatusType";
import IssueTypePill from "../../../../common/components/IssueTypePill";
import IssueType from "../../types/IssueType";
import { IssueDto } from "../../../../store/generated/issue";
import { DETAILS_PANE_HEIGHT } from "./IssueDetailsModal";

const columns = [
    {
        title: "",
        dataIndex: "projectIssueKeyedLabel",
        key: "type",
    },
    {
        title: "Type",
        dataIndex: "issueType",
        key: "type",
        render: (text: string) => <IssueTypePill issueType={text as unknown as IssueType} />,
    },
    {
        title: "Title",
        dataIndex: "title",
        key: "title",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (statusType: StatusType) => mapStatusTypeToFriendlyName(statusType),
    },
];

type Props = {
    issues: IssueDto[];
};

export function RelatedIssuesComponent(props: Props) {
    return (
        <Table
            style={{ marginBottom: 15 }}
            dataSource={props.issues}
            columns={columns}
            rowKey="issueKey"
            scroll={{ y: DETAILS_PANE_HEIGHT - 4 * 40 }}
            // @ts-ignore
            rowClassName={(record, index) => (record.status === StatusType.Done ? "completed-issue" : undefined)}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {},
                };
            }}
        />
    );
}
