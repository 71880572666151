import { Tag } from "antd";
import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import Config from "../../../../config.json";

export function TicketPill(props: { issueKey: string | undefined }) {
    return (
        <a
            onClick={() => {
                navigator.clipboard.writeText(
                    `${Config.WEB_URL}/backlog-management/backlog?selectedIssue=${props.issueKey}`
                );
            }}
        >
            <Tag
                color="#108ee9"
                style={{ verticalAlign: "bottom", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
            >
                {props.issueKey}
            </Tag>
            <CopyOutlined />
        </a>
    );
}
