import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
    useDeleteSettingsTenantDeleteMutation,
    useGetSettingsTenantGetQuery,
    usePostSettingsTenantAddMutation,
    usePutSettingsTenantUpdateMutation,
} from "../api/TenantApi";
import { AddSettingsTenantDto } from "../../../../store/generated/settings/tenant";
import { Form, Input } from "antd";
import { DetailsFormToolbar } from "../../../../common/components/DetailsFormToolbar";
import useFormValidation from "../../../../common/components/useFormValidation";

const TenantDetails = () => {
    const [form] = Form.useForm();
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const navigate = useNavigate();
    const tenantKey = new URLSearchParams(useLocation().search).get("tenantKey");
    const [tenant, setTenant] = useState<AddSettingsTenantDto | undefined>(undefined);
    const { data, error, isLoading } = useGetSettingsTenantGetQuery({ tenantKey: tenantKey!! }, { skip: !tenantKey });
    useEffect(() => {
        if (data) {
            setTenant(data);
        }
    }, [data]);
    const [addTenant, addTenantResult] = usePostSettingsTenantAddMutation();
    const [updateTenant, updateTenantResult] = usePutSettingsTenantUpdateMutation();
    const [deleteTenant, deleteResult] = useDeleteSettingsTenantDeleteMutation();
    useFormValidation({ form, validationResult: (addTenantResult ?? updateTenantResult ?? deleteResult) as any });
    useEffect(() => {
        if (addTenantResult.isSuccess || updateTenantResult.isSuccess) {
            navigate(-1);
        }
    }, [addTenantResult, updateTenantResult]);
    useEffect(() => {
        if (deleteResult.isSuccess) {
            navigate(-1);
        }
    }, [deleteResult]);
    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
        setTenant({
            ...tenant,
            [property]: e.target.value,
        });
    };
    const onCancel = () => {
        navigate(-1);
    };
    const onFinish = (values: any) => {
        if (tenantKey) {
            updateTenant({ existingSettingsTenantDto: { tenantKey, ...values } });
        } else {
            addTenant({ addSettingsTenantDto: { ...values } });
        }
    };

    const onDelete = () => {
        deleteTenant({ tenantKey: tenant?.tenantKey!! });
    };

    return !tenantKey || tenant ? (
        <Form
            {...layout}
            initialValues={tenant}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{ maxWidth: 600, paddingTop: 15 }}
        >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input type="text" onChange={(e) => onValueChange(e, "name")} />
            </Form.Item>
            <Form.Item name="uriName" label="Uri" rules={[{ required: true }]}>
                <Input type="text" onChange={(e) => onValueChange(e, "uriName")} />
            </Form.Item>
            <DetailsFormToolbar
                tailLayout={tailLayout}
                onCancel={onCancel}
                onDelete={onDelete}
                onSubmit={() => form.submit()}
            />
        </Form>
    ) : undefined;
};

export default TenantDetails;
