import React, { useState } from "react";
import { Button, Form, Upload, UploadFile, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";

type Props = {
    onFileChange: (file: any) => any;
};
const FileSelector = (props: Props) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const uploadProps: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            props.onFileChange(file);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            props.onFileChange(file);
            return false;
        },
        fileList: fileList,
    };

    return (
        <>
            <div>
                <label htmlFor="file" className="sr-only">
                    Choose a file
                </label>
                <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
            </div>
        </>
    );
};

export default FileSelector;
