import { enhancedApi as api } from "../../../store/generated/issue";

const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ["Issue", "IssueSubTasks"],
    endpoints: {
        getIssueGet: {
            providesTags: ["Issue"],
        },
        getIssueListEpics: {
            providesTags: ["Issue"],
        },
        getIssueList: {
            providesTags: ["Issue"],
        },
        postIssueSave: {
            invalidatesTags: ["Issue"],
        },
        deleteIssueDelete: {
            invalidatesTags: ["Issue"],
        },
        putIssueUpdateIssueOrder: {
            invalidatesTags: ["Issue"],
        },
        postIssueClone: {
            invalidatesTags: ["Issue"],
        },
        postIssueImportExecute: {
            invalidatesTags: ["Issue"],
        },
        putIssueAssignToCurrentUser: {
            invalidatesTags: ["Issue"],
        },
        putIssueSendToBacklog: {
            invalidatesTags: ["Issue"],
        },
        putIssueSendToCurrentSprint: {
            invalidatesTags: ["Issue"],
        },
    },
});

export const {
    useGetIssueGetQuery,
    usePutIssueUpdateIssueOrderMutation,
    useGetIssueListQuery,
    usePostIssueSaveMutation,
    useDeleteIssueDeleteMutation,
    usePostIssueCloneMutation,
    usePutIssueSendToCurrentSprintMutation,
    usePutIssueSendToBacklogMutation,
    usePutIssueAssignToCurrentUserMutation,
    usePostIssueImportExecuteMutation,
    useGetIssueListEpicsQuery,
    useLazyGetIssueGetQuery,
    useLazyGetIssueListEpicsQuery,
    useLazyGetIssueListQuery,
} = enhancedApi;
