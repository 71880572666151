import { emptySplitApi as api } from "../../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSettingsTeamGet: build.query<GetSettingsTeamGetApiResponse, GetSettingsTeamGetApiArg>({
            query: (queryArg) => ({
                url: `/settings/Team/Get`,
                params: { teamKey: queryArg.teamKey },
            }),
        }),
        getSettingsTeamList: build.query<GetSettingsTeamListApiResponse, GetSettingsTeamListApiArg>({
            query: () => ({ url: `/settings/Team/List` }),
        }),
        postSettingsTeamAdd: build.mutation<PostSettingsTeamAddApiResponse, PostSettingsTeamAddApiArg>({
            query: (queryArg) => ({
                url: `/settings/Team/Add`,
                method: "POST",
                body: queryArg.addSettingsTeamDto,
            }),
        }),
        putSettingsTeamUpdate: build.mutation<PutSettingsTeamUpdateApiResponse, PutSettingsTeamUpdateApiArg>({
            query: (queryArg) => ({
                url: `/settings/Team/Update`,
                method: "PUT",
                body: queryArg.existingSettingsTeamDto,
            }),
        }),
        deleteSettingsTeamDelete: build.mutation<DeleteSettingsTeamDeleteApiResponse, DeleteSettingsTeamDeleteApiArg>({
            query: (queryArg) => ({
                url: `/settings/Team/Delete`,
                method: "DELETE",
                params: { teamKey: queryArg.teamKey },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetSettingsTeamGetApiResponse = /** status 200 Success */ ExistingSettingsTeamDto;
export type GetSettingsTeamGetApiArg = {
    teamKey?: string;
};
export type GetSettingsTeamListApiResponse = /** status 200 Success */ ExistingSettingsTeamDto[];
export type GetSettingsTeamListApiArg = void;
export type PostSettingsTeamAddApiResponse = /** status 200 Success */ string;
export type PostSettingsTeamAddApiArg = {
    addSettingsTeamDto: AddSettingsTeamDto;
};
export type PutSettingsTeamUpdateApiResponse = /** status 200 Success */ undefined;
export type PutSettingsTeamUpdateApiArg = {
    existingSettingsTeamDto: ExistingSettingsTeamDto;
};
export type DeleteSettingsTeamDeleteApiResponse = /** status 200 Success */ undefined;
export type DeleteSettingsTeamDeleteApiArg = {
    teamKey?: string;
};
export type MethodologyType = 0 | 1 | 2;
export type TenantType = 0;
export type Tenant = {
    tenantKey?: string;
    uriName?: string | null;
    name?: string | null;
    tenantType?: TenantType;
    users?: User[] | null;
};
export type AccessType = 0 | 1 | 2 | 3 | 4 | 5;
export type Role = {
    roleKey?: string;
    name?: string | null;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string | null;
    isDeleted?: boolean;
    accessType?: AccessType;
    users?: User[] | null;
};
export type Team = {
    teamKey?: string;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string | null;
};
export type User = {
    userKey?: string;
    lastName?: string | null;
    firstName?: string | null;
    email?: string | null;
    tenant?: Tenant[] | null;
    roles?: Role[] | null;
    teams?: Team[] | null;
};
export type ExistingSettingsTeamDto = {
    teamKey?: string;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type AddSettingsTeamDto = {
    teamKey?: string | null;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string;
};
export const {
    useGetSettingsTeamGetQuery,
    useGetSettingsTeamListQuery,
    usePostSettingsTeamAddMutation,
    usePutSettingsTeamUpdateMutation,
    useDeleteSettingsTeamDeleteMutation,
} = injectedRtkApi;
