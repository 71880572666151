import { Button, Checkbox } from "antd";
import React from "react";
import IssueType from "../../types/IssueType";
import { isNumber } from "lodash";
import { useSelector } from "react-redux";
import { getDefaultIssueTypeKey } from "../../selectors/BacklogSelectors";

type Props = {
    isUpdatingDefaultIssueType: boolean;
    onIssueTypeSelected: (issueType: IssueType) => void;
    onDefaultClick: React.MouseEventHandler<HTMLElement>;
};

const mapLabelValueToIssueType = (issueType: IssueType): string => {
    switch (issueType) {
        case IssueType.Initiative:
            return "Initiative";
        case IssueType.Epic:
            return "Epic";
        case IssueType.Story:
            return "Story";
        case IssueType.Task:
            return "Task";
        case IssueType.Bug:
            return "Bug";
    }
    throw new Error("Unsupported type");
};

const options = Object.values(IssueType)
    .filter((x) => isNumber(x))
    .map((x) => {
        return { value: x, label: mapLabelValueToIssueType(x as unknown as IssueType) };
    });

export const IssueWizardSelectIssueType = (props: Props) => {
    const defaultIssueType = useSelector(getDefaultIssueTypeKey);

    function onIssueTypeSelect(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        // @ts-ignore
        props.onIssueTypeSelected(
            options.find((x) => x.value == e.currentTarget.id.replace("btn-", ""))?.value as IssueType
        );
    }

    return (
        <>
            {options.map((x) => {
                return (
                    <Button
                        key={x.value.toString(10)}
                        className={defaultIssueType === x.value ? "btn-current-default" : undefined}
                        onClick={onIssueTypeSelect}
                        id={"btn-" + x.value}
                        style={{ marginBottom: 5 }}
                    >
                        {x.label}
                    </Button>
                );
            })}
            <Checkbox
                style={{ paddingTop: 10, alignSelf: "flex-end" }}
                onClick={props.onDefaultClick}
                checked={props.isUpdatingDefaultIssueType}
            >
                Use as Default
            </Checkbox>
        </>
    );
};
