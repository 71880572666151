import { Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableCompanies, getSelectedCompany } from "../../../common/selectors/CompanySelectors";
import { setCurrentCompany } from "../../../common/reducers/CompanySlice";
import { ExistingSettingsTenantDto } from "../../../store/generated/settings/tenant";
import { resetBacklogState } from "../../backlog-management/reducers/BacklogSlice";
import { emptySplitApi } from "../../../store/emptyApi";

const CompanySelect = () => {
    const selectedCompany = useSelector(getSelectedCompany);
    const availableCompanies = useSelector(getAvailableCompanies);
    const dispatch = useDispatch();
    const items = useMemo(() => {
        return availableCompanies.map((availableCompany: any) => {
            return {
                key: availableCompany.tenant.tenantKey,
                label: availableCompany.tenant.name,
            };
        });
    }, [availableCompanies]);
    const onSelectCompany = (e: any) => {
        dispatch(emptySplitApi.util.resetApiState());
        dispatch(resetBacklogState());
        dispatch(setCurrentCompany(e.key));
    };

    return (
        <Dropdown menu={{ items, onClick: onSelectCompany, selectable: true }} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    {selectedCompany?.name ?? "Select Company"}
                    <DownOutlined />
                </Space>
            </a>
        </Dropdown>
    );
};

export default CompanySelect;
