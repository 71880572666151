import React from "react";
import { useNavigate } from "react-router";
import { Table } from "antd";
import SettingsToolbar from "../../SettingsToolbar";
import { useGetSettingsRoleListQuery } from "../api/RoleApi";
import { ExistingSettingsRoleDto } from "../../../../store/generated/settings/role";

const RoleListPage = (props: any) => {
    const { data, error, isLoading } = useGetSettingsRoleListQuery();
    const navigate = useNavigate();
    const onEdit = (role?: ExistingSettingsRoleDto) => {
        if (role) {
            navigate(`/settings/role/details?roleKey=${role?.roleKey ?? ""}`);
        } else {
            navigate(`/settings/role/details`);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
    ];

    return (
        <>
            <SettingsToolbar onClick={() => onEdit()} />
            <Table
                dataSource={data}
                columns={columns}
                rowKey={(record) => record.roleKey!!}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            onEdit(record);
                        }, // click row
                    };
                }}
            />
        </>
    );
};

export default RoleListPage;
