import { useDispatch, useSelector } from "react-redux";
import { List } from "antd";
import { clearFilteredStatus, setFilteredStatus } from "../../reducers/BacklogSlice";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { getFilteredStatus } from "../../selectors/BacklogSelectors";
import StatusType, { mapStatusTypeToFriendlyName } from "../../../../common/types/StatusType";

const StatusDrawerFilter = () => {
    const dispatch = useDispatch();
    const filteredStatus = useSelector(getFilteredStatus);
    const items = Object.values(StatusType)
        .filter((x) => Number(x) >= 0)
        .map((x) => ({
            key: x.toString(),
            label: mapStatusTypeToFriendlyName(x as StatusType).toString() ?? [],
            isFiltered: (filteredStatus as any as StatusType)?.toString() === x.toString(),
        }));

    const handleStatusClick = (status: {
        key: string | undefined | null;
        label: string | null | undefined;
        isFiltered: boolean;
    }) => {
        if (status.isFiltered) {
            dispatch(clearFilteredStatus());
        } else {
            dispatch(setFilteredStatus(status.key));
        }
    };

    return (
        <List
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item, index) => (
                <List.Item
                    onClick={() => handleStatusClick(item)}
                    extra={item.isFiltered ? <FilterFilled /> : undefined}
                >
                    <List.Item.Meta title={item?.label} />
                </List.Item>
            )}
        />
    );
};

export default StatusDrawerFilter;
