import { RootState } from "../../index";

export const getSelectedCompanyKey = (state: RootState) => {
    return state.company.selectedCompanyKey;
};

export const getSelectedCompany = (state: RootState) => {
    return state.company.availableCompanies.find((x: any) => x.tenantKey === state.company.selectedCompanyKey);
};

export const getAvailableCompanies = (state: RootState) => {
    return state.company.availableCompanies;
};

export const getSelectedRole = (state: RootState) => {
    return state.company.availableRoles.find((x: any) => x.roleKey === state.company.selectedRoleKey);
};

export const getAvailableRoles = (state: RootState) => {
    return state.company.availableRoles;
};
