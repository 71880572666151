import { Table } from "antd";
import React from "react";
import ReportStatusIndicator from "./ReportStatusIndicator";
import { Content } from "antd/es/layout/layout";

const columns = [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
    },
    {
        title: "",
        dataIndex: "status",
        render: (status: "Queued" | "Processing" | "Available" | "Error") => <ReportStatusIndicator status={status} />,
    },
];

type ReportDto = {
    reportKey: string;
    name: string;
    status: "Queued" | "Processing" | "Available" | "Error";
};

const ReportsPage = () => {
    const reports = [
        {
            reportKey: "123",
            name: "Sprint Summary - Dachshund",
            status: "Queued",
        },
        {
            reportKey: "124",
            name: "Sprint Summary - Papin",
            status: "Available",
        },
        {
            reportKey: "125",
            name: "Individual Metrics - Nate Almgren",
            status: "Processing",
        },
        {
            reportKey: "126",
            name: "Individual Metrics - Jake Filan",
            status: "Error",
        },
    ] as ReportDto[];
    return (
        <Content style={{ marginTop: 15 }}>
            <Table
                style={{ marginBottom: 15 }}
                dataSource={reports}
                columns={columns}
                rowKey="reportKey"
                // @ts-ignore
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {},
                    };
                }}
            />
        </Content>
    );
};

export default ReportsPage;
