import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
    useDeleteSettingsProjectDeleteMutation,
    useGetSettingsProjectGetQuery,
    usePostSettingsProjectAddMutation,
    usePutSettingsProjectUpdateMutation,
} from "../api/ProjectApi";
import { Form, Input } from "antd";
import { DetailsFormToolbar } from "../../../../common/components/DetailsFormToolbar";
import { ExistingSettingsProjectDto } from "../../../../store/generated/settings/project";
import useFormValidation from "../../../../common/components/useFormValidation";

const ProjectDetailsPage = () => {
    const [form] = Form.useForm();
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const navigate = useNavigate();
    const projectKey = new URLSearchParams(useLocation().search).get("projectKey");
    const [project, setProject] = useState<ExistingSettingsProjectDto | undefined>(undefined);
    const { data, error, isLoading } = useGetSettingsProjectGetQuery(
        { projectKey: projectKey!! },
        { skip: !projectKey }
    );
    useEffect(() => {
        if (data) {
            setProject(data);
        }
    }, [data]);
    const [updateProject, updateProjectResult] = usePutSettingsProjectUpdateMutation();
    const [addProject, addProjectResult] = usePostSettingsProjectAddMutation();
    const [deleteProject, deleteResult] = useDeleteSettingsProjectDeleteMutation();
    useFormValidation({ form, validationResult: (addProjectResult ?? updateProjectResult ?? deleteResult) as any });

    useEffect(() => {
        if (updateProjectResult.isSuccess || addProjectResult.isSuccess) {
            navigate(-1);
        }
    }, [updateProject, addProjectResult]);
    useEffect(() => {
        if (deleteResult.isSuccess) {
            navigate(-1);
        }
    }, [deleteResult]);
    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
        setProject({
            ...project,
            [property]: e.target.value,
        });
    };
    const onCancel = () => {
        navigate(-1);
    };
    const onFinish = (values: any) => {
        if (projectKey) {
            updateProject({ existingSettingsProjectDto: { projectKey, ...values } });
        } else {
            addProject({ addSettingsProjectDto: { ...values } });
        }
    };

    const onDelete = () => {
        deleteProject({ projectKey: project?.projectKey!! });
    };

    return !projectKey || project ? (
        <Form
            {...layout}
            initialValues={project}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{ maxWidth: 600, paddingTop: 15 }}
        >
            <Form.Item name="abbreviation" label="Abbreviation" rules={[{ required: true }]}>
                <Input type="text" onChange={(e) => onValueChange(e, "abbreviation")} />
            </Form.Item>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input type="text" onChange={(e) => onValueChange(e, "name")} />
            </Form.Item>
            <DetailsFormToolbar
                tailLayout={tailLayout}
                onCancel={onCancel}
                onDelete={onDelete}
                onSubmit={() => form.submit()}
            />
        </Form>
    ) : undefined;
};

export default ProjectDetailsPage;
