import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredTeam } from "../../../areas/backlog-management/selectors/BacklogSelectors";
import { useGetSettingsTeamListQuery } from "../../../store/generated/settings/team";
import { clearFilteredTeam, setFilteredTeam } from "../../../areas/backlog-management/reducers/BacklogSlice";
import { TeamDto } from "../../../store/generated/issue";

type Props = {
    teams: TeamDto[];
};

export const NavMenuTeamSelect = (props: Props) => {
    const selectedTeam = useSelector(getFilteredTeam);
    const dispatch = useDispatch();

    const teams = props.teams;
    return (
        <Select
            value={
                selectedTeam
                    ? {
                          value: selectedTeam,
                          label: teams.find((x) => x.teamKey === selectedTeam.key)?.name,
                      }
                    : undefined
            }
            popupMatchSelectWidth={false}
            defaultOpen={false}
            optionLabelProp="label"
            onSelect={(e) => {
                const team = teams.find((x) => x.teamKey === (e as any))!!;
                dispatch(setFilteredTeam({ key: team.teamKey, label: team.name }));
            }}
            onClear={() => dispatch(clearFilteredTeam())}
            allowClear={true}
            options={teams.map((team) => ({
                value: team.teamKey,
                label: team.name,
            }))}
            style={{ alignSelf: "center", maxWidth: 140, width: 140, textAlign: "center" }}
        />
    );
};
