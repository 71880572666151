import React from "react";
import { SettingsBreadcrumb } from "./SetttingsBreadcrumb";
import { Divider, List, Typography } from "antd";
import { useNavigate } from "react-router";
import { CommonStyles } from "../../common/Styles";
const globalSettings = [
    "Features",
    "Customers",
    "Access", // TODO: This is a different (super admin) user perspective
];
const companySettings = ["Projects", "Users", "Teams", "Roles", "Sprints"];
const Settings = () => {
    const navigate = useNavigate();
    return (
        <>
            <SettingsBreadcrumb />
            <Divider orientation="left">Global</Divider>
            {/* TODO: only show if user is super admin */}
            <List
                bordered
                dataSource={globalSettings}
                renderItem={(item) => (
                    <List.Item style={CommonStyles.link} onClick={() => navigate(item.toLowerCase())} key={item}>
                        <Typography.Text>{item}</Typography.Text>
                    </List.Item>
                )}
            />
            {/* TODO: Display company name */}
            <Divider orientation="left">Company</Divider>
            <List
                bordered
                dataSource={companySettings}
                renderItem={(item) => (
                    <List.Item style={CommonStyles.link} onClick={() => navigate(item.toLowerCase())} key={item}>
                        <Typography.Text>{item}</Typography.Text>
                    </List.Item>
                )}
            />
        </>
    );
};

export default Settings;
