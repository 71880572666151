import React from "react";
import { Button, Checkbox } from "antd";
import { useGetSettingsTeamListQuery } from "../../../settings/teams/api/TeamApi";
import { useSelector } from "react-redux";
import { getDefaultTeamKey } from "../../selectors/BacklogSelectors";
import { TeamDto } from "../../../../store/generated/issue";

type Props = {
    isUpdatingDefaultTeam: boolean;
    onTeamSelected: (team: TeamDto) => void;
    onDefaultClick: React.MouseEventHandler<HTMLElement>;
};

export const IssueWizardTeamSelect = (props: Props) => {
    const defaultTeamKey = useSelector(getDefaultTeamKey);

    const { data, error, isLoading } = useGetSettingsTeamListQuery();
    return data ? (
        <>
            {data.map((x) => {
                return (
                    <Button
                        key={x.teamKey}
                        className={defaultTeamKey === x.teamKey ? "btn-current-default" : undefined}
                        onClick={() => props.onTeamSelected(x as TeamDto)}
                        style={{ marginBottom: 5 }}
                    >
                        {x.name}
                    </Button>
                );
            })}
            <Checkbox
                style={{ paddingTop: 10, alignSelf: "flex-end" }}
                onClick={props.onDefaultClick}
                checked={props.isUpdatingDefaultTeam}
            >
                Use as Default
            </Checkbox>
        </>
    ) : undefined;
};
