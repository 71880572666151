import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
    useDeleteSettingsSprintDeleteMutation,
    useGetSettingsSprintGetQuery,
    usePostSettingsSprintAddMutation,
    usePutSettingsSprintUpdateMutation,
} from "../api/SprintApi";
import { DatePicker, Form, Input, Select } from "antd";
import { DetailsFormToolbar } from "../../../../common/components/DetailsFormToolbar";
import dayjs, { Dayjs } from "dayjs";
import { AddSettingsSprintDto, ExistingSettingsSprintDto } from "../../../../store/generated/settings/sprint";
import useFormValidation from "../../../../common/components/useFormValidation";
import { useGetSettingsReleaseListQuery } from "../../../../store/generated/settings/release";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const SprintDetails = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const sprintKey = new URLSearchParams(useLocation().search).get("sprintKey");
    const [sprint, setSprint] = useState<AddSettingsSprintDto | undefined>(undefined);
    const { data, error, isLoading } = useGetSettingsSprintGetQuery({ sprintKey: sprintKey!! }, { skip: !sprintKey });
    const { data: releases } = useGetSettingsReleaseListQuery();

    useEffect(() => {
        if (data) {
            const sprint = {
                ...data,
                startDate: dayjs(data.plannedStartDate),
                endDate: data.plannedEndDate ? dayjs(data.plannedEndDate) : undefined,
                release: { label: data?.release?.name, value: data?.release?.releaseKey },
            };
            setSprint(sprint as any);
        }
    }, [data]);

    const [addSprint, addSprintResult] = usePostSettingsSprintAddMutation();
    const [updateSprint, updateSprintResult] = usePutSettingsSprintUpdateMutation();
    const [deleteSprint, deleteResult] = useDeleteSettingsSprintDeleteMutation();
    useFormValidation({ form, validationResult: (addSprintResult ?? updateSprintResult ?? deleteResult) as any });

    useEffect(() => {
        if (addSprintResult.isSuccess || updateSprintResult.isSuccess || deleteResult.isSuccess) {
            navigate(-1);
        }
    }, [addSprintResult, updateSprintResult, deleteResult]);

    const onCancel = () => {
        navigate(-1);
    };

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
        setSprint({
            ...sprint,
            [property]: e.target.value,
        });
    };

    const onValueSelected = (e: { label: string; value: any }, property: string) => {
        setSprint({
            ...sprint,
            [property]: e,
        });
    };

    const onDateValueChange = (e: Dayjs | null, property: string) => {
        setSprint({
            ...sprint,
            [property]: e?.format("YYYY-MM-DD"),
        });
    };

    const onFinish = (values: any) => {
        const dto = {
            ...sprint,
            releaseKey: (sprint?.release as any).value,
            startDate: dayjs(sprint?.plannedStartDate).format("YYYY-MM-DD"),
            endDate: sprint?.plannedEndDate ? dayjs(sprint?.plannedEndDate).format("YYYY-MM-DD") : undefined,
        };
        delete dto.release;
        if (dto?.sprintKey) {
            updateSprint({ existingSettingsSprintDto: dto as ExistingSettingsSprintDto });
        } else {
            addSprint({ addSettingsSprintDto: dto });
        }
    };

    const onDelete = () => {
        deleteSprint({ sprintKey: sprint?.sprintKey!! });
    };

    return !sprintKey || sprint ? (
        <Form
            {...layout}
            form={form}
            initialValues={sprint}
            name="control-hooks"
            onFinish={onFinish}
            style={{ maxWidth: 600, paddingTop: 15 }}
        >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input type="text" onChange={(e) => onValueChange(e, "name")} />
            </Form.Item>
            <Form.Item name="startDate" label="Start Date" rules={[{ required: true }]}>
                <DatePicker onChange={(e) => onDateValueChange(e, "startDate")} />
            </Form.Item>
            <Form.Item name="endDate" label="End Date" rules={[{ required: false }]}>
                <DatePicker onChange={(e) => onDateValueChange(e, "endDate")} />
            </Form.Item>

            <Form.Item name="release" label="Release" rules={[{ required: true }]}>
                <Select
                    placeholder="Release"
                    labelInValue
                    popupMatchSelectWidth={false}
                    defaultOpen={false}
                    options={releases?.map((x) => ({ value: x.releaseKey, label: x.name }))}
                    onSelect={(e) => onValueSelected(e, "release")}
                    style={{ maxWidth: 175, width: 175, textAlign: "center" }}
                />
            </Form.Item>

            <DetailsFormToolbar
                tailLayout={tailLayout}
                onCancel={onCancel}
                onDelete={onDelete}
                onSubmit={() => form.submit()}
            />
        </Form>
    ) : undefined;
};

export default SprintDetails;
