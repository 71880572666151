import { SettingsBreadcrumb } from "./SetttingsBreadcrumb";
import { Button } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { UserInviteModal } from "./UserInviteModal";

function SettingsToolbar(props: { onClick: () => void }) {
    const location = useLocation();
    const [showInviteModal, setShowModal] = useState(false);

    const onAddUser = () => {
        setShowModal(true);
    };

    const handleInviteCancel = () => {
        setShowModal(false);
    };

    const handleInviteSend = () => {
        setShowModal(false);
    };

    return (
        <div style={{ display: "flex" }}>
            <SettingsBreadcrumb />
            {location.pathname.includes("settings/users") ? (
                <Button style={{ alignSelf: "center", marginRight: 5 }} onClick={onAddUser}>
                    Invite User
                </Button>
            ) : undefined}
            <Button style={{ alignSelf: "center" }} onClick={props.onClick}>
                Add
            </Button>
            <UserInviteModal open={showInviteModal} onOk={handleInviteSend} onCancel={handleInviteCancel} />
        </div>
    );
}

export default SettingsToolbar;
