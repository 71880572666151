import React, { Ref } from "react";
import { Card, Flex, Tag } from "antd";
import { IssueDto } from "../../../../store/generated/issue";
import UserAvatar from "../../../../common/components/UserAvatar";
import Config from "../../../../config.json";
import { CopyOutlined } from "@ant-design/icons";
import StatusType from "../../../../common/types/StatusType";
import BacklogContextMenu from "../backlog-list/BacklogContextMenu";
import { KANBAN_BOARD_SPACING } from "./KanbanBoardColumn";

type CardProps = {
    issue: IssueDto;
    isFirst: boolean;
    onClick: () => void;
    rightClickMenu: Ref<typeof BacklogContextMenu>;
};

function CardContent(props: { issue: IssueDto }) {
    return (
        <Flex vertical={true} flex={1} style={{ padding: 0 }}>
            <span style={{ textAlign: "left", paddingBottom: 8 }}>{props.issue.title}</span>
            <Flex vertical={false} align="center">
                <Flex justify="flex-start">
                    {props.issue.assignee ? <UserAvatar user={props.issue.assignee} /> : <i>Unassigned</i>}
                </Flex>
                <Flex justify="flex-end" flex={1}>
                    <Tag>{props.issue.storyPoints}</Tag>
                </Flex>
            </Flex>
        </Flex>
    );
}

const KanbanCard = (props: CardProps) => {
    const generateCardLink = () => {
        return (
            <Flex vertical={true} align={"flex-start"}>
                <a
                    onClick={(event) => {
                        event.stopPropagation();
                        navigator.clipboard.writeText(
                            `${Config.WEB_URL}/backlog-management/backlog?selectedIssue=${props.issue.issueKey}`
                        );
                    }}
                >
                    {props.issue.projectIssueKeyedLabel}
                    <CopyOutlined style={{ paddingLeft: 5 }} />
                </a>
            </Flex>
        );
    };
    return (
        <>
            <Card
                onContextMenu={(event) => {
                    event.preventDefault();
                    (props.rightClickMenu as any).show(props.issue, event.clientX, event.clientY);
                }}
                className={props.issue.status === StatusType.Done ? "completed-issue" : undefined}
                title={generateCardLink()}
                hoverable={true}
                bordered={true}
                headStyle={{ border: "none" }}
                size="small"
                style={{
                    backgroundColor: "rgba(253, 253, 253, 0.10)",
                    borderColor: "rgb(66, 66, 66)",
                    width: "100%",
                    display: "block",
                    marginTop: props.isFirst ? "unset" : KANBAN_BOARD_SPACING,
                    paddingTop: 5,
                }}
                onClick={() => props.onClick()}
            >
                <CardContent issue={props.issue} />
            </Card>
        </>
    );
};

export default KanbanCard;
