import { EpicDto } from "../../../../store/generated/issue";
import { LockOutlined } from "@ant-design/icons";
import React from "react";
import { Tooltip } from "antd";

export function EpicLabel(props: { epic: EpicDto }) {
    return (
        <>
            <Tooltip title="Default Epic settings can be managed under project settings">
                {props.epic?.isDefault ? <LockOutlined /> : undefined} {props.epic?.title}
            </Tooltip>
        </>
    );
}
