import { configureStore as configure } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { History } from "history";
import { emptySplitApi } from "./emptyApi";
import BacklogSlice from "../areas/backlog-management/reducers/BacklogSlice";
import Reactotron from "../ReactotronConfig";
import ProjectSlice from "../common/reducers/ProjectSlice";
import UserPreferenceSlice from "../common/reducers/UserPreferenceSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import CompanySlice from "../common/reducers/CompanySlice";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import UserOnboardingSlice from "../common/reducers/UserOnboardingSlice";

const rootPersistConfig = {
    key: "root",
    version: 1,
    whitelist: ["preferences"],
    storage,
};
const backlogPersistConfig = {
    key: "backlog",
    version: 1,
    storage: storage,
    stateReconciler: autoMergeLevel2 as any,
    whitelist: ["filterState", "defaultState"],
};
const companyPersistConfig = {
    key: "company",
    storage: storage,
    whitelist: ["selectedCompanyKey", "selectedRoleKey"],
};
const userOnboardingPersistConfig = {
    key: "userOnboarding",
    storage: storage,
};

export default function configureStore(history: History, initialState?: {}) {
    const appReducer = combineReducers({
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
        company: persistReducer(companyPersistConfig, CompanySlice),
        backlog: persistReducer(backlogPersistConfig, BacklogSlice),
        project: ProjectSlice,
        preferences: UserPreferenceSlice,
        userOnboarding: persistReducer(userOnboardingPersistConfig, UserOnboardingSlice),
    });

    const rootReducer = (state: any, action: any) => {
        if (action.type === "USER_LOGOUT") {
            return appReducer(undefined, action);
        }

        return appReducer(state, action);
    };

    const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
    //const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    //if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    //enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    //}

    return configure({
        reducer: persistedReducer,
        preloadedState: initialState as any,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }).concat([
                thunk,
                //routerMiddleware(history),
                emptySplitApi.middleware,
            ]),
        enhancers: (getDefaultEnhancers) =>
            Reactotron.createEnhancer
                ? getDefaultEnhancers().concat(Reactotron.createEnhancer())
                : getDefaultEnhancers(),
    });
}
