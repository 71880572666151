import React from "react";
import { Checkbox, Progress, Spin, Table } from "antd";
import { useNavigate } from "react-router";
import { useGetFeatureListQuery, usePutFeatureUpdateMutation } from "../api/FeatureApi";
import { FeatureDto } from "../../../../store/generated/feature";
import SettingsToolbar from "../../SettingsToolbar";

const FeatureListPage = (props: any) => {
    const { data, error, isLoading } = useGetFeatureListQuery();
    const [trigger, result] = usePutFeatureUpdateMutation();
    const navigate = useNavigate();

    function handleFeatureToggle(feature: FeatureDto) {
        trigger({ featureDto: { ...feature, isEnabled: !feature.isEnabled } });
    }

    const columns = [
        {
            title: "Key",
            dataIndex: "featureKey",
            key: "featureKey",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Enabled",
            key: "isEnabled",
            render: (feature: FeatureDto) => (
                <Checkbox checked={feature.isEnabled} onChange={() => handleFeatureToggle(feature)} />
            ),
        },
    ];
    return (
        <>
            <SettingsToolbar onClick={() => {}} />
            <Table
                dataSource={data}
                columns={columns}
                rowKey={(record) => record.featureKey!!}
                onRow={(record, rowIndex) => {
                    return {
                        //onClick: event => {onEdit(record)}, // click row
                    };
                }}
            />
        </>
    );
};

export default FeatureListPage;
