import { RootState } from "../../index";
import { createSelector } from "@reduxjs/toolkit";
import { AccessType } from "../types/AccessType";
import { useSelector } from "react-redux";
import { getSelectedCompany } from "./CompanySelectors";

const getSelectedRoleKey = (state: RootState) => state.company.selectedRoleKey;
export const getAvailableRoles = (state: RootState) => {
    return state.company.availableRoles;
};

export const getSelectedRole = createSelector(
    getSelectedRoleKey,
    getSelectedCompany,
    getAvailableRoles,
    (selectedRoleKey, selectedCompany, availableRoles) => {
        return availableRoles.find((x: any) => x.roleKey === selectedRoleKey) ?? selectedCompany?.currentRole;
    }
);

export const getCurrentAccessType = createSelector(getSelectedRole, (selectedRole) => selectedRole?.accessType);
export const isAllowedToDeleteIssue = createSelector(
    getCurrentAccessType,
    (currentAccessType) => currentAccessType >= AccessType.Edit
);
export const isAllowedToSaveIssue = createSelector(
    getCurrentAccessType,
    (currentAccessType) => currentAccessType >= AccessType.Edit
);
export const isAllowedToChangeIssueOrder = createSelector(
    getCurrentAccessType,
    (currentAccessType) => currentAccessType >= AccessType.Edit
);
export const isAllowedToAccessSettings = createSelector(
    getCurrentAccessType,
    (currentAccessType) => currentAccessType >= AccessType.Admin
);
