import React from "react";
import { useNavigate } from "react-router";
import { Table } from "antd";
import SettingsToolbar from "../../SettingsToolbar";
import { useGetSettingsTeamListQuery } from "../api/TeamApi";
import { CommonStyles } from "../../../../common/Styles";
import { ExistingSettingsTeamDto } from "../../../../store/generated/settings/team";

const TeamListPage = (props: any) => {
    const { data, error, isLoading } = useGetSettingsTeamListQuery();
    const navigate = useNavigate();
    const onEdit = (team?: ExistingSettingsTeamDto) => {
        if (team) {
            navigate(`/settings/team/details?teamKey=${team?.teamKey ?? ""}`);
        } else {
            navigate(`/settings/team/details`);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (value: string) => <div style={CommonStyles.link}>{value}</div>,
        },
    ];

    return (
        <>
            <SettingsToolbar onClick={() => onEdit()} />
            <Table
                dataSource={data}
                columns={columns}
                rowKey={(record) => record.teamKey!!}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            onEdit(record);
                        }, // click row
                    };
                }}
            />
        </>
    );
};

export default TeamListPage;
