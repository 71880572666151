import React from "react";
import { useNavigate } from "react-router";
import { ExistingSettingsTenantDto, useGetSettingsTenantListQuery } from "../../../../store/generated/settings/tenant";
import { Table } from "antd";
import SettingsToolbar from "../../SettingsToolbar";
import { CommonStyles } from "../../../../common/Styles";

const TenantList = (props: any) => {
    const { data, error, isLoading } = useGetSettingsTenantListQuery();
    const navigate = useNavigate();
    const editTenant = (tenant?: ExistingSettingsTenantDto) => {
        if (tenant) {
            navigate(`/settings/customer/details?tenantKey=${tenant?.tenantKey ?? ""}`);
        } else {
            navigate(`/settings/customer/details`);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (data: string) => <div style={CommonStyles.link}>{data}</div>,
        },
        {
            title: "Uri",
            dataIndex: "uri",
            key: "uri",
        },
    ];

    return (
        <>
            <SettingsToolbar onClick={() => editTenant()} />
            <Table
                dataSource={data}
                columns={columns}
                rowKey={(record) => record.tenantKey!!}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            editTenant(record);
                        }, // click row
                    };
                }}
            />
        </>
    );
};

export default TenantList;
