import React, { CSSProperties, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Menu, MenuProps, notification } from "antd";
import {
    usePostIssueCloneMutation,
    usePutIssueAssignToCurrentUserMutation,
    usePutIssueSendToBacklogMutation,
    usePutIssueSendToCurrentSprintMutation,
} from "../../api/IssueApi";
import { useDispatch } from "react-redux";
import { IssueDto } from "../../../../store/generated/issue";
import { emitShowCreateIssueModal } from "../../../../common/events";

function useOutsideAlerter(ref: any, handler: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

enum MenuType {
    Backlog,
    AssignedToSprint,
}
function BacklogContextMenu(props: { teamKey?: string }, ref: any) {
    const [menuType, setMenuType] = useState(MenuType.Backlog);
    const [issueKey, setIssueKey] = useState<string | undefined>(undefined);
    const [popoverStyle, setPopOverStyle] = useState({
        position: "absolute",
        visibility: "hidden",
        zIndex: 10000,
    } as CSSProperties);
    const dispatch = useDispatch();
    const [sendToCurrentSprint] = usePutIssueSendToCurrentSprintMutation();
    const [sendToBacklog] = usePutIssueSendToBacklogMutation();
    const [assignToMe] = usePutIssueAssignToCurrentUserMutation();
    const [clone] = usePostIssueCloneMutation();

    const handleSendToCurrentSprint = () => {
        sendToCurrentSprint({ issueKey: issueKey, teamKey: props.teamKey }).then((x) => {
            //dispatch(enhancedApi.util.invalidateTags(['Issue' as any]))
            hide();
        });
    };

    const handleSendToBacklog = () => {
        sendToBacklog({ issueKey: issueKey }).then((x) => {
            //dispatch(enhancedApi.util.invalidateTags(['Issue' as any]))
            hide();
        });
    };

    const handleAssignToMe = () => {
        assignToMe({ issueKey: issueKey }).then((x) => {
            //dispatch(enhancedApi.util.invalidateTags(['Issue' as any]))
            hide();
        });
    };

    const handleClone = () => {
        clone({ issueKey: issueKey, keepSprint: true }).then((x: any) => {
            notification.success({
                message: "Issue Cloned",
                description: "Your issue was successfully cloned!",
                placement: "topRight",
                duration: 3,
            });
            emitShowCreateIssueModal({ projectIssueKeyedLabel: x.data.projectIssueKeyedLabel });
        });
    };

    let sprintMenuItems: MenuProps["items"] = [
        {
            label: "Send Issue to Backlog",
            key: "sendToBacklog",
            icon: undefined,
            onClick: handleSendToBacklog,
        },
        {
            label: "Assign to Me",
            key: "assignToMe",
            icon: undefined,
            onClick: handleAssignToMe,
        },
        {
            label: "Clone",
            key: "clone",
            icon: undefined,
            onClick: handleClone,
        },
    ];

    let backlogMenuItems: MenuProps["items"] = [
        {
            label: "Assign to Me",
            key: "assignToMe",
            icon: undefined,
            onClick: handleAssignToMe,
        },
        {
            label: "Clone",
            key: "clone",
            icon: undefined,
            onClick: handleClone,
        },
    ];
    if (props.teamKey) {
        backlogMenuItems.push({
            label: "Add to Current Sprint",
            key: "addToSprint",
            icon: undefined,
            onClick: handleSendToCurrentSprint,
        });
    }

    function adaptMenu(record: IssueDto) {
        setMenuType(record.sprintDto !== null ? MenuType.AssignedToSprint : MenuType.Backlog);
    }
    const popoverRef = useRef();
    useOutsideAlerter(popoverRef, () => {
        hide();
    });

    const hide = () => {
        setPopOverStyle({ ...popoverStyle, visibility: "hidden" });
    };

    useImperativeHandle(ref, () => ({
        show(record: IssueDto, positionX: number, positionY: number) {
            adaptMenu(record);
            setIssueKey(record.issueKey);
            setPopOverStyle({
                ...popoverStyle,
                visibility: "visible",
                top: positionY - 20,
                left: positionX + 20,
            } as CSSProperties);
        },
    }));

    return (
        <div ref={ref as any}>
            <div style={popoverStyle} ref={popoverRef as any}>
                <Menu
                    mode="vertical"
                    items={menuType === MenuType.AssignedToSprint ? sprintMenuItems : backlogMenuItems}
                />
            </div>
        </div>
    );
}

export default React.forwardRef(BacklogContextMenu);
