import { CheckboxChangeEvent } from "antd/es/checkbox";
import { uniq } from "lodash";
import { Checkbox, Collapse, Flex, Form, FormInstance, Layout, List, Modal, Radio, Typography } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { UserThemePreference } from "../../../../common/reducers/UserPreferenceSlice";
import * as React from "react";
import { SprintProgressSummary } from "../../../../store/generated/sprint";

import { SprintFormData } from "./SprintFormData";
import { PresprintActionType } from "../types/PresprintActionType";
import { emitShowCreateIssueModal } from "../../../../common/events";

type CloseSprintModalProps = {
    open: boolean;
    onCancel: () => void;
    onOk: () => void;
    sprintSummary: SprintProgressSummary;
    submittable: boolean;
    form: FormInstance;
    formData: SprintFormData;
    initialValues: SprintFormData;
    activeUserThemePreference: UserThemePreference;
    handleFormDataChange: (property: keyof SprintFormData, value: any) => void;
};

export const existingBacklogItemHandlingOptions = ["Next Sprint", "Backlog"];

export const items = [
    {
        key: PresprintActionType.IncludeIncompleteSprints,
        label: "Include Incomplete Sprint(s)",
    },
    {
        key: PresprintActionType.UnassignExistingItems,
        label: "Unassign Existing Item(s)",
    },
    {
        key: PresprintActionType.ChangeInProgressItemStatus,
        label: "Change In Progress Item Status",
    },
];

export function CloseSprintModal(props: CloseSprintModalProps) {
    function handleCheckboxChange(e: CheckboxChangeEvent, key: number) {
        if (e.target.checked) {
            props.handleFormDataChange("checked", uniq([...props.formData.checked, key]));
        } else {
            props.handleFormDataChange(
                "checked",
                props.formData.checked.filter((x) => x !== key)
            );
        }
    }

    const renderItem = (item: { key: number; label: string }) => {
        const checked = props.formData.checked.some((x) => item.key === x);
        function handleIssueClick(x: any) {
            emitShowCreateIssueModal({ projectIssueKeyedLabel: x.projectIssueKeyedLabel!! });
        }
        return item.key === PresprintActionType.IncludeIncompleteSprints ? (
            <List.Item key={item.key}>
                <Flex vertical={true} flex={1}>
                    <Flex vertical={false} style={{ paddingBottom: 10, alignItems: "center" }}>
                        <List.Item.Meta
                            title={
                                <Typography.Text>{`Move ${props.sprintSummary?.inProgressIssues?.length} Incomplete Items to:`}</Typography.Text>
                            }
                        />
                        <Radio.Group
                            onChange={(e) => props.handleFormDataChange("existingUserBacklogHandling", e.target.value)}
                            disabled={!checked}
                            value={
                                checked
                                    ? props.formData.existingUserBacklogHandling
                                    : existingBacklogItemHandlingOptions[0]
                            }
                            options={existingBacklogItemHandlingOptions}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Flex>

                    {props.sprintSummary?.inProgressIssues ? (
                        <Collapse
                            accordion={true}
                            collapsible="icon"
                            items={[
                                {
                                    key: "1",
                                    label: "Incomplete Issues",
                                    children: props.sprintSummary?.inProgressIssues.map((x) => (
                                        <List.Item onClick={() => handleIssueClick(x)}>{x.title}</List.Item>
                                    )),
                                },
                            ]}
                        />
                    ) : undefined}
                </Flex>
            </List.Item>
        ) : (
            <List.Item key={item.key}>
                <List.Item.Meta title={<Typography.Text>{item.label}</Typography.Text>} />
                {item.key !== PresprintActionType.IncludeIncompleteSprints ? (
                    <Checkbox checked={checked} onChange={(e) => handleCheckboxChange(e, item.key)} />
                ) : undefined}
            </List.Item>
        );
    };

    return (
        <Modal
            open={props.open}
            okText="Close Sprint"
            onCancel={props.onCancel}
            onOk={props.onOk}
            okButtonProps={{ disabled: !props.submittable }}
        >
            <Form form={props.form} name="control-hooks" style={{ maxWidth: 600 }} initialValues={props.initialValues}>
                <Header
                    style={{
                        paddingLeft: 0,
                        backgroundColor:
                            props.activeUserThemePreference == UserThemePreference.Dark ? "#1f1f1f" : "white",
                    }}
                >
                    <Form.Item name="includedActions">Included Sprint Actions</Form.Item>
                </Header>
                <List bordered dataSource={items} renderItem={renderItem} />
            </Form>
        </Modal>
    );
}
