import { enhancedApi as api } from "../../../../store/generated/settings/tenant";

const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ["TenantUser"],
    endpoints: {
        getSettingsTenantUserList: {
            providesTags: ["TenantUser"],
        },
        getSettingsTenantUserGet: {
            providesTags: ["TenantUser"],
        },
        putSettingsTenantUserSave: {
            invalidatesTags: ["TenantUser"],
        },
        deleteSettingsTenantUserDelete: {
            invalidatesTags: ["TenantUser"],
        },
    },
});

export const {
    useGetSettingsTenantUserListQuery,
    useGetSettingsTenantUserGetQuery,
    usePutSettingsTenantUserSaveMutation,
    useDeleteSettingsTenantUserDeleteMutation,
} = enhancedApi;
