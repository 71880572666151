import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
    useDeleteSettingsTeamDeleteMutation,
    useGetSettingsTeamGetQuery,
    usePostSettingsTeamAddMutation,
    usePutSettingsTeamUpdateMutation,
} from "../api/TeamApi";
import { AddSettingsTeamDto } from "../../../../store/generated/settings/team";
import { Form, FormInstance, Input } from "antd";
import { DetailsFormToolbar } from "../../../../common/components/DetailsFormToolbar";
import useFormValidation from "../../../../common/components/useFormValidation";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const TeamDetails = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const teamKey = new URLSearchParams(useLocation().search).get("teamKey");
    const [team, setTeam] = useState<AddSettingsTeamDto | undefined>(undefined);
    const { data, error, isLoading } = useGetSettingsTeamGetQuery({ teamKey: teamKey!! }, { skip: !teamKey });
    useEffect(() => {
        if (data) {
            setTeam(data);
        }
    }, [data]);
    const [addTeam, addTeamResult] = usePostSettingsTeamAddMutation();
    const [updateTeam, updateTeamResult] = usePutSettingsTeamUpdateMutation();
    const [deleteTeam, deleteResult] = useDeleteSettingsTeamDeleteMutation();
    useFormValidation({ form, validationResult: (addTeamResult ?? updateTeamResult ?? deleteResult) as any });
    useEffect(() => {
        if (addTeamResult.isSuccess || updateTeamResult.isSuccess) {
            navigate(-1);
        }
    }, [addTeamResult, updateTeamResult]);
    useEffect(() => {
        if (deleteResult.isSuccess) {
            navigate(-1);
        }
    }, [deleteResult]);
    const onCancel = () => {
        navigate(-1);
    };
    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
        setTeam({
            ...team,
            [property]: e.target.value,
        });
    };

    const onFinish = (values: any) => {
        if (team?.teamKey) {
            updateTeam({ existingSettingsTeamDto: team as any });
        } else {
            addTeam({ addSettingsTeamDto: team as any });
        }
    };

    const onDelete = () => {
        deleteTeam({ teamKey: team?.teamKey!! });
    };

    return !teamKey || team ? (
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            initialValues={team}
            onFinish={onFinish}
            style={{ maxWidth: 600, paddingTop: 15 }}
        >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input type="text" onChange={(e) => onValueChange(e, "name")} />
            </Form.Item>
            <DetailsFormToolbar
                tailLayout={tailLayout}
                onCancel={onCancel}
                onDelete={onDelete}
                onSubmit={() => form.submit()}
            />
        </Form>
    ) : undefined;
};

export default TeamDetails;
