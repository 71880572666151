import { Form, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
    ReportDefinitionDto,
    useGetReportsReportGetQuery,
    useGetReportsReportListQuery,
} from "../../../store/generated/reports";
import ReportParametersSubform from "./ReportParametersSubform";
import ReportSelect from "./ReportSelect";
import { isUndefined } from "lodash";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const ReportModal = (props: Props) => {
    const [selectedReport, setSelectedReport] = useState({ parameters: [] } as ReportDefinitionDto);
    const { data, error, isLoading } = useGetReportsReportListQuery();
    const { data: selectedReportData, isLoading: isLoadingSubdata } = useGetReportsReportGetQuery(
        {
            reportType: 0,
        },
        { skip: isUndefined(selectedReport.reportType) }
    );
    function cancelEditing() {
        props.onClose();
    }
    function handleSelectedReportChange(value: ReportDefinitionDto) {
        setSelectedReport(data?.find((x) => x.reportType!!.valueOf() === value)!!);
    }
    return !!data ? (
        <Modal open={props.isOpen} onCancel={cancelEditing}>
            <Form>
                <ReportSelect data={data} onChange={(value) => handleSelectedReportChange(value)} />
                {selectedReportData ? (
                    isLoadingSubdata ? (
                        <Spin />
                    ) : (
                        <ReportParametersSubform selectedReport={selectedReportData} />
                    )
                ) : undefined}
            </Form>
        </Modal>
    ) : (
        <Spin />
    );
};

export default ReportModal;
