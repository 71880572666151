import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
    useDeleteSettingsRoleDeleteMutation,
    useGetSettingsRoleGetQuery,
    usePostSettingsRoleAddMutation,
    usePutSettingsRoleUpdateMutation,
} from "../api/RoleApi";
import { Form, Input, Select } from "antd";
import { DetailsFormToolbar } from "../../../../common/components/DetailsFormToolbar";
import { AccessType } from "../../../../common/types/AccessType";
import { AddSettingsRoleDto } from "../../../../store/generated/settings/role";
import useFormValidation from "../../../../common/components/useFormValidation";
import { splitPascalString } from "../../../../common/utils/StringUtils";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const availablePermissionValues = Object.values(AccessType)
    .filter((x) => typeof x === "string")
    .map((x, index) => {
        return {
            label: splitPascalString(x as string) === "Edit" ? "Create and Edit" : splitPascalString(x as string),
            value: index,
        };
    });

const RoleDetails = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const roleKey = new URLSearchParams(useLocation().search).get("roleKey");
    const [role, setRole] = useState<AddSettingsRoleDto | undefined>(undefined);
    const { data, error, isLoading } = useGetSettingsRoleGetQuery({ roleKey: roleKey!! }, { skip: !roleKey });
    useEffect(() => {
        if (data) {
            setRole(data);
        }
    }, [data]);
    const [addRole, addRoleResult] = usePostSettingsRoleAddMutation();
    const [updateRole, updateRoleResult] = usePutSettingsRoleUpdateMutation();
    const [deleteRole, deleteResult] = useDeleteSettingsRoleDeleteMutation();
    useFormValidation({ form, validationResult: (addRoleResult ?? updateRoleResult ?? deleteResult) as any });
    useEffect(() => {
        if (addRoleResult.isSuccess || updateRoleResult.isSuccess) {
            navigate(-1);
        }
    }, [addRoleResult, updateRoleResult]);
    useEffect(() => {
        if (deleteResult.isSuccess) {
            navigate(-1);
        }
    }, [deleteResult]);
    const onCancel = () => {
        navigate(-1);
    };
    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
        setRole({
            ...role,
            [property]: e.target.value,
        });
    };

    const onFinish = (values: any) => {
        if (role?.roleKey) {
            updateRole({ existingSettingsRoleDto: role!! });
        } else {
            addRole({ addSettingsRoleDto: role!! });
        }
    };

    const onDelete = () => {
        deleteRole({ roleKey: role?.roleKey!! });
    };

    return !roleKey || role ? (
        <Form
            {...layout}
            form={form}
            initialValues={role}
            name="control-hooks"
            onFinish={onFinish}
            style={{ maxWidth: 600, paddingTop: 15 }}
        >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input type="text" onChange={(e) => onValueChange(e, "name")} />
            </Form.Item>
            <Form.Item name="accessType" label="Access Type" rules={[{ required: true }]}>
                <Select
                    options={availablePermissionValues}
                    popupMatchSelectWidth={true}
                    defaultOpen={false}
                    onChange={(value) => onValueChange({ target: { value } } as any, "accessType")}
                />
            </Form.Item>
            <DetailsFormToolbar
                tailLayout={tailLayout}
                onCancel={onCancel}
                onDelete={onDelete}
                onSubmit={() => form.submit()}
            />
        </Form>
    ) : undefined;
};

export default RoleDetails;
