import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredProject } from "../../../areas/backlog-management/selectors/BacklogSelectors";
import { clearFilteredProject, setFilteredProject } from "../../../areas/backlog-management/reducers/BacklogSlice";
import { ProjectDto } from "../../../store/generated/issue";

type Props = {
    projects: ProjectDto[];
};

export const NavMenuProjectSelect = (props: Props) => {
    const selectedProject = useSelector(getFilteredProject);
    const dispatch = useDispatch();

    const projects = props.projects;
    return (
        <Select
            defaultValue={
                selectedProject
                    ? {
                          value: selectedProject,
                          label: projects.find((x) => x.projectKey === selectedProject.key)?.name,
                      }
                    : undefined
            }
            popupMatchSelectWidth={false}
            defaultOpen={false}
            optionLabelProp="label"
            onSelect={(e) => {
                const project = projects.find((x) => x.projectKey === (e as any))!!;
                dispatch(setFilteredProject({ key: project.projectKey, label: project.name }));
            }}
            onClear={() => dispatch(clearFilteredProject())}
            allowClear={true}
            options={projects.map((project) => ({
                value: project.projectKey,
                label: project.name,
            }))}
            style={{ alignSelf: "center", maxWidth: 140, width: 140, textAlign: "center" }}
        />
    );
};
