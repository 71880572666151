import { Modal, Spin } from "antd";
import FileSelector from "./FileSelector";
import * as React from "react";
import { useState } from "react";
import { ImportStatus } from "../types/ImportStatus";
import { fetchAuthSession } from "aws-amplify/auth";
import Config from "../../config.json";
import { enhancedApi as issueApi } from "../../store/generated/issue";
import { useDispatch } from "react-redux";
import { Content, Header } from "antd/es/layout/layout";

export function FileImportModal(props: {
    importStatus: ImportStatus | null;
    onImportStatusChanged: (importStatus: ImportStatus) => void;
    onHide: () => void;
}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch();
    const executeImport = async () => {
        props.onImportStatusChanged(ImportStatus.Processing);
        if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            try {
                // TODO: Duplicated from API layer
                const res = await fetchAuthSession();
                let accessToken = res.tokens?.accessToken;
                const headers = new Headers();
                if (accessToken) {
                    headers.set("authorization", `Bearer ${accessToken}`);
                    // For now, let's assume authenticated also equals the same tenant

                    headers.set("X-Tenant-Key", "267cba78-4f17-4b8f-af45-f7c24667deaa");
                    //headers.set('X-Tenant-Key', '9232d52c-fc88-42c6-8f46-7a8c18a19880')
                }
                // You can write the URL of your server or any other endpoint used for file upload
                const result = await fetch(`${Config.API_URL}/issueimport/execute`, {
                    method: "POST",
                    body: formData,
                    headers,
                });
                if (result.status !== 200) {
                    props.onImportStatusChanged(ImportStatus.Error);
                } else {
                    props.onImportStatusChanged(ImportStatus.Success);
                }
                const data = await result;
                dispatch(issueApi.util.invalidateTags(["Issue"] as any[]));
                // TODO: refresh data
            } catch (error) {}
        }
    };

    const importIsFinished = () => {
        return props.importStatus === ImportStatus.Success || props.importStatus === ImportStatus.Error;
    };

    const getComponentForStatus = () => {
        switch (props.importStatus) {
            case ImportStatus.PendingInput:
                return <FileSelector onFileChange={setSelectedFile} />;
            case ImportStatus.Processing:
                return <Spin />;
            case ImportStatus.Success:
                return <div>Successfully executed the import</div>;
            case ImportStatus.Error:
                return <div>There was an error executing the import</div>;
        }
    };

    const getTitle = () => {
        switch (props.importStatus) {
            case ImportStatus.PendingInput:
                return "Pending File Upload";
            case ImportStatus.Processing:
                return "Importing ...";
            case ImportStatus.Success:
                return "Success";
            case ImportStatus.Error:
                return "Error";
        }
    };
    return (
        <Modal
            title="Basic Modal"
            open={props.importStatus !== null}
            okText={importIsFinished() ? "Ok" : "Import"}
            onOk={importIsFinished() ? props.onHide : executeImport}
            onCancel={props.onHide}
            okButtonProps={{ type: "primary" }}
        >
            <Header>{getTitle()}</Header>
            <Content>{getComponentForStatus()}</Content>
        </Modal>
    );
}
