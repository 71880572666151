import { Select } from "antd";
import React from "react";
import { EpicDto, useGetIssueListEpicsQuery } from "../../../../store/generated/issue";
import { LockOutlined } from "@ant-design/icons";
import { keyBy } from "lodash";

export function EpicSelectPill(props: {
    epic: EpicDto | undefined;
    projectKey: string;
    onEpicUpdate: (value: EpicDto) => void;
}) {
    const { epics, presentableValues, error, isLoading } = useGetIssueListEpicsQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            epics: keyBy(
                data?.filter((x) => x.projectKey === props.projectKey),
                "issueKey"
            ),
            presentableValues: data
                ?.filter((x) => x.projectKey === props.projectKey)
                .map((x) => ({
                    value: x.issueKey,
                    label: x.isDefault ? (
                        <span>
                            <LockOutlined style={{ paddingRight: 4 }} />
                            {x.title}
                        </span>
                    ) : (
                        x.title
                    ),
                })),
            error,
            isLoading,
        }),
        pollingInterval: undefined,
    });

    const onSelect = (selected: any) => {
        props.onEpicUpdate(epics[selected]);
    };

    return (
        <Select
            options={presentableValues}
            defaultValue={{ value: props.epic?.issueKey, label: props.epic?.title }}
            popupMatchSelectWidth={false}
            defaultOpen={false}
            onSelect={onSelect}
            style={{ maxWidth: 140, width: 140, textAlign: "center" }}
        />
    );
}
