enum IssueType {
    Initiative = 1, // Deliverable?
    Epic = 2,
    Story = 3,
    Bug = 4, // Only relevant for software
    Task = 5,
}

export const getIssueTypeLabel = (issueType: IssueType) => {
    switch (issueType) {
        case 1:
            return "Initiative";
        case 2:
            return "Epic";
        case 3:
            return "Story";
        case 4:
            return "Bug";
        case 5:
            return "Task";
    }
};
export const getIssueTypeColor = (issueType: IssueType) => {
    switch (issueType) {
        case 1:
            return "blue";
        case 2:
            return "cyan";
        case 3:
            return "green";
        case 4:
            return "red";
        case 5:
            return "light";
    }
};

export default IssueType;
