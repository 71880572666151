import React from "react";
import { Button, Checkbox } from "antd";
import { useGetSettingsProjectListQuery } from "../../../settings/projects/api/ProjectApi";
import { useSelector } from "react-redux";
import { getDefaultProjectKey } from "../../selectors/BacklogSelectors";
import { ProjectDto } from "../../../../store/generated/issue";

type Props = {
    isUpdatingDefaultProject: boolean;
    onProjectSelected: (project: ProjectDto) => void;
    onDefaultClick: React.MouseEventHandler<HTMLElement>;
};

export const IssueWizardProjectSelect = (props: Props) => {
    const defaultProjectKey = useSelector(getDefaultProjectKey);

    const { data, error, isLoading } = useGetSettingsProjectListQuery();
    return data ? (
        <>
            {data.map((x) => {
                return (
                    <Button
                        key={x.projectKey}
                        className={defaultProjectKey === x.projectKey ? "btn-current-default" : undefined}
                        onClick={() => props.onProjectSelected(x as ProjectDto)}
                        style={{ marginBottom: 5 }}
                    >
                        {x.name}
                    </Button>
                );
            })}
            <Checkbox
                style={{ paddingTop: 10, alignSelf: "flex-end" }}
                onClick={props.onDefaultClick}
                checked={props.isUpdatingDefaultProject}
            >
                Use as Default
            </Checkbox>
        </>
    ) : undefined;
};
