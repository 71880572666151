import React from "react";
import { Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { getIssueTypeColor, getIssueTypeLabel } from "../../areas/backlog-management/types/IssueType";
import { IssueType } from "../../store/generated/issue";

type Props = {
    issueType: IssueType;
    onClick?: () => void;
};
const IssueTypePill = (props: Props) => {
    return (
        <Tag
            icon={props.onClick ? <EditOutlined /> : undefined}
            color={getIssueTypeColor(props.issueType)}
            onClick={props.onClick}
        >
            {getIssueTypeLabel(props.issueType)}
        </Tag>
    );
};

export default IssueTypePill;
