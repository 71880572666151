import { Col, Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import React from "react";
import { IssueDto } from "../../../../store/generated/issue";
import KanbanCard from "./KanbanCard";
import { emitShowCreateIssueModal } from "../../../../common/events";
import { getSelectedTheme } from "../../../../common/UserPreferenceSelectors";
import { useSelector } from "react-redux";
import { UserThemePreference } from "../../../../common/reducers/UserPreferenceSlice";

export type KanbanBoardColumn = {
    span: number;
    label: string;
    isLast: boolean;
    issues: IssueDto[];
    rightClickMenu: any;
};

export const KANBAN_BOARD_SPACING = 16;
function KanbanBoardColumn(props: KanbanBoardColumn) {
    const activeThemePreference = useSelector(getSelectedTheme);
    console.log(props.span);
    return (
        <Col
            xs={{ flex: "100%" }}
            sm={{ span: props.span * 2 }}
            xl={{ span: props.span }}
            style={{
                textAlign: "center",
            }}
        >
            <Layout
                style={{
                    backgroundColor: activeThemePreference == UserThemePreference.Dark ? "rgb(20, 20, 20)" : "white",
                    borderRadius: 8,
                    borderColor: "rgba(66, 66, 66)",
                    borderWidth: "thin",
                    borderStyle: "solid",
                    minHeight: "calc(100vh - 80px - 64px)",
                    height: "100%",
                }}
            >
                <Header
                    style={{
                        backgroundColor:
                            activeThemePreference == UserThemePreference.Dark ? "rgb(20, 20, 20)" : "white",
                        position: "sticky",
                        zIndex: 1,
                        top: 0,
                        fontSize: 18,
                        height: "48px",
                        lineHeight: "initial",
                        alignContent: "center",
                    }}
                >
                    {props.label}
                </Header>
                <Content
                    style={{
                        backgroundColor:
                            activeThemePreference == UserThemePreference.Dark ? "rgb(20, 20, 20)" : "white",
                        paddingBottom: KANBAN_BOARD_SPACING,
                        paddingRight: KANBAN_BOARD_SPACING,
                        paddingLeft: KANBAN_BOARD_SPACING,
                    }}
                >
                    {props.issues.map((x, index) => (
                        <KanbanCard
                            key={x.issueKey}
                            issue={x}
                            isFirst={index === 0}
                            onClick={() => {
                                emitShowCreateIssueModal({ projectIssueKeyedLabel: x.projectIssueKeyedLabel as any });
                            }}
                            rightClickMenu={props.rightClickMenu}
                        />
                    ))}
                </Content>
            </Layout>
        </Col>
    );
}

export default KanbanBoardColumn;
