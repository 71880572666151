import { Button, Form } from "antd";
import React from "react";

export function DetailsFormToolbar(props: {
    tailLayout: { wrapperCol: { offset: number; span: number } };
    onCancel: () => void;
    onDelete: () => void;
    onSubmit: () => void;
}) {
    return (
        <Form.Item {...props.tailLayout}>
            <Button type="primary" onClick={props.onDelete} danger={true} style={{ float: "left" }}>
                Delete
            </Button>
            <div className="ms-auto" style={{ justifyContent: "end", display: "flex", flex: 1 }}>
                <Button type="text" onClick={props.onCancel} style={{ marginRight: 5 }}>
                    Cancel
                </Button>
                <Button type="primary" onClick={props.onSubmit}>
                    Save
                </Button>
            </div>
        </Form.Item>
    );
}
