import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

export function SettingsBreadcrumb() {
    const location = useLocation();
    const pathSnippets = useMemo(() => {
        return location.pathname.split("/").filter((i) => i);
    }, [location.pathname]);
    return (
        <Breadcrumb
            style={{ flex: 1, marginTop: 15, marginBottom: 15 }}
            items={pathSnippets.map((x, index) => {
                return {
                    title: <Link to={`/${pathSnippets.slice(0, index + 1).join("/")}`}>{x}</Link>,
                    key: x,
                };
            })}
        />
    );
}
