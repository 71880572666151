import { useDispatch, useSelector } from "react-redux";
import { List, Progress } from "antd";
import { clearFilteredTeam, setFilteredTeam } from "../../reducers/BacklogSlice";
import { FilterFilled } from "@ant-design/icons";
import { getFilteredTeam } from "../../selectors/BacklogSelectors";
import { useGetSettingsTeamListQuery } from "../../../settings/teams/api/TeamApi";

const TeamDrawerFilter = () => {
    const dispatch = useDispatch();
    const { data, isLoading } = useGetSettingsTeamListQuery();
    const filteredTeam = useSelector(getFilteredTeam);
    const items = (data ?? []).map((x) => ({
        key: x.teamKey,
        label: x.name,
        isFiltered: filteredTeam?.key === x.teamKey,
    }));

    const handleTeamClick = (team: {
        key: string | undefined | null;
        label: string | null | undefined;
        isFiltered: boolean;
    }) => {
        if (team.isFiltered) {
            dispatch(clearFilteredTeam());
        } else {
            dispatch(setFilteredTeam(team));
        }
    };
    return !isLoading ? (
        <List
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item, index) => (
                <List.Item onClick={() => handleTeamClick(item)} extra={item.isFiltered ? <FilterFilled /> : undefined}>
                    <List.Item.Meta title={item?.label} />
                </List.Item>
            )}
        />
    ) : (
        <Progress />
    );
};

export default TeamDrawerFilter;
