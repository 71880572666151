import { GetReportsReportListApiResponse, ReportDefinitionDto } from "../../../store/generated/reports";
import { Form, Select } from "antd";
import React from "react";

function ReportSelect(props: {
    onChange: (value: ReportDefinitionDto) => void;
    data: GetReportsReportListApiResponse;
}) {
    return (
        <Form.Item label="Report" name="Report" rules={[{ required: true, message: "Please input!" }]}>
            <Select onChange={props.onChange}>
                {props.data?.map((report, index) => {
                    return (
                        <Select.Option key={index} value={report.reportType}>
                            {report.name}
                        </Select.Option>
                    );
                })}
            </Select>
        </Form.Item>
    );
}

export default ReportSelect;
