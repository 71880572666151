import { enhancedApi as api } from "../../../../store/generated/settings/role";

const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ["Role"],
    endpoints: {
        getSettingsRoleList: {
            providesTags: ["Role"],
        },
        getSettingsRoleGet: {
            providesTags: ["Role"],
        },
        postSettingsRoleAdd: {
            invalidatesTags: ["Role"],
        },
        putSettingsRoleUpdate: {
            invalidatesTags: ["Role"],
        },
        deleteSettingsRoleDelete: {
            invalidatesTags: ["Role"],
        },
    },
});

export const {
    useGetSettingsRoleListQuery,
    useGetSettingsRoleGetQuery,
    usePostSettingsRoleAddMutation,
    usePutSettingsRoleUpdateMutation,
    useDeleteSettingsRoleDeleteMutation,
} = enhancedApi;
