import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Table } from "antd";
import { emitShowCreateIssueModal } from "../../../../common/events";
import React, { useRef } from "react";
import { IssueDto } from "../../../../store/generated/issue";
import { usePutIssueUpdateIssueOrderMutation } from "../../api/IssueApi";
import { CSS } from "@dnd-kit/utilities";
import { ColumnType } from "antd/lib/table";
import StatusType from "../../../../common/types/StatusType";
import { useSearchParams } from "react-router-dom";
import RightClickMenu from "./BacklogContextMenu";

type Props = {
    teamKey?: string;
    issues: IssueDto[];
    columns: ColumnType<IssueDto>[];
    components?: {
        body: {
            row: React.FC<any>;
            cell: React.FC<any>;
        };
    };
    rowClassName?: string;
    launchModalOnClick?: boolean;
    isDragAndDropEnabled: boolean;
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
}
const Row = (props: RowProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        cursor: "move",
        ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const BacklogIssueList = (props: Props) => {
    const rightClickMenu = useRef<typeof RightClickMenu>();
    const [updateOrderTrigger, result] = usePutIssueUpdateIssueOrderMutation();
    const [searchParams, setSearchParams] = useSearchParams();

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        })
    );

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            const issueKey = active.id;
            const newOrder = props.issues.find((i) => i.issueKey === over?.id)?.order!!;
            updateOrderTrigger({ issueKey: issueKey.toString(), order: newOrder });
        }
    };

    return (
        <>
            <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                    disabled={!props.isDragAndDropEnabled}
                    // rowKey array
                    items={props.issues.map((i) => i.issueKey!!)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        style={{ marginBottom: 15 }}
                        components={
                            props.components ?? {
                                body: {
                                    row: Row,
                                },
                            }
                        }
                        pagination={false}
                        dataSource={props.issues}
                        columns={props.columns}
                        rowKey="issueKey"
                        // @ts-ignore
                        rowClassName={(record, index) =>
                            props.rowClassName
                                ? props.rowClassName
                                : record.status === StatusType.Done
                                  ? "completed-issue"
                                  : undefined
                        }
                        onRow={(record, rowIndex) => {
                            return {
                                onContextMenu: (event) => {
                                    event.preventDefault();
                                    (rightClickMenu.current as any).show(record, event.clientX, event.clientY);
                                },
                                onClick: (event) => {
                                    searchParams.set("selectedIssue", record.projectIssueKeyedLabel!!);
                                    setSearchParams(searchParams);
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    props.launchModalOnClick
                                        ? emitShowCreateIssueModal({
                                              projectIssueKeyedLabel: record.projectIssueKeyedLabel!!,
                                          })
                                        : undefined;
                                },
                            };
                        }}
                    />
                </SortableContext>
            </DndContext>
            <RightClickMenu ref={rightClickMenu} teamKey={props.teamKey} />
        </>
    );
};

export default BacklogIssueList;
