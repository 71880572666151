import { useDispatch, useSelector } from "react-redux";
import { getFilteredEpic, getFilteredProject } from "../../selectors/BacklogSelectors";
import { EpicDto, useGetIssueListEpicsQuery } from "../../../../store/generated/issue";
import { List, Progress } from "antd";
import { orderBy } from "lodash";
import { clearFilteredEpic, setFilteredEpic } from "../../reducers/BacklogSlice";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";

const EpicDrawerFilter = () => {
    const dispatch = useDispatch();
    const filteredEpic = useSelector(getFilteredEpic);
    const filteredProject = useSelector(getFilteredProject);
    const { epics, isLoading } = useGetIssueListEpicsQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            epics: orderBy(
                data?.filter((x) => (!!filteredProject ? x.projectKey === filteredProject?.key : true)),
                "order"
            ),
            isLoading,
        }),
    });
    const getEpicLabel = (epicDto: EpicDto) => {
        return filteredProject ? epicDto.title!! : `(${epicDto.projectAbbreviation!!}) ${epicDto.title!!}`;
    };

    const items = (epics ?? []).map((x) => {
        return {
            key: x.issueKey,
            label: getEpicLabel(x),
            isFiltered: filteredEpic === x.issueKey,
        };
    });

    const handleEpicClick = (epic: { key: string | undefined; label: string; isFiltered: boolean }) => {
        if (epic.isFiltered) {
            dispatch(clearFilteredEpic());
        } else {
            dispatch(setFilteredEpic(epic.key));
        }
    };
    return !isLoading ? (
        <List
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item, index) => (
                <List.Item onClick={() => handleEpicClick(item)} extra={item.isFiltered ? <FilterFilled /> : undefined}>
                    <List.Item.Meta title={item?.label} />
                </List.Item>
            )}
        />
    ) : (
        <Progress />
    );
};

export default EpicDrawerFilter;
