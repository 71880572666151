import { fetchAuthSession } from "aws-amplify/auth";
import Config from "../../config.json";
import { Dispatch } from "react";
import { setAvailableCompanies, setAvailableRoles, setCurrentCompany, setCurrentRole } from "../reducers/CompanySlice";
import { getSelectedCompanyKey } from "../selectors/CompanySelectors";
import { GetState } from "@reduxjs/toolkit";

export function loadInitialAppState(navigate: any) {
    return (dispatch: Dispatch<any>, getState: GetState<any>) => {
        // @ts-ignore
        const state = getState();
        const currentCompanyKey = getSelectedCompanyKey(state);
        fetchAuthSession()
            .then((res) => {
                if (!res.tokens) {
                    return;
                }
                const { accessToken } = res.tokens;
                fetch(`${Config.API_URL}/appcontext/get`, { headers: { authorization: `Bearer ${accessToken}` } })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        dispatch(setAvailableCompanies(data.availableCompanies));
                        dispatch(setAvailableRoles(data.availableRoles));
                        // TODO: Validate current selected company
                        if (data.availableCompanies.length === 1) {
                            dispatch(setCurrentCompany(data.availableCompanies[0].tenant.tenantKey));
                            dispatch(setCurrentRole(data.availableCompanies[0].currentRole.roleKey));
                        } else if (
                            currentCompanyKey &&
                            data.availableCompanies.some((x: any) => x.tenant.tenantKey === currentCompanyKey)
                        ) {
                            //navigate("/backlog-management");
                            // Ok to enter
                        } else {
                            dispatch(setCurrentCompany(undefined));
                            navigate("/company");
                        }
                    });
            })
            .catch((e) => console.log(e));
    };
}
