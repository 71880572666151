import { Select } from "antd";
import React from "react";
import { isNumber } from "lodash";
import StatusType, { mapStatusTypeToFriendlyName } from "../../../../common/types/StatusType";
import issueType from "../../types/IssueType";

const options = Object.values(StatusType)
    .filter((x) => isNumber(x) && x !== StatusType.NotDone)
    .map((x) => {
        return { value: x, label: mapStatusTypeToFriendlyName(x as unknown as StatusType) };
    });

export function EditableStatusTypePill(props: {
    status: StatusType | undefined;
    onStatusTypeUpdate: (value: StatusType) => void;
}) {
    return (
        <Select
            options={options}
            defaultValue={props.status}
            popupMatchSelectWidth={false}
            defaultOpen={false}
            onSelect={props.onStatusTypeUpdate}
            style={{ maxWidth: 140, width: 140, textAlign: "center" }}
        />
    );
}
