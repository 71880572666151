import * as React from "react";
import { Button, Col, Layout, List, Row, Typography } from "antd";
import { CommonStyles } from "../../../common/Styles";
import { getAvailableCompanies } from "../../../common/selectors/CompanySelectors";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCompany, setCurrentRole } from "../../../common/reducers/CompanySlice";
import { useNavigate } from "react-router";
import { resetBacklogState } from "../../backlog-management/reducers/BacklogSlice";
import { emptySplitApi } from "../../../store/emptyApi";
import { Header } from "antd/es/layout/layout";
import { UserThemePreference } from "../../../common/reducers/UserPreferenceSlice";
import { getSelectedTheme } from "../../../common/UserPreferenceSelectors";
import { signOut } from "aws-amplify/auth";
import { EvoLogo } from "../../../common/navigation/navmenu/EvoLogo";
import { ExistingSettingsTenantDto } from "../../../store/generated/settings/tenant";

const CompanySelectPage = () => {
    const availableCompanies = useSelector(getAvailableCompanies);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activeUserThemePreference = useSelector(getSelectedTheme);
    const onCompanySelect = (company: ExistingSettingsTenantDto) => {
        dispatch(emptySplitApi.util.resetApiState());
        dispatch(resetBacklogState());
        dispatch(setCurrentCompany(company.tenantKey));
        dispatch(
            setCurrentRole(
                availableCompanies.find((x: any) => x.tenant.tenantKey === company.tenantKey).currentRole.roleKey
            )
        );
        navigate("/backlog-management");
    };
    return (
        <Layout style={{ flex: 1, marginBottom: 54 }}>
            <Header
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    backgroundColor:
                        activeUserThemePreference == UserThemePreference.Dark ? "rgb(20, 20, 20)" : "white",
                    border: "1px solid rgba(253, 253, 253, 0.12)",
                    borderColor:
                        activeUserThemePreference == UserThemePreference.Dark
                            ? "rgba(253, 253, 253, 0.12)"
                            : "rgba(5, 5, 5, 0.06)",
                }}
            >
                <Row>
                    <Col
                        flex={1}
                        style={{
                            paddingLeft: 15,
                            lineHeight: "initial",
                            borderBottomWidth: 0,
                        }}
                    >
                        <EvoLogo onClick={() => {}} activeUserThemePreference={activeUserThemePreference} />
                    </Col>
                    <Col
                        flex="auto"
                        style={{
                            textAlign: "right",
                            lineHeight: "initial",
                            borderBottomStyle: "solid",
                            borderBottomWidth: 0,
                        }}
                    >
                        <Button
                            style={{ marginRight: 15, marginTop: 15 }}
                            onClick={() => {
                                signOut().then(() => {
                                    dispatch({ type: "USER_LOGOUT" });
                                    navigate("/login");
                                });
                            }}
                        >
                            Log Out
                        </Button>
                    </Col>
                </Row>
            </Header>
            <List
                style={{ marginTop: 15 }}
                bordered
                dataSource={availableCompanies}
                renderItem={(item: { tenant: ExistingSettingsTenantDto; currentRole: any }) => (
                    <List.Item
                        style={CommonStyles.link}
                        onClick={() => onCompanySelect(item.tenant)}
                        key={item.tenant.tenantKey}
                    >
                        <Typography.Text>{item.tenant.name}</Typography.Text>
                    </List.Item>
                )}
            />
        </Layout>
    );
};

export default CompanySelectPage;
