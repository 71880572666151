import React, { useState } from "react";
import { useGetIssueListQuery } from "../../api/IssueApi";
import { orderBy } from "lodash";
import IssueTypePill from "../../../../common/components/IssueTypePill";
import IssueType from "../../types/IssueType";
import { useSelector } from "react-redux";
import {
    getFilteredIssues,
    getFilteredProject,
    getFilteredStatus,
    getFilteredTeam,
} from "../../selectors/BacklogSelectors";
import { EpicDto, IssueDto, PriorityType, ProjectDto, StatusType } from "../../../../store/generated/issue";
import BacklogIssueList from "./BacklogIssueList";
import "../../PlanningStyles.css";
import { getPriorityTypeColor, mapPriorityTypeToLabel } from "../../types/PriorityType";
import { mapStatusTypeToFriendlyName } from "../../../../common/types/StatusType";
import { EpicLabel } from "./EpicLabel";
import { useShowViewOptionsDrawerListener } from "../../../../common/events";
import { UserDto } from "../../../../store/generated/user";
import UserAvatar from "../../../../common/components/UserAvatar";
import { AlignType } from "rc-table/lib/interface";
import FilterDrawer from "../filters/FilterDrawer";
import { getSelectedTheme } from "../../../../common/UserPreferenceSelectors";
import SprintLabel from "./SprintLabel";
import { TableColumnsType, Tag } from "antd";
import { isAllowedToChangeIssueOrder } from "../../../../common/selectors/RoleSelectors";
import { useGetSprintListQuery } from "../../../../store/generated/sprint";
import { NIL as NIL_UUID } from "uuid";
import InvalidFilterState from "../InvalidFilterState";

function BacklogListPage() {
    const canChangeOrder = useSelector(isAllowedToChangeIssueOrder);
    const filteredTeam = useSelector(getFilteredTeam);
    const activeUserThemePreference = useSelector(getSelectedTheme);
    const filteredProject = useSelector(getFilteredProject);
    const filteredStatus = useSelector(getFilteredStatus);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    useShowViewOptionsDrawerListener(() => {
        setIsDrawerOpen(true);
    });

    const { data: sprints } = useGetSprintListQuery({ teamKey: filteredTeam?.key }, { skip: !filteredTeam });
    const {
        data,
        error,
        isLoading = true,
    } = useGetIssueListQuery(
        {
            projectKey: filteredProject?.key,
            teamKey: filteredTeam?.key,
            statusType: filteredStatus?.key as unknown as StatusType,
        },
        {
            selectFromResult: ({ data, error, isLoading }) => ({
                data: orderBy(data, "order"),
                error,
                isLoading,
            }),
            pollingInterval: undefined,
            skip: !filteredTeam,
        }
    );

    const filteredIssues = useSelector((state) => getFilteredIssues(state, data));
    const columns: TableColumnsType<IssueDto> = [
        {
            title: "Assignee",
            dataIndex: "assignee",
            key: "assignee",
            align: "center" as AlignType,
            responsive: ["sm"],
            render: (user: UserDto) => (user ? <UserAvatar user={user} /> : undefined),
        },
        {
            title: "Issue",
            dataIndex: "projectIssueKeyedLabel",
            align: "center" as AlignType,
            key: "type",
        },
        {
            title: "Title",
            dataIndex: "title",
            align: "start" as AlignType,
            key: "title",
        },
        {
            title: "Type",
            dataIndex: "issueType",
            key: "type",
            align: "start" as AlignType,
            responsive: ["md"],
            render: (text: string) => <IssueTypePill issueType={text as unknown as IssueType} />,
        },
        {
            title: "Epic",
            dataIndex: "epic",
            key: "epic",
            align: "center" as AlignType,
            responsive: ["md"],
            render: (epic: EpicDto, issue: IssueDto) =>
                issue.issueType === IssueType.Initiative || issue.issueType == IssueType.Epic ? undefined : (
                    <EpicLabel epic={epic} />
                ),
        },
        {
            title: "Priority",
            dataIndex: "priorityType",
            key: "priorityType",
            align: "start" as AlignType,
            responsive: ["lg"],
            render: (priority: PriorityType) => (
                <Tag color={getPriorityTypeColor(priority)}>{mapPriorityTypeToLabel(priority)}</Tag>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center" as AlignType,
            responsive: ["sm"],
            render: (statusType: StatusType) => mapStatusTypeToFriendlyName(statusType),
        },
        {
            title: "Effort",
            dataIndex: "storyPoints",
            key: "storyPoints",
            align: "start" as AlignType,
            responsive: ["md"],
            render: (points: number) => (points ? <Tag>{points}</Tag> : undefined),
        },
    ];

    const activeColumns = () => {
        if (filteredProject) {
            return columns;
        } else {
            const copy = [...columns];
            copy.splice(4, 0, {
                title: "Project",
                dataIndex: "project",
                key: "project",
                align: "center" as AlignType,
                render: (project: ProjectDto) => project?.name as any,
            });
            return copy;
        }
    };

    return (
        <div style={{ marginTop: 15 }}>
            <FilterDrawer
                open={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                }}
            />
            {!filteredTeam ? (
                <InvalidFilterState />
            ) : (
                sprints?.map((sprint, index) => {
                    return (
                        <div key={index.toString()}>
                            <SprintLabel activeUserThemePreference={activeUserThemePreference} sprint={sprint} />
                            <BacklogIssueList
                                key={index.toString()}
                                issues={filteredIssues.filter(
                                    (x) => (x.sprintDto?.sprintKey ?? NIL_UUID) === sprint.sprintKey
                                )}
                                columns={activeColumns()}
                                launchModalOnClick={true}
                                isDragAndDropEnabled={filteredProject !== undefined && canChangeOrder}
                                teamKey={filteredTeam?.key}
                            />
                        </div>
                    );
                })
            )}
        </div>
    );
}

export default BacklogListPage;
