import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedCompanyKey: undefined,
    availableCompanies: [],
    selectedRoleKey: undefined,
    availableRoles: [],
};
const companySlice = createSlice({
    name: "company",
    initialState: initialState as any,
    reducers: {
        setCurrentCompany(state, action) {
            state.selectedCompanyKey = action.payload;
        },
        setAvailableCompanies(state, action) {
            state.availableCompanies = action.payload;
        },
        setCurrentRole(state, action) {
            state.selectedRoleKey = action.payload;
        },
        setAvailableRoles(state, action) {
            state.availableRoles = action.payload;
        },
    },
});

export const { setCurrentCompany, setAvailableCompanies, setCurrentRole, setAvailableRoles } = companySlice.actions;
export default companySlice.reducer;
