import { useDispatch, useSelector } from "react-redux";
import { List, Progress } from "antd";
import { clearFilteredProject, setFilteredProject } from "../../reducers/BacklogSlice";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { getFilteredProject } from "../../selectors/BacklogSelectors";
import { useGetSettingsProjectListQuery } from "../../../settings/projects/api/ProjectApi";

const ProjectDrawerFilter = () => {
    const dispatch = useDispatch();
    const { data, isLoading } = useGetSettingsProjectListQuery();
    const filteredProject = useSelector(getFilteredProject);
    const items = (data ?? []).map((x) => ({
        key: x.projectKey,
        label: x.name,
        isFiltered: filteredProject?.key === x.projectKey,
    }));

    const handleProjectClick = (project: {
        key: string | undefined | null;
        label: string | null | undefined;
        isFiltered: boolean;
    }) => {
        if (project.isFiltered) {
            dispatch(clearFilteredProject());
        } else {
            dispatch(setFilteredProject(project));
        }
    };
    return !isLoading ? (
        <List
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item, index) => (
                <List.Item
                    onClick={() => handleProjectClick(item)}
                    extra={item.isFiltered ? <FilterFilled /> : undefined}
                >
                    <List.Item.Meta title={item?.label} />
                </List.Item>
            )}
        />
    ) : (
        <Progress />
    );
};

export default ProjectDrawerFilter;
