import { useDispatch, useSelector } from "react-redux";
import { getFilteredProject, getFilteredUsers } from "../../selectors/BacklogSelectors";
import { List, Progress } from "antd";
import { addFilteredUsers, clearFilteredUser } from "../../reducers/BacklogSlice";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { useGetUserListQuery } from "../../../../store/generated/user";

const UserDrawerFilter = () => {
    const filteredProject = useSelector(getFilteredProject);
    const { data, isLoading } = useGetUserListQuery({ projectKey: filteredProject?.key });
    const filteredUsers = useSelector(getFilteredUsers);
    const dispatch = useDispatch();
    const items = (data ?? []).map((x) => ({
        value: x.tenantUserKey!!,
        label: x.fullName!!,
        isFiltered: filteredUsers.some((filteredUser) => filteredUser.value == x.tenantUserKey!!),
    }));

    const handleUserClick = (user: { value: string; label: string; isFiltered: boolean }) => {
        if (user.isFiltered) {
            dispatch(clearFilteredUser(user.value));
        } else {
            dispatch(addFilteredUsers(user));
        }
    };
    return !isLoading ? (
        <List
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item, index) => (
                <List.Item onClick={() => handleUserClick(item)} extra={item.isFiltered ? <FilterFilled /> : undefined}>
                    <List.Item.Meta title={item?.label} />
                </List.Item>
            )}
        />
    ) : (
        <Progress />
    );
};

export default UserDrawerFilter;
