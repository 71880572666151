import { useEffect } from "react";
import { groupBy, uniqBy } from "lodash";
import { FormInstance } from "antd";

type Props = {
    form: FormInstance;
    validationResult: {
        error: any;
        isLoading: boolean;
        isError: boolean;
    };
};
const useFormValidation = (props: Props) => {
    useEffect(() => {
        if (props.validationResult.isLoading) {
            return;
        }
        if (props.validationResult.isError && (props.validationResult.error as any).status === 400) {
            const errors = (props.validationResult.error as any).data.errors;
            const properties = uniqBy(errors, (x: any) => x.propertyName).map((x) => x.propertyName);
            const groupedErrors = groupBy(errors, "propertyName");
            properties.forEach((x: any) => {
                const errorPropArr = x.split(".");
                const primaryProp = errorPropArr[errorPropArr.length - 1];
                props.form.setFields([
                    {
                        name: primaryProp.charAt(0).toLowerCase() + primaryProp.slice(1),
                        errors: groupedErrors[x].map((x) => x.errorMessage),
                    },
                ]);
            });
        } else {
            props.form.resetFields();
        }
    }, [props.validationResult]);
};

export default useFormValidation;
