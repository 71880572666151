import { enhancedApi as api } from "../../../../store/generated/sprint";
import { enhancedApi as settingsApi } from "../../../../store/generated/settings/sprint";

const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ["sprint"],
    endpoints: {
        getSprintsSprintGet: {
            providesTags: ["sprint"],
        },
        postSprintsSprintCloseSprint: {
            invalidatesTags: ["sprint"],
        },
        postSprintsSprintOpenSprint: {
            invalidatesTags: ["sprint"],
        },
    },
});

const settingsEnhancedApi = settingsApi.enhanceEndpoints({
    addTagTypes: ["sprint"],
    endpoints: {
        getSettingsSprintGet: {
            providesTags: ["sprint"],
        },
        getSettingsSprintList: {
            providesTags: ["sprint"],
        },
        postSettingsSprintAdd: {
            invalidatesTags: ["sprint"],
        },
        putSettingsSprintUpdate: {
            invalidatesTags: ["sprint"],
        },
        deleteSettingsSprintDelete: {
            invalidatesTags: ["sprint"],
        },
    },
});

const combined = {
    ...enhancedApi,
    ...settingsEnhancedApi,
};

export const {
    useGetSprintsSprintGetQuery,
    usePostSprintsSprintCloseSprintMutation,
    useGetSettingsSprintGetQuery,
    useGetSettingsSprintListQuery,
    usePostSettingsSprintAddMutation,
    usePutSettingsSprintUpdateMutation,
    useDeleteSettingsSprintDeleteMutation,
    usePostSprintsSprintOpenSprintMutation,
} = combined;
