import { enhancedApi as api } from "../../../../store/generated/settings/user";

const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ["User"],
    endpoints: {
        getSettingsUserList: {
            providesTags: ["User"],
        },
        getSettingsUserGet: {
            providesTags: ["User"],
        },
        postSettingsUserAdd: {
            invalidatesTags: ["User"],
        },
        putSettingsUserUpdate: {
            invalidatesTags: ["User"],
        },
        deleteSettingsUserDelete: {
            invalidatesTags: ["User"],
        },
    },
});

export const {
    useGetSettingsUserListQuery,
    useGetSettingsUserGetQuery,
    usePostSettingsUserAddMutation,
    usePutSettingsUserUpdateMutation,
    useDeleteSettingsUserDeleteMutation,
} = enhancedApi;
