import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    projectKey: undefined,
};
const projectSlice = createSlice({
    name: "project",
    initialState: initialState as any,
    reducers: {
        setCurrentProject(state, action) {
            state.projectKey = action.payload.projectKey;
        },
    },
});

export const { setCurrentProject } = projectSlice.actions;
export default projectSlice.reducer;
