import { enhancedApi as api } from "../../../../store/generated/settings/project";

const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ["Project"],
    endpoints: {
        getSettingsProjectList: {
            providesTags: ["Project"],
        },
        getSettingsProjectGet: {
            providesTags: ["Project"],
        },
        postSettingsProjectAdd: {
            invalidatesTags: ["Project"],
        },
        putSettingsProjectUpdate: {
            invalidatesTags: ["Project"],
        },
        deleteSettingsProjectDelete: {
            invalidatesTags: ["Project"],
        },
    },
});

export const {
    useGetSettingsProjectListQuery,
    useGetSettingsProjectGetQuery,
    usePostSettingsProjectAddMutation,
    usePutSettingsProjectUpdateMutation,
    useDeleteSettingsProjectDeleteMutation,
} = enhancedApi;
