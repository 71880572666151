import { UserThemePreference } from "../../reducers/UserPreferenceSlice";
import * as React from "react";

export function EvoLogo(props: { onClick: () => void; activeUserThemePreference: UserThemePreference }) {
    return (
        <a
            onClick={props.onClick}
            style={{
                marginTop: 10,
                display: "inline-block",
                fontWeight: "bold",
                fontSize: 22,
                padding: 8,
                borderRadius: 6,
                borderWidth: 1,
                borderStyle: "solid",
                boxShadow: "rgba(0, 0, 0, 0.02) 0px 2px 0px 0px",
                borderColor:
                    props.activeUserThemePreference == UserThemePreference.Dark
                        ? "rgba(253, 253, 253, 0.12)"
                        : "rgb(217, 217, 217)",
                backgroundColor:
                    props.activeUserThemePreference == UserThemePreference.Dark ? "rgb(20, 20, 20)" : undefined,
                border:
                    props.activeUserThemePreference == UserThemePreference.Dark
                        ? "1px solid rgba(253, 253, 253, 0.12)"
                        : "1px solid rgba(5, 5, 5, 0.06)",
                textDecoration: "none",
                color:
                    props.activeUserThemePreference == UserThemePreference.Dark
                        ? "rgba(255, 255, 255, 0.85)"
                        : "rgba(0, 0, 0, 0.88)",
            }}
        >
            Evo
        </a>
    );
}
