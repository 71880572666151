import { createSlice } from "@reduxjs/toolkit";

export interface ChecklistItem {
    item: string;
    version: number;
    progress: number;
    availableProgress: number;
    completed: boolean;
}
interface UserOnboardingState {
    checklist: ChecklistItem[];
    activeItemKey: string | undefined;
}
const initialState: UserOnboardingState = {
    checklist: [
        {
            item: "Global Tour",
            version: 1,
            progress: 0,
            availableProgress: 10,
            completed: false,
        },
    ],
    activeItemKey: undefined,
};
export const userOnboardingSlice = createSlice({
    name: "userOnboarding",
    initialState: initialState,
    reducers: {
        markChecklistItemComplete: (
            state,
            action: { type: string; payload: { item: keyof UserOnboardingState["checklist"] } }
        ) => {
            const index = state.checklist.findIndex((x) => x.item === action.payload.item);
            state.checklist[index].completed = true;
        },
        markChecklistItemIncomplete: (
            state,
            action: { type: string; payload: { item: keyof UserOnboardingState["checklist"] } }
        ) => {
            const index = state.checklist.findIndex((x) => x.item === action.payload.item);
            state.checklist[index].completed = false;
        },
        setOnboardingTaskInProgress: (state, action) => {
            state.activeItemKey = action.payload.inProgress ? action.payload.activeItemKey : undefined;
        },
    },
    selectors: {
        getAllChecklistItems: (state) => state.checklist,
        getIncompleteChecklistItemCount: (state) => state.checklist.filter((x) => !x.completed).length,
        getIncompleteChecklistItems: (state) => state.checklist.filter((x) => !x.completed),
        getCompleteChecklistItems: (state) => state.checklist.filter((x) => x.completed),
        getOnboardingTaskInProgress: (state) => state.activeItemKey,
    },
});

export const {} = userOnboardingSlice.actions;
export default userOnboardingSlice.reducer;
