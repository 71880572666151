import { Button, Checkbox, List, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChecklistItem, userOnboardingSlice } from "../../../common/reducers/UserOnboardingSlice";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const OnboardingTasksModal = (props: Props) => {
    const allChecklistItems = useSelector(userOnboardingSlice.selectors.getAllChecklistItems);
    const inProgressItem = useSelector(userOnboardingSlice.selectors.getOnboardingTaskInProgress);
    const dispatch = useDispatch();
    function continueOnboardingTask(onboardingTask: ChecklistItem) {
        dispatch(
            userOnboardingSlice.actions.setOnboardingTaskInProgress({
                activeItemKey: onboardingTask.item,
                inProgress: true,
            })
        );
    }
    function stopOnboardingTask(onboardingTask: ChecklistItem) {
        dispatch(
            userOnboardingSlice.actions.setOnboardingTaskInProgress({
                activeItemKey: onboardingTask.item,
                inProgress: false,
            })
        );
    }
    return (
        <Modal open={props.isOpen} onCancel={props.onClose}>
            <List>
                {allChecklistItems.map((x) => (
                    <List.Item key={x.item}>
                        <List.Item.Meta
                            title={x.item}
                            description={x.progress + " of " + x.availableProgress + " completed"}
                        />
                        <div>
                            {inProgressItem === x.item ? (
                                <Button
                                    type="default"
                                    onClick={() => {
                                        stopOnboardingTask(x);
                                    }}
                                    style={{ marginRight: 10 }}
                                >
                                    Stop
                                </Button>
                            ) : (
                                <Button
                                    type="default"
                                    onClick={() => {
                                        continueOnboardingTask(x);
                                    }}
                                    style={{ marginRight: 10 }}
                                >
                                    Continue
                                </Button>
                            )}
                            <Checkbox checked={x.completed} />
                        </div>
                    </List.Item>
                ))}
            </List>
        </Modal>
    );
};

export default OnboardingTasksModal;
