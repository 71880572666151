import Reactotron, { trackGlobalErrors } from "reactotron-react-js";
import { reactotronRedux } from "reactotron-redux";

const reactotron = Reactotron.configure()
    .use(
        trackGlobalErrors({
            veto: (frame) => frame.fileName.indexOf("/node_modules/react-native/") >= 0,
        })
    )
    .use(reactotronRedux())
    .connect();
export default reactotron;
