import React from "react";
import { Table } from "antd";
import { useNavigate } from "react-router";
import SettingsToolbar from "../../SettingsToolbar";
import { useGetSettingsUserListQuery } from "../api/UserApi";
import { ExistingSettingsUserDto } from "../../../../store/generated/settings/user";

const UserList = (props: any) => {
    const { data, error, isLoading } = useGetSettingsUserListQuery();
    const navigate = useNavigate();
    const onEdit = (user?: ExistingSettingsUserDto) => {
        if (user) {
            navigate(`/settings/user/details?userKey=${user?.userKey ?? ""}`);
        } else {
            navigate(`/settings/user/details`);
        }
    };

    const columns = [
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
    ];
    return (
        <>
            <SettingsToolbar onClick={() => onEdit()} />
            <Table
                dataSource={data}
                columns={columns}
                rowKey={(record) => record.userKey!!}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            onEdit(record);
                        }, // click row
                    };
                }}
            />
        </>
    );
};

export default UserList;
