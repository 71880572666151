import { createSlice } from "@reduxjs/toolkit";

export enum UserThemePreference {
    Light,
    Dark,
}

const initialState = {
    selectedTheme: UserThemePreference.Dark,
};

const userPreferenceSlice = createSlice({
    name: "userPreference",
    initialState: initialState,
    reducers: {
        selectTheme(state, action) {
            state.selectedTheme = action.payload;
        },
    },
});

export const { selectTheme } = userPreferenceSlice.actions;
export default userPreferenceSlice.reducer;
