import { InputNumber } from "antd";
import React from "react";

export function EditableStoryPointsInput(props: {
    storyPoints: number | null | undefined;
    onStoryPointsUpdate: (value: number | string | null) => void;
}) {
    return (
        <InputNumber
            type="number"
            defaultValue={props.storyPoints ?? undefined}
            onChange={props.onStoryPointsUpdate}
            style={{ maxWidth: 140, width: 140, textAlign: "center" }}
            precision={1}
        />
    );
}
