import { Authenticator } from "@aws-amplify/ui-react";
import * as React from "react";
import { signUp } from "aws-amplify/auth";
import "./amplify-styles.css";

const Login = () => {
    const services = {
        async handleSignUp(formData: any) {
            let { username, password, attributes } = formData;
            // custom username
            username = username.toLowerCase();
            attributes.email = attributes.email.toLowerCase();
            return signUp({
                username,
                password,
                options: {
                    userAttributes: attributes,
                    autoSignIn: {
                        enabled: true,
                    },
                },
            });
        },
    };
    return (
        <Authenticator
            services={services}
            loginMechanisms={["username", "email"]}
            signUpAttributes={["name", "phone_number"]}
        />
    );
};

export default Login;
