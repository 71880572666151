import { emptySplitApi as api } from "../../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSettingsTenantList: build.query<GetSettingsTenantListApiResponse, GetSettingsTenantListApiArg>({
            query: () => ({ url: `/settings/Tenant/List` }),
        }),
        getSettingsTenantGet: build.query<GetSettingsTenantGetApiResponse, GetSettingsTenantGetApiArg>({
            query: (queryArg) => ({
                url: `/settings/Tenant/Get`,
                params: { tenantKey: queryArg.tenantKey },
            }),
        }),
        postSettingsTenantAdd: build.mutation<PostSettingsTenantAddApiResponse, PostSettingsTenantAddApiArg>({
            query: (queryArg) => ({
                url: `/settings/Tenant/Add`,
                method: "POST",
                body: queryArg.addSettingsTenantDto,
            }),
        }),
        putSettingsTenantUpdate: build.mutation<PutSettingsTenantUpdateApiResponse, PutSettingsTenantUpdateApiArg>({
            query: (queryArg) => ({
                url: `/settings/Tenant/Update`,
                method: "PUT",
                body: queryArg.existingSettingsTenantDto,
            }),
        }),
        deleteSettingsTenantDelete: build.mutation<
            DeleteSettingsTenantDeleteApiResponse,
            DeleteSettingsTenantDeleteApiArg
        >({
            query: (queryArg) => ({
                url: `/settings/Tenant/Delete`,
                method: "DELETE",
                params: { tenantKey: queryArg.tenantKey },
            }),
        }),
        getSettingsTenantUserGet: build.query<GetSettingsTenantUserGetApiResponse, GetSettingsTenantUserGetApiArg>({
            query: (queryArg) => ({
                url: `/settings/TenantUser/Get`,
                params: { userKey: queryArg.userKey },
            }),
        }),
        getSettingsTenantUserList: build.query<GetSettingsTenantUserListApiResponse, GetSettingsTenantUserListApiArg>({
            query: () => ({ url: `/settings/TenantUser/List` }),
        }),
        putSettingsTenantUserSave: build.mutation<
            PutSettingsTenantUserSaveApiResponse,
            PutSettingsTenantUserSaveApiArg
        >({
            query: (queryArg) => ({
                url: `/settings/TenantUser/Save`,
                method: "PUT",
                body: queryArg.updateSettingsTenantUserAccessDto,
            }),
        }),
        putSettingsTenantUserChangeRole: build.mutation<
            PutSettingsTenantUserChangeRoleApiResponse,
            PutSettingsTenantUserChangeRoleApiArg
        >({
            query: (queryArg) => ({
                url: `/settings/TenantUser/ChangeRole`,
                method: "PUT",
                body: queryArg.changeTenantUserRoleDto,
            }),
        }),
        postSettingsTenantUserInvite: build.mutation<
            PostSettingsTenantUserInviteApiResponse,
            PostSettingsTenantUserInviteApiArg
        >({
            query: (queryArg) => ({
                url: `/settings/TenantUser/Invite`,
                method: "POST",
                body: queryArg.settingsUserInviteDto,
            }),
        }),
        postSettingsTenantUserAcceptInvite: build.mutation<
            PostSettingsTenantUserAcceptInviteApiResponse,
            PostSettingsTenantUserAcceptInviteApiArg
        >({
            query: (queryArg) => ({
                url: `/settings/TenantUser/AcceptInvite`,
                method: "POST",
                body: queryArg.acceptSettingsUserInviteDto,
            }),
        }),
        deleteSettingsTenantUserDelete: build.mutation<
            DeleteSettingsTenantUserDeleteApiResponse,
            DeleteSettingsTenantUserDeleteApiArg
        >({
            query: (queryArg) => ({
                url: `/settings/TenantUser/Delete`,
                method: "DELETE",
                params: { tenantKey: queryArg.tenantKey, userKey: queryArg.userKey },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetSettingsTenantListApiResponse = /** status 200 Success */ ExistingSettingsTenantDto[];
export type GetSettingsTenantListApiArg = void;
export type GetSettingsTenantGetApiResponse = /** status 200 Success */ ExistingSettingsTenantDto;
export type GetSettingsTenantGetApiArg = {
    tenantKey?: string;
};
export type PostSettingsTenantAddApiResponse = /** status 200 Success */ string;
export type PostSettingsTenantAddApiArg = {
    addSettingsTenantDto: AddSettingsTenantDto;
};
export type PutSettingsTenantUpdateApiResponse = /** status 200 Success */ undefined;
export type PutSettingsTenantUpdateApiArg = {
    existingSettingsTenantDto: ExistingSettingsTenantDto;
};
export type DeleteSettingsTenantDeleteApiResponse = /** status 200 Success */ undefined;
export type DeleteSettingsTenantDeleteApiArg = {
    tenantKey?: string;
};
export type GetSettingsTenantUserGetApiResponse = /** status 200 Success */ SettingsTenantUserAccessDto;
export type GetSettingsTenantUserGetApiArg = {
    userKey?: string;
};
export type GetSettingsTenantUserListApiResponse = /** status 200 Success */ ExistingSettingsUserDto[];
export type GetSettingsTenantUserListApiArg = void;
export type PutSettingsTenantUserSaveApiResponse = /** status 200 Success */ undefined;
export type PutSettingsTenantUserSaveApiArg = {
    updateSettingsTenantUserAccessDto: UpdateSettingsTenantUserAccessDto;
};
export type PutSettingsTenantUserChangeRoleApiResponse = /** status 200 Success */ undefined;
export type PutSettingsTenantUserChangeRoleApiArg = {
    changeTenantUserRoleDto: ChangeTenantUserRoleDto;
};
export type PostSettingsTenantUserInviteApiResponse = /** status 200 Success */ undefined;
export type PostSettingsTenantUserInviteApiArg = {
    settingsUserInviteDto: SettingsUserInviteDto;
};
export type PostSettingsTenantUserAcceptInviteApiResponse = /** status 200 Success */ undefined;
export type PostSettingsTenantUserAcceptInviteApiArg = {
    acceptSettingsUserInviteDto: AcceptSettingsUserInviteDto;
};
export type DeleteSettingsTenantUserDeleteApiResponse = /** status 200 Success */ undefined;
export type DeleteSettingsTenantUserDeleteApiArg = {
    tenantKey?: string;
    userKey?: string;
};
export type TenantType = 0;
export type ExistingSettingsTenantDto = {
    tenantKey?: string;
    name?: string | null;
    uriName?: string | null;
    tenantType?: TenantType;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type AddSettingsTenantDto = {
    tenantKey?: string | null;
    name?: string | null;
    uriName?: string | null;
    tenantType?: TenantType;
};
export type SettingsTenantUserAccessDto = {
    userKey?: string;
    lastName?: string | null;
    firstName?: string | null;
    tenantKeys?: string[] | null;
    availableTenants?: ExistingSettingsTenantDto[] | null;
};
export type ExistingSettingsUserDto = {
    userKey?: string | null;
    tenantUserKey?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    email?: string | null;
    initials?: string | null;
    roleKeys?: string[] | null;
    teamKeys?: string[] | null;
};
export type UpdateSettingsTenantUserAccessDto = {
    userKey?: string;
    tenantKeys?: string[] | null;
};
export type ChangeTenantUserRoleDto = {
    roleKey?: string;
};
export type SettingsUserInviteDto = {
    email?: string | null;
};
export type AcceptSettingsUserInviteDto = {
    tenantKey?: string;
};
export const {
    useGetSettingsTenantListQuery,
    useGetSettingsTenantGetQuery,
    usePostSettingsTenantAddMutation,
    usePutSettingsTenantUpdateMutation,
    useDeleteSettingsTenantDeleteMutation,
    useGetSettingsTenantUserGetQuery,
    useGetSettingsTenantUserListQuery,
    usePutSettingsTenantUserSaveMutation,
    usePutSettingsTenantUserChangeRoleMutation,
    usePostSettingsTenantUserInviteMutation,
    usePostSettingsTenantUserAcceptInviteMutation,
    useDeleteSettingsTenantUserDeleteMutation,
} = injectedRtkApi;
