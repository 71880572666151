import { emptySplitApi as api } from "../../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSettingsUserGet: build.query<GetSettingsUserGetApiResponse, GetSettingsUserGetApiArg>({
            query: (queryArg) => ({
                url: `/settings/User/Get`,
                params: { userKey: queryArg.userKey },
            }),
        }),
        getSettingsUserList: build.query<GetSettingsUserListApiResponse, GetSettingsUserListApiArg>({
            query: () => ({ url: `/settings/User/List` }),
        }),
        postSettingsUserAdd: build.mutation<PostSettingsUserAddApiResponse, PostSettingsUserAddApiArg>({
            query: (queryArg) => ({
                url: `/settings/User/Add`,
                method: "POST",
                body: queryArg.addSettingsUserDto,
            }),
        }),
        putSettingsUserUpdate: build.mutation<PutSettingsUserUpdateApiResponse, PutSettingsUserUpdateApiArg>({
            query: (queryArg) => ({
                url: `/settings/User/Update`,
                method: "PUT",
                body: queryArg.existingSettingsUserDto,
            }),
        }),
        deleteSettingsUserDelete: build.mutation<DeleteSettingsUserDeleteApiResponse, DeleteSettingsUserDeleteApiArg>({
            query: (queryArg) => ({
                url: `/settings/User/Delete`,
                method: "DELETE",
                params: { userKey: queryArg.userKey },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetSettingsUserGetApiResponse = /** status 200 Success */ SettingsUserDetailsDto;
export type GetSettingsUserGetApiArg = {
    userKey?: string;
};
export type GetSettingsUserListApiResponse = /** status 200 Success */ ExistingSettingsUserDto[];
export type GetSettingsUserListApiArg = void;
export type PostSettingsUserAddApiResponse = /** status 200 Success */ string;
export type PostSettingsUserAddApiArg = {
    addSettingsUserDto: AddSettingsUserDto;
};
export type PutSettingsUserUpdateApiResponse = /** status 200 Success */ undefined;
export type PutSettingsUserUpdateApiArg = {
    existingSettingsUserDto: ExistingSettingsUserDto;
};
export type DeleteSettingsUserDeleteApiResponse = /** status 200 Success */ undefined;
export type DeleteSettingsUserDeleteApiArg = {
    userKey?: string;
};
export type ExistingSettingsUserDto = {
    userKey?: string | null;
    tenantUserKey?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    email?: string | null;
    initials?: string | null;
    roleKeys?: string[] | null;
    teamKeys?: string[] | null;
};
export type AccessType = 0 | 1 | 2 | 3 | 4 | 5;
export type ExistingSettingsRoleDto = {
    roleKey?: string | null;
    name?: string | null;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string;
    isDeleted?: boolean;
    accessType?: AccessType;
};
export type MethodologyType = 0 | 1 | 2;
export type TenantType = 0;
export type Tenant = {
    tenantKey?: string;
    uriName?: string | null;
    name?: string | null;
    tenantType?: TenantType;
    users?: User[] | null;
};
export type Role = {
    roleKey?: string;
    name?: string | null;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string | null;
    isDeleted?: boolean;
    accessType?: AccessType;
    users?: User[] | null;
};
export type Team = {
    teamKey?: string;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string | null;
};
export type User = {
    userKey?: string;
    lastName?: string | null;
    firstName?: string | null;
    email?: string | null;
    tenant?: Tenant[] | null;
    roles?: Role[] | null;
    teams?: Team[] | null;
};
export type AddSettingsTeamDto = {
    teamKey?: string | null;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string;
};
export type SettingsUserDetailsDto = {
    user?: ExistingSettingsUserDto;
    availableRoles?: ExistingSettingsRoleDto[] | null;
    availableTeams?: AddSettingsTeamDto[] | null;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type AddSettingsUserDto = {
    tenantUserKey?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    email?: string | null;
    initials?: string | null;
    roleKeys?: string[] | null;
    teamKeys?: string[] | null;
    userKey?: string | null;
};
export const {
    useGetSettingsUserGetQuery,
    useGetSettingsUserListQuery,
    usePostSettingsUserAddMutation,
    usePutSettingsUserUpdateMutation,
    useDeleteSettingsUserDeleteMutation,
} = injectedRtkApi;
