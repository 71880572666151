import { emptySplitApi as api } from "../../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSettingsRoleGet: build.query<GetSettingsRoleGetApiResponse, GetSettingsRoleGetApiArg>({
            query: (queryArg) => ({
                url: `/settings/Role/Get`,
                params: { roleKey: queryArg.roleKey },
            }),
        }),
        getSettingsRoleList: build.query<GetSettingsRoleListApiResponse, GetSettingsRoleListApiArg>({
            query: () => ({ url: `/settings/Role/List` }),
        }),
        postSettingsRoleAdd: build.mutation<PostSettingsRoleAddApiResponse, PostSettingsRoleAddApiArg>({
            query: (queryArg) => ({
                url: `/settings/Role/Add`,
                method: "POST",
                body: queryArg.addSettingsRoleDto,
            }),
        }),
        putSettingsRoleUpdate: build.mutation<PutSettingsRoleUpdateApiResponse, PutSettingsRoleUpdateApiArg>({
            query: (queryArg) => ({
                url: `/settings/Role/Update`,
                method: "PUT",
                body: queryArg.existingSettingsRoleDto,
            }),
        }),
        deleteSettingsRoleDelete: build.mutation<DeleteSettingsRoleDeleteApiResponse, DeleteSettingsRoleDeleteApiArg>({
            query: (queryArg) => ({
                url: `/settings/Role/Delete`,
                method: "DELETE",
                params: { roleKey: queryArg.roleKey },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetSettingsRoleGetApiResponse = /** status 200 Success */ ExistingSettingsRoleDto;
export type GetSettingsRoleGetApiArg = {
    roleKey?: string;
};
export type GetSettingsRoleListApiResponse = /** status 200 Success */ ExistingSettingsRoleDto[];
export type GetSettingsRoleListApiArg = void;
export type PostSettingsRoleAddApiResponse = /** status 200 Success */ string;
export type PostSettingsRoleAddApiArg = {
    addSettingsRoleDto: AddSettingsRoleDto;
};
export type PutSettingsRoleUpdateApiResponse = /** status 200 Success */ undefined;
export type PutSettingsRoleUpdateApiArg = {
    existingSettingsRoleDto: ExistingSettingsRoleDto;
};
export type DeleteSettingsRoleDeleteApiResponse = /** status 200 Success */ undefined;
export type DeleteSettingsRoleDeleteApiArg = {
    roleKey?: string;
};
export type AccessType = 0 | 1 | 2 | 3 | 4 | 5;
export type ExistingSettingsRoleDto = {
    roleKey?: string | null;
    name?: string | null;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string;
    isDeleted?: boolean;
    accessType?: AccessType;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type AddSettingsRoleDto = {
    name?: string | null;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string;
    isDeleted?: boolean;
    accessType?: AccessType;
    roleKey?: string | null;
};
export const {
    useGetSettingsRoleGetQuery,
    useGetSettingsRoleListQuery,
    usePostSettingsRoleAddMutation,
    usePutSettingsRoleUpdateMutation,
    useDeleteSettingsRoleDeleteMutation,
} = injectedRtkApi;
