import { Form, Progress } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedTheme } from "../../../../common/UserPreferenceSelectors";
import {
    ExistingUserBacklogHandlingType,
    useGetSprintsSprintGetQuery,
    usePostSprintsSprintOpenSprintMutation,
} from "../../../../store/generated/sprint";
import { reduce } from "lodash";
import useFormValidation from "../../../../common/components/useFormValidation";
import { usePostSprintsSprintCloseSprintMutation } from "../api/SprintApi";
import { SprintStatus } from "../../types/SprintStatus";
import { CloseSprintModal, existingBacklogItemHandlingOptions, items } from "./CloseSprintModal";
import { PresprintActionType } from "../types/PresprintActionType";
import { SprintFormData } from "./SprintFormData";
import { OpenSprintModal } from "./OpenSprintModal";

type Props = {
    sprintKey: string;
    open: boolean;
    onClose: () => void;
};

const ManageSprintModal = (props: Props) => {
    const [form] = Form.useForm();
    const [submittable, setSubmittable] = useState(true);
    const activeUserThemePreference = useSelector(getSelectedTheme);
    const [closeSprint, closeSprintResult] = usePostSprintsSprintCloseSprintMutation();
    const [openSprint, openSprintResult] = usePostSprintsSprintOpenSprintMutation();
    const { data, isLoading } = useGetSprintsSprintGetQuery({ sprintKey: props.sprintKey });
    useFormValidation({ form, validationResult: closeSprintResult as any });
    const [formData, setFormData] = useState({
        existingUserBacklogHandling: existingBacklogItemHandlingOptions[0],
        sprintNoteRecipients: [],
        checked: items.map((x) => x.key),
    } as SprintFormData);

    function handleFormDataChange(property: keyof SprintFormData, value: any) {
        setFormData({ ...formData, [property]: value });
    }

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then((x) => {
            const errorLengths = form.getFieldsError().map((x) => x.errors.length);
            setSubmittable(reduce(errorLengths, (cur: number, agg: number) => cur + agg, 0) === 0);
        });
    }, [formData.checked, formData.existingUserBacklogHandling, formData.sprintNoteRecipients]);

    function handleSubmit() {
        data?.nextStatus === SprintStatus.Closed
            ? closeSprint({
                  closeSprintDto: {
                      sprintKey: props.sprintKey,
                      includedActions: formData.checked,
                      existingUserBacklogHandling: existingBacklogItemHandlingOptions.findIndex(
                          (x) => x === formData.existingUserBacklogHandling
                      ) as ExistingUserBacklogHandlingType,
                      releaseNoteRecipients: formData.sprintNoteRecipients,
                  },
              })
            : openSprint({ openSprintDto: { sprintKey: props.sprintKey } });
        props.onClose();
    }

    useEffect(() => {
        if (
            !data?.hasUpcomingSprint &&
            formData.checked.some((x) => x == PresprintActionType.IncludeIncompleteSprints) &&
            formData.existingUserBacklogHandling === "Next Sprint"
        ) {
            form.setFields([
                {
                    name: "existingUserBacklogHandling",
                    errors: ["There is no upcoming sprint"],
                },
            ]);
        } else {
            form.resetFields();
        }
    }, [formData.existingUserBacklogHandling, data?.hasUpcomingSprint, formData.checked]);

    return isLoading || !data ? undefined : (
        <>
            {data.nextStatus === SprintStatus.Open ? (
                <OpenSprintModal
                    open={props.open}
                    onCancel={props.onClose}
                    onOk={handleSubmit}
                    submittable={submittable}
                    activeUserThemePreference={activeUserThemePreference}
                    unpointedIssues={data?.unpointedIssues ?? undefined}
                />
            ) : (
                <CloseSprintModal
                    open={props.open}
                    onCancel={props.onClose}
                    onOk={handleSubmit}
                    sprintSummary={data!!}
                    form={form}
                    submittable={submittable}
                    initialValues={formData}
                    formData={formData}
                    activeUserThemePreference={activeUserThemePreference}
                    handleFormDataChange={handleFormDataChange}
                />
            )}
        </>
    );
};

export default ManageSprintModal;
