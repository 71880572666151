import { emptySplitApi as api } from "../../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSettingsSprintGet: build.query<GetSettingsSprintGetApiResponse, GetSettingsSprintGetApiArg>({
            query: (queryArg) => ({
                url: `/settings/Sprint/Get`,
                params: { sprintKey: queryArg.sprintKey },
            }),
        }),
        getSettingsSprintList: build.query<GetSettingsSprintListApiResponse, GetSettingsSprintListApiArg>({
            query: () => ({ url: `/settings/Sprint/List` }),
        }),
        postSettingsSprintAdd: build.mutation<PostSettingsSprintAddApiResponse, PostSettingsSprintAddApiArg>({
            query: (queryArg) => ({
                url: `/settings/Sprint/Add`,
                method: "POST",
                body: queryArg.addSettingsSprintDto,
            }),
        }),
        putSettingsSprintUpdate: build.mutation<PutSettingsSprintUpdateApiResponse, PutSettingsSprintUpdateApiArg>({
            query: (queryArg) => ({
                url: `/settings/Sprint/Update`,
                method: "PUT",
                body: queryArg.existingSettingsSprintDto,
            }),
        }),
        deleteSettingsSprintDelete: build.mutation<
            DeleteSettingsSprintDeleteApiResponse,
            DeleteSettingsSprintDeleteApiArg
        >({
            query: (queryArg) => ({
                url: `/settings/Sprint/Delete`,
                method: "DELETE",
                params: { sprintKey: queryArg.sprintKey },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetSettingsSprintGetApiResponse = /** status 200 Success */ ExistingSettingsSprintDto;
export type GetSettingsSprintGetApiArg = {
    sprintKey?: string;
};
export type GetSettingsSprintListApiResponse = /** status 200 Success */ ExistingSettingsSprintDto[];
export type GetSettingsSprintListApiArg = void;
export type PostSettingsSprintAddApiResponse = /** status 200 Success */ string;
export type PostSettingsSprintAddApiArg = {
    addSettingsSprintDto: AddSettingsSprintDto;
};
export type PutSettingsSprintUpdateApiResponse = /** status 200 Success */ undefined;
export type PutSettingsSprintUpdateApiArg = {
    existingSettingsSprintDto: ExistingSettingsSprintDto;
};
export type DeleteSettingsSprintDeleteApiResponse = /** status 200 Success */ undefined;
export type DeleteSettingsSprintDeleteApiArg = {
    sprintKey?: string;
};
export type SprintStatus = 0 | 1 | 2;
export type MethodologyType = 0 | 1 | 2;
export type TenantType = 0;
export type Tenant = {
    tenantKey?: string;
    uriName?: string | null;
    name?: string | null;
    tenantType?: TenantType;
    users?: User[] | null;
};
export type AccessType = 0 | 1 | 2 | 3 | 4 | 5;
export type Role = {
    roleKey?: string;
    name?: string | null;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string | null;
    isDeleted?: boolean;
    accessType?: AccessType;
    users?: User[] | null;
};
export type Team = {
    teamKey?: string;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string | null;
};
export type User = {
    userKey?: string;
    lastName?: string | null;
    firstName?: string | null;
    email?: string | null;
    tenant?: Tenant[] | null;
    roles?: Role[] | null;
    teams?: Team[] | null;
};
export type TeamDto = {
    teamKey?: string;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string;
};
export type ReleaseStatus = 0 | 1 | 2;
export type ExistingSettingsReleaseDto = {
    releaseKey?: string;
    name?: string | null;
    status?: ReleaseStatus;
    notes?: string | null;
    order?: number;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string;
    isDeleted?: boolean;
};
export type ExistingSettingsSprintDto = {
    sprintKey?: string;
    name?: string | null;
    teamKey?: string;
    status?: SprintStatus;
    plannedStartDate?: string;
    actualStartDate?: string | null;
    plannedEndDate?: string | null;
    actualEndDate?: string | null;
    team?: TeamDto;
    releaseKey?: string;
    release?: ExistingSettingsReleaseDto;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type AddSettingsSprintDto = {
    sprintKey?: string | null;
    name?: string | null;
    teamKey?: string;
    status?: SprintStatus;
    plannedStartDate?: string;
    actualStartDate?: string | null;
    plannedEndDate?: string | null;
    actualEndDate?: string | null;
    releaseKey?: string;
    team?: TeamDto;
    release?: ExistingSettingsReleaseDto;
};
export const {
    useGetSettingsSprintGetQuery,
    useGetSettingsSprintListQuery,
    usePostSettingsSprintAddMutation,
    usePutSettingsSprintUpdateMutation,
    useDeleteSettingsSprintDeleteMutation,
} = injectedRtkApi;
