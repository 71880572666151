import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Breakpoint } from "antd";

const useActiveBreakpoint = (): Breakpoint | undefined => {
    const breakpoints = useBreakpoint(true);
    const breakpointEntries = Object.entries(breakpoints);
    const activeBreakpoints = breakpointEntries.filter((breakpoint) => !!breakpoint[1]);
    return activeBreakpoints[activeBreakpoints.length - 1]?.[0] as Breakpoint;
};

export default useActiveBreakpoint;
