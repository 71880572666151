import { ConfigProvider, Dropdown, MenuProps, Space, theme as Theme } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { selectTheme, UserThemePreference } from "../../../common/reducers/UserPreferenceSlice";

const items: MenuProps["items"] = [
    {
        key: UserThemePreference.Light,
        label: "Light",
    },
    {
        key: UserThemePreference.Dark,
        label: "Dark",
    },
];

const ColorThemeSelect = () => {
    const { theme } = React.useContext(ConfigProvider.ConfigContext);
    const dispatch = useDispatch();

    const selectColorTheme = (e: any) => {
        dispatch(selectTheme(e.key));
    };

    return (
        <Dropdown
            menu={{
                items,
                onClick: selectColorTheme,
                selectable: true,
                defaultSelectedKeys: theme?.algorithm === Theme.darkAlgorithm ? ["dark"] : ["light"],
            }}
            trigger={["click"]}
        >
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    {theme?.algorithm === Theme.darkAlgorithm ? "Dark" : "Light"}
                    <DownOutlined />
                </Space>
            </a>
        </Dropdown>
    );
};

export default ColorThemeSelect;
