import { UserThemePreference } from "../../../../common/reducers/UserPreferenceSlice";
import { SprintDto } from "../../../../store/generated/issue";
import { Header } from "antd/es/layout/layout";
import React from "react";
import dayjs from "dayjs";
import { SprintStatus } from "../../types/SprintStatus";

function SprintLabel(props: { activeUserThemePreference: UserThemePreference; sprint?: SprintDto }) {
    function buildSprintNameLabel() {
        return props.sprint?.name
            ? `${props.sprint?.name} ${props.sprint?.status === SprintStatus.Open ? " - Current Sprint" : ""}`
            : undefined;
    }

    function buildSprintRangeLabel() {
        if (!props.sprint) {
            return undefined;
        }

        const sprint = props.sprint;
        const startDateString = `Starts: ${dayjs(sprint.plannedStartDate).format("MM/DD")}`;
        const endDateString = sprint.plannedEndDate ? `Ends: ${dayjs(sprint.plannedEndDate).format("MM/DD")}` : "";
        return `${startDateString} ${endDateString}`;
    }
    return (
        <Header
            style={{
                display: "flex",
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor:
                    props.activeUserThemePreference == UserThemePreference.Dark ? "rgb(0, 0, 0)" : "rgb(255, 255, 255)",
            }}
        >
            <span style={{ fontSize: 18, flex: 1 }}>{buildSprintNameLabel() ?? "Prioritization"}</span>
            <span style={{ float: "right" }}>{buildSprintRangeLabel()}</span>
        </Header>
    );
}
export default SprintLabel;
