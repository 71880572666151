import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Form, Select } from "antd";
import { useGetSettingsTenantUserGetQuery, usePutSettingsTenantUserSaveMutation } from "../api/AccessApi";
import { DetailsFormToolbar } from "../../../../common/components/DetailsFormToolbar";
import { SettingsTenantUserAccessDto } from "../../../../store/generated/settings/tenant";
import useFormValidation from "../../../../common/components/useFormValidation";

const AccessDetailsPage = () => {
    const [form] = Form.useForm();
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const navigate = useNavigate();
    const userKey = new URLSearchParams(useLocation().search).get("userKey");
    const [userAccessData, setUserAccessData] = useState<SettingsTenantUserAccessDto | undefined>(undefined);
    const { data, isLoading } = useGetSettingsTenantUserGetQuery({ userKey: userKey ?? undefined });
    const [updateUser, saveResult] = usePutSettingsTenantUserSaveMutation();
    useFormValidation({ form, validationResult: saveResult as any });
    useEffect(() => {
        if (data) {
            setUserAccessData(data);
        }
    }, [data]);
    useEffect(() => {
        if (saveResult.isSuccess) {
            navigate(-1);
        }
    }, [saveResult]);
    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
        setUserAccessData({
            ...userAccessData,
            [property]: e.target.value,
        });
    };
    const onSelectValueChange = (key: string, property: string) => {
        // TODO: Track selected values in the entity
    };
    const onCancel = () => {
        navigate(-1);
    };
    const onFinish = (values: any) => {
        updateUser({ updateSettingsTenantUserAccessDto: { ...userAccessData, ...values } });
    };

    return !userKey || userAccessData ? (
        <Form
            {...layout}
            initialValues={userAccessData}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{ maxWidth: 600, paddingTop: 15 }}
        >
            <Form.Item name="tenantKeys" label="Tenants" rules={[{ required: true }]}>
                <Select
                    mode="multiple"
                    options={userAccessData?.availableTenants!!.map((x) => {
                        return { label: x.name, value: x.tenantKey };
                    })}
                    popupMatchSelectWidth={true}
                    defaultOpen={false}
                    onSelect={(e) => onSelectValueChange(e.tenantKey!!, "tenantKeys")}
                />
            </Form.Item>
            <DetailsFormToolbar
                tailLayout={tailLayout}
                onCancel={onCancel}
                onDelete={() => {}}
                onSubmit={() => form.submit()}
            />
        </Form>
    ) : undefined;
};

export default AccessDetailsPage;
