import { enhancedApi as api } from "../../../../store/generated/feature";

const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ["Feature"],
    endpoints: {
        getFeatureList: {
            providesTags: ["Feature"],
            keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
        },
        putFeatureUpdate: {
            invalidatesTags: ["Feature"],
        },
    },
});

export const { useGetFeatureListQuery, usePutFeatureUpdateMutation } = enhancedApi;
