import { Form, Input } from "antd";
import React, { ChangeEvent, useEffect } from "react";
import { IssueDto } from "../../../../store/generated/issue";

type Props = {
    issue: IssueDto;
    onIssueEdit: (e: ChangeEvent, property: keyof IssueDto) => void;
};

export const IssueWizardBasicInfo = (props: Props) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} name="control-hooks" layout={"vertical"}>
            <Form.Item label="Title" rules={[{ required: true }]}>
                <Input
                    type="text"
                    value={props.issue?.title ?? undefined}
                    onChange={(e) => props.onIssueEdit(e, "title")}
                />
            </Form.Item>
        </Form>
    );
};
