import React from "react";
import { Avatar, Select, Space, Tag } from "antd";
import { useGetUserListQuery, UserDto } from "../../../../store/generated/user";

type Props = {
    projectKey: string;
    user?: UserDto;
    onSelect: (user: UserDto) => void;
};

export const IssueUserSelect = (props: Props) => {
    const { data, error, isLoading } = useGetUserListQuery({ projectKey: props.projectKey });

    function onUserSelect(selected: any) {
        props.onSelect({
            tenantUserKey: selected,
            initials: data?.find((x) => x.tenantUserKey === selected)?.initials,
        } as UserDto);
    }

    function renderOption(option: { key?: string; label?: string; data: { initials: string; fullName: string } }) {
        return (
            <Space>
                {option.key ? (
                    <>
                        <span role="img" aria-label={option.data.fullName}>
                            <Avatar style={{ backgroundColor: "primary", verticalAlign: "middle" }} size="small">
                                {option.data.initials}
                            </Avatar>
                        </span>
                        {option.data.fullName}
                    </>
                ) : (
                    option.label
                )}
            </Space>
        );
    }

    function projectUserToOption(user: UserDto): {
        option: { key?: string; data: { initials: string; fullName: string } };
    } {
        return { option: { key: user.tenantUserKey, data: { initials: user.initials!!, fullName: user.initials!! } } };
    }

    return (
        <Select
            defaultValue={
                props.user
                    ? { value: props.user?.tenantUserKey, label: renderOption(projectUserToOption(props.user).option) }
                    : undefined
            }
            popupMatchSelectWidth={false}
            defaultOpen={false}
            optionLabelProp="label"
            onSelect={onUserSelect}
            onClear={() => onUserSelect(undefined)}
            allowClear={true}
            options={data?.map((user) => ({
                value: user.tenantUserKey,
                label: renderOption(projectUserToOption(user).option),
                fullName: user.fullName,
                initials: user.initials,
                avatarUrl: user.avatarUrl,
            }))}
            // @ts-ignore
            optionRender={(option) => renderOption(option)}
            style={{ maxWidth: 140, width: 140, textAlign: "center" }}
        />
    );
};
