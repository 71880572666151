import { Select } from "antd";
import React from "react";
import { ProjectDto } from "../../../../store/generated/issue";
import { useGetSettingsProjectListQuery } from "../../../settings/projects/api/ProjectApi";

export function EditableProjectPill(props: {
    project: ProjectDto | undefined;
    onProjectUpdate: (value: ProjectDto) => void;
}) {
    const { data } = useGetSettingsProjectListQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.map((x) => ({ value: x.projectKey, label: x.name })),
            error,
            isLoading,
        }),
    });

    function onProjectSelect(selected: any) {
        props.onProjectUpdate({
            projectKey: selected,
            name: data?.find((x) => x.value === selected)?.label,
        } as ProjectDto);
    }

    return (
        <Select
            options={data}
            defaultValue={{ value: props.project?.projectKey, label: props.project?.name }}
            popupMatchSelectWidth={false}
            defaultOpen={false}
            onSelect={onProjectSelect}
            style={{ maxWidth: 80, width: 80, textAlign: "center" }}
        />
    );
}
