import React, { useEffect, useRef, useState } from "react";
import StatusType, { mapStatusTypeToFriendlyName } from "../../../../common/types/StatusType";
import BoardColumn, { KANBAN_BOARD_SPACING } from "./KanbanBoardColumn";
import { useGetIssueListQuery } from "../../api/IssueApi";
import { groupBy, orderBy } from "lodash";
import { useSelector } from "react-redux";
import {
    getFilteredIssues,
    getFilteredProject,
    getFilteredStatus,
    getFilteredTeam,
} from "../../selectors/BacklogSelectors";
import FilterDrawer from "../filters/FilterDrawer";
import { useShowViewOptionsDrawerListener } from "../../../../common/events";
import BacklogContextMenu from "../backlog-list/BacklogContextMenu";
import RightClickMenu from "../backlog-list/BacklogContextMenu";
import InvalidFilterState from "../InvalidFilterState";
import { Row } from "antd";

const statusMap = Object.values(StatusType)
    .filter(
        (x) =>
            x !== StatusType.Planning && x !== StatusType.Research && x !== StatusType.NotDone && typeof x === "number"
    )
    .map((x) => ({ label: mapStatusTypeToFriendlyName(x as StatusType), value: x }));

const KanbanBoardPage = () => {
    const rightClickMenu = useRef<typeof RightClickMenu>();
    const filteredTeam = useSelector(getFilteredTeam);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useShowViewOptionsDrawerListener(() => {
        setIsDrawerOpen(true);
    });
    // TODO: Hook for fetching issue data
    // TODO: Filter to active sprint
    const filteredProject = useSelector(getFilteredProject);
    const filteredStatus = useSelector(getFilteredStatus);
    const {
        data,
        error,
        isLoading = true,
    } = useGetIssueListQuery(
        {
            projectKey: filteredProject?.key,
            teamKey: filteredTeam?.key,
            statusType: filteredStatus?.key as unknown as StatusType,
            filterToCurrentSprint: true,
        },
        {
            selectFromResult: ({ data, error, isLoading }) => ({
                data: orderBy(data, "order"),
                error,
                isLoading,
            }),
            pollingInterval: undefined,
            skip: !filteredTeam,
        }
    );

    const filteredIssues = groupBy(
        useSelector((state) => getFilteredIssues(state, data)),
        "status"
    );

    function findClosestValueToTarget(value: number, target: number) {
        if (target % value === 0) {
            return value;
        }

        let i = 0;
        while (i < target) {
            let closest = value + 1;
            if (target % closest === 0) {
                return closest;
            }
            i++;
        }
        return undefined;
    }

    return (
        <Row
            gutter={[KANBAN_BOARD_SPACING, KANBAN_BOARD_SPACING]}
            justify="center"
            style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            wrap={true}
        >
            <FilterDrawer
                open={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                }}
            />
            {!filteredTeam ? (
                <div style={{ marginTop: 15 }}>
                    <InvalidFilterState />
                </div>
            ) : (
                <>
                    {statusMap.map((x, index) => (
                        <BoardColumn
                            key={x.value.toString()}
                            label={x.label}
                            isLast={index === statusMap.length - 1}
                            issues={filteredIssues[x.value] ?? []}
                            rightClickMenu={rightClickMenu?.current}
                            span={24 / findClosestValueToTarget(statusMap.length, 24)!!}
                        />
                    ))}
                    <BacklogContextMenu teamKey={filteredTeam?.key} ref={rightClickMenu} />
                </>
            )}
        </Row>
    );
};
export default KanbanBoardPage;
