import { emptySplitApi as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getReportsReportList: build.query<GetReportsReportListApiResponse, GetReportsReportListApiArg>({
            query: () => ({ url: `/reports/Report/List` }),
        }),
        getReportsReportGet: build.query<GetReportsReportGetApiResponse, GetReportsReportGetApiArg>({
            query: (queryArg) => ({
                url: `/reports/Report/Get`,
                params: { reportType: queryArg.reportType },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetReportsReportListApiResponse = /** status 200 Success */ ReportDefinitionDto[];
export type GetReportsReportListApiArg = void;
export type GetReportsReportGetApiResponse = /** status 200 Success */ ReportDefinitionDto;
export type GetReportsReportGetApiArg = {
    reportType?: ReportType;
};
export type ReportType = 0;
export type AvailableValue = {
    value?: any | null;
    label?: string | null;
};
export type ReportParameterDto = {
    reportParameterKey?: string;
    name?: string | null;
    type?: string | null;
    required?: boolean;
    defaultValue?: any | null;
    availableValues?: AvailableValue[] | null;
};
export type ReportDefinitionDto = {
    reportType?: ReportType;
    name?: string | null;
    parameters?: ReportParameterDto[] | null;
};
export const { useGetReportsReportListQuery, useGetReportsReportGetQuery } = injectedRtkApi;
