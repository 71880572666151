import { Button } from "antd";
import { green } from "@ant-design/colors";
import { CSSProperties } from "react";

type Props = {
    title: string;
    onClick: () => void;
};
const SuccessButton = (props: Props) => {
    function handleClick(e: any) {
        e.stopPropagation();
        props.onClick();
    }

    return (
        <Button style={{ borderColor: green.primary, color: green.primary }} onClick={handleClick}>
            {props.title}
        </Button>
    );
};

export default SuccessButton;
