import { Select } from "antd";
import React from "react";
import { isNumber } from "lodash";
import { mapPriorityTypeToLabel, PriorityType } from "../../types/PriorityType";

const options = Object.values(PriorityType)
    .filter((x) => isNumber(x))
    .map((x) => {
        return { value: x, label: mapPriorityTypeToLabel(x as unknown as PriorityType) };
    });

export function EditablePriorityTypePill(props: {
    priorityType: PriorityType | undefined;
    onPriorityTypeUpdate: (value: PriorityType) => void;
}) {
    return (
        <Select
            options={options}
            defaultValue={props.priorityType}
            popupMatchSelectWidth={false}
            defaultOpen={false}
            onSelect={props.onPriorityTypeUpdate}
            style={{ maxWidth: 140, width: 140, textAlign: "center" }}
        />
    );
}
