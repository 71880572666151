import { Spin } from "antd";
import {
    CloudDownloadOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    PauseCircleOutlined,
} from "@ant-design/icons";
import { presetPrimaryColors } from "@ant-design/colors";
import React from "react";

type Props = {
    status: "Queued" | "Processing" | "Available" | "Error";
};

const ReportStatusIndicator = (props: Props) => {
    function getComponentForStatus() {
        switch (props.status) {
            case "Queued":
                return <PauseCircleOutlined />;
            case "Processing":
                return <LoadingOutlined />;
            case "Error":
                return <ExclamationCircleOutlined style={{ color: presetPrimaryColors.red }} />;
            case "Available":
                return <CloudDownloadOutlined />;
        }
    }
    return getComponentForStatus();
};

export default ReportStatusIndicator;
