import { emptySplitApi as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getIssueGet: build.query<GetIssueGetApiResponse, GetIssueGetApiArg>({
            query: (queryArg) => ({
                url: `/Issue/Get`,
                params: { projectIssueKeyLabel: queryArg.projectIssueKeyLabel },
            }),
        }),
        getIssueList: build.query<GetIssueListApiResponse, GetIssueListApiArg>({
            query: (queryArg) => ({
                url: `/Issue/List`,
                params: {
                    projectKey: queryArg.projectKey,
                    teamKey: queryArg.teamKey,
                    statusType: queryArg.statusType,
                    filterToCurrentSprint: queryArg.filterToCurrentSprint,
                },
            }),
        }),
        putIssueUpdateIssueOrder: build.mutation<PutIssueUpdateIssueOrderApiResponse, PutIssueUpdateIssueOrderApiArg>({
            query: (queryArg) => ({
                url: `/Issue/UpdateIssueOrder`,
                method: "PUT",
                params: { issueKey: queryArg.issueKey, order: queryArg.order },
            }),
        }),
        postIssueSave: build.mutation<PostIssueSaveApiResponse, PostIssueSaveApiArg>({
            query: (queryArg) => ({
                url: `/Issue/Save`,
                method: "POST",
                body: queryArg.saveIssueDto,
            }),
        }),
        getIssueListEpics: build.query<GetIssueListEpicsApiResponse, GetIssueListEpicsApiArg>({
            query: () => ({ url: `/Issue/ListEpics` }),
        }),
        deleteIssueDelete: build.mutation<DeleteIssueDeleteApiResponse, DeleteIssueDeleteApiArg>({
            query: (queryArg) => ({
                url: `/Issue/Delete`,
                method: "DELETE",
                params: { issueKey: queryArg.issueKey },
            }),
        }),
        postIssueClone: build.mutation<PostIssueCloneApiResponse, PostIssueCloneApiArg>({
            query: (queryArg) => ({
                url: `/Issue/Clone`,
                method: "POST",
                params: {
                    issueKey: queryArg.issueKey,
                    keepSprint: queryArg.keepSprint,
                },
            }),
        }),
        putIssueSendToCurrentSprint: build.mutation<
            PutIssueSendToCurrentSprintApiResponse,
            PutIssueSendToCurrentSprintApiArg
        >({
            query: (queryArg) => ({
                url: `/Issue/SendToCurrentSprint`,
                method: "PUT",
                params: { issueKey: queryArg.issueKey, teamKey: queryArg.teamKey },
            }),
        }),
        putIssueSendToBacklog: build.mutation<PutIssueSendToBacklogApiResponse, PutIssueSendToBacklogApiArg>({
            query: (queryArg) => ({
                url: `/Issue/SendToBacklog`,
                method: "PUT",
                params: { issueKey: queryArg.issueKey },
            }),
        }),
        putIssueAssignToCurrentUser: build.mutation<
            PutIssueAssignToCurrentUserApiResponse,
            PutIssueAssignToCurrentUserApiArg
        >({
            query: (queryArg) => ({
                url: `/Issue/AssignToCurrentUser`,
                method: "PUT",
                params: { issueKey: queryArg.issueKey },
            }),
        }),
        postIssueImportExecute: build.mutation<PostIssueImportExecuteApiResponse, PostIssueImportExecuteApiArg>({
            query: (queryArg) => ({
                url: `/IssueImport/Execute`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetIssueGetApiResponse = /** status 200 Success */ IssueDetailDto;
export type GetIssueGetApiArg = {
    projectIssueKeyLabel?: string;
};
export type GetIssueListApiResponse = /** status 200 Success */ IssueDto[];
export type GetIssueListApiArg = {
    projectKey?: string;
    teamKey?: string;
    statusType?: StatusType;
    filterToCurrentSprint?: boolean;
};
export type PutIssueUpdateIssueOrderApiResponse = /** status 200 Success */ undefined;
export type PutIssueUpdateIssueOrderApiArg = {
    issueKey?: string;
    order?: number;
};
export type PostIssueSaveApiResponse = /** status 200 Success */ undefined;
export type PostIssueSaveApiArg = {
    saveIssueDto: SaveIssueDto;
};
export type GetIssueListEpicsApiResponse = /** status 200 Success */ EpicDto[];
export type GetIssueListEpicsApiArg = void;
export type DeleteIssueDeleteApiResponse = /** status 200 Success */ undefined;
export type DeleteIssueDeleteApiArg = {
    issueKey?: string;
};
export type PostIssueCloneApiResponse = /** status 200 Success */ IssueDto;
export type PostIssueCloneApiArg = {
    issueKey?: string;
    keepSprint?: boolean;
};
export type PutIssueSendToCurrentSprintApiResponse = /** status 200 Success */ undefined;
export type PutIssueSendToCurrentSprintApiArg = {
    issueKey?: string;
    teamKey?: string;
};
export type PutIssueSendToBacklogApiResponse = /** status 200 Success */ undefined;
export type PutIssueSendToBacklogApiArg = {
    issueKey?: string;
};
export type PutIssueAssignToCurrentUserApiResponse = /** status 200 Success */ undefined;
export type PutIssueAssignToCurrentUserApiArg = {
    issueKey?: string;
};
export type PostIssueImportExecuteApiResponse = /** status 200 Success */ undefined;
export type PostIssueImportExecuteApiArg = {
    body: {
        file?: Blob;
    };
};
export type IssueType = 1 | 2 | 3 | 4 | 5;
export type StatusType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type PriorityType = 0 | 1 | 2 | 3;
export type MethodologyType = 0 | 1 | 2;
export type TenantType = 0;
export type Tenant = {
    tenantKey?: string;
    uriName?: string | null;
    name?: string | null;
    tenantType?: TenantType;
    users?: User[] | null;
};
export type AccessType = 0 | 1 | 2 | 3 | 4 | 5;
export type Role = {
    roleKey?: string;
    name?: string | null;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string | null;
    isDeleted?: boolean;
    accessType?: AccessType;
    users?: User[] | null;
};
export type Team = {
    teamKey?: string;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string | null;
};
export type User = {
    userKey?: string;
    lastName?: string | null;
    firstName?: string | null;
    email?: string | null;
    tenant?: Tenant[] | null;
    roles?: Role[] | null;
    teams?: Team[] | null;
};
export type TeamDto = {
    teamKey?: string;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string;
};
export type ProjectDto = {
    projectKey?: string;
    abbreviation?: string | null;
    name?: string | null;
    description?: string | null;
    team?: TeamDto;
};
export type EpicDto = {
    issueKey?: string;
    projectIssueKey?: number;
    title?: string | null;
    description?: string | null;
    priorityType?: PriorityType;
    projectKey?: string;
    projectAbbreviation?: string | null;
    order?: number;
    team?: TeamDto;
    isDefault?: boolean;
};
export type UserDto = {
    userKey?: string;
    tenantUserKey?: string;
    fullName?: string | null;
    initials?: string | null;
    avatarUrl?: string | null;
};
export type ReleaseDto = {
    releaseKey?: string;
    name?: string | null;
    notes?: string | null;
};
export type SprintStatus = 0 | 1 | 2;
export type SprintDto = {
    sprintKey?: string;
    teamKey?: string;
    name?: string | null;
    plannedStartDate?: string;
    actualStartDate?: string;
    plannedEndDate?: string | null;
    actualEndDate?: string | null;
    release?: ReleaseDto;
    status?: SprintStatus;
};
export type IssueDto = {
    issueKey?: string;
    projectIssueKeyedLabel?: string | null;
    title?: string | null;
    description?: string | null;
    issueType?: IssueType;
    status?: StatusType;
    priorityType?: PriorityType;
    order?: number;
    project?: ProjectDto;
    epic?: EpicDto;
    createdBy?: UserDto;
    assignee?: UserDto;
    storyPoints?: number | null;
    sprintDto?: SprintDto;
    teamDto?: TeamDto;
};
export type IssueDetailDto = {
    issueKey?: string;
    projectIssueKeyedLabel?: string | null;
    title?: string | null;
    description?: string | null;
    issueType?: IssueType;
    status?: StatusType;
    priorityType?: PriorityType;
    order?: number;
    project?: ProjectDto;
    epic?: EpicDto;
    assignee?: UserDto;
    storyPoints?: number | null;
    sprintDto?: SprintDto;
    epicIssues?: IssueDto[] | null;
    developer?: UserDto;
    peerReviewer?: UserDto;
    qaReviewer?: UserDto;
    createdBy?: UserDto;
    teamDto?: TeamDto;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type SaveIssueDto = {
    issueKey?: string;
    title?: string | null;
    description?: string | null;
    issueType?: IssueType;
    status?: StatusType;
    priorityType?: PriorityType;
    tenantKey?: string | null;
    assigneeKey?: string | null;
    peerReviewerKey?: string | null;
    developerKey?: string | null;
    qaReviewerKey?: string | null;
    teamKey?: string | null;
    projectKey?: string | null;
    epicKey?: string | null;
    storyPoints?: number | null;
    sprintKey?: string | null;
};
export const {
    useGetIssueGetQuery,
    useGetIssueListQuery,
    usePutIssueUpdateIssueOrderMutation,
    usePostIssueSaveMutation,
    useGetIssueListEpicsQuery,
    useDeleteIssueDeleteMutation,
    usePostIssueCloneMutation,
    usePutIssueSendToCurrentSprintMutation,
    usePutIssueSendToBacklogMutation,
    usePutIssueAssignToCurrentUserMutation,
    usePostIssueImportExecuteMutation,
} = injectedRtkApi;
