import { Button, List } from "antd";
import ColorThemeSelect from "./ColorThemeSelect";
import React from "react";
import CompanySelect from "./CompanySelect";
import RoleSelect from "./RoleSelect";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { signOut } from "aws-amplify/auth";
import { emitShowOnboardingTasksModal } from "../../../common/events";
import { useGetFeatureListQuery } from "../../../store/generated/feature";
import Features from "../../../common/enum/Features";

const UserPreferencesPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: features } = useGetFeatureListQuery();
    return (
        <List bordered style={{ marginTop: 20 }}>
            {features?.find((x) => x.featureKey === Features.Onboarding)?.isEnabled && (
                <List.Item>
                    <List.Item.Meta title="Onboarding Tasks" />
                    <div>
                        <Button
                            type="link"
                            onClick={() => {
                                emitShowOnboardingTasksModal();
                            }}
                        >
                            Show Onboarding Tasks
                        </Button>
                    </div>
                </List.Item>
            )}
            <List.Item>
                <List.Item.Meta title="Company" />
                <div>
                    <CompanySelect />
                </div>
            </List.Item>
            <List.Item>
                <List.Item.Meta title="Role" />
                <div>
                    <RoleSelect />
                </div>
            </List.Item>
            <List.Item>
                <List.Item.Meta title="Color Theme" />
                <div>
                    <ColorThemeSelect />
                </div>
            </List.Item>
            <List.Item>
                <List.Item.Meta />
                <div>
                    <Button
                        type="default"
                        onClick={() => {
                            signOut().then(() => {
                                dispatch({ type: "USER_LOGOUT" });
                                navigate("/login");
                            });
                        }}
                    >
                        Logout
                    </Button>
                </div>
            </List.Item>
        </List>
    );
};
export default UserPreferencesPage;
