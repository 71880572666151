import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import Issue from "../types/Issue";
import { act } from "react-dom/test-utils";

export const backlogAdapter = createEntityAdapter<Issue>({
    sortComparer: (a, b) => a.key.localeCompare(b.key),
});

const backlogSlice = createSlice({
    name: "backlog",
    initialState: {
        ...backlogAdapter.getInitialState(),
        filterState: {
            project: undefined as
                | {
                      key: string;
                      label: string;
                  }
                | undefined,
            status: undefined as
                | {
                      key: string;
                      label: string;
                  }
                | undefined,
            team: undefined as
                | {
                      key: string;
                      label: string;
                  }
                | undefined,
            epic: undefined as
                | {
                      key: string;
                      label: string;
                  }
                | undefined,
            users: [] as {
                value: string;
                label: string;
            }[],
        },
        defaultState: {
            projectKey: undefined,
            teamKey: undefined,
            issueTypeKey: undefined,
            epicKey: undefined,
        },
    },
    reducers: {
        issueAdded: backlogAdapter.addOne,
        setIssues(state, action) {
            backlogAdapter.setAll(state, action.payload);
        },
        clearFilteredProject(state) {
            state.filterState.project = undefined;
            state.filterState.status = undefined;
            state.filterState.team = undefined;
            state.filterState.epic = undefined;
            state.filterState.users = [];
        },
        setDefaultProject(state, action) {
            state.defaultState.projectKey = action.payload;
        },
        setDefaultTeam(state, action) {
            state.defaultState.teamKey = action.payload;
        },
        setDefaultEpic(state, action) {
            state.defaultState.epicKey = action.payload;
        },
        clearFilteredStatus(state) {
            state.filterState.status = undefined;
        },
        setFilteredStatus(state, action) {
            state.filterState.status = action.payload;
        },
        setDefaultIssueType(state, action) {
            state.defaultState.issueTypeKey = action.payload;
        },
        setFilteredProject(state, action) {
            state.filterState.project = action.payload;
            // TODO: This should impact the backload
        },
        clearFilteredTeam(state) {
            state.filterState.team = undefined;
        },
        setFilteredTeam(state, action) {
            state.filterState.team = action.payload;
        },
        setFilteredEpic(state, action) {
            state.filterState.epic = action.payload;
        },
        clearFilteredEpic(state) {
            state.filterState.epic = undefined;
        },
        resetBacklogState(state) {
            state.filterState = { project: undefined, status: undefined, team: undefined, epic: undefined, users: [] };
        },
        addFilteredUsers(state, action) {
            state.filterState.users = [...state.filterState.users, action.payload];
        },
        clearFilteredUser(state, action) {
            state.filterState.users = state.filterState.users.filter((x) => x.value !== action.payload);
        },
    },
});

export const {
    clearFilteredProject,
    setFilteredProject,
    clearFilteredStatus,
    setFilteredStatus,
    setIssues,
    setFilteredEpic,
    resetBacklogState,
    setDefaultProject,
    setDefaultTeam,
    setDefaultIssueType,
    setDefaultEpic,
    addFilteredUsers,
    clearFilteredUser,
    clearFilteredEpic,
    setFilteredTeam,
    clearFilteredTeam,
} = backlogSlice.actions;
export default backlogSlice.reducer;
