import "./styles.css";
import { StarterKit } from "@tiptap/starter-kit";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import React from "react";
import ListItem from "antd/es/upload/UploadList/ListItem";
import { TextStyle } from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import {
    BlockOutlined,
    BoldOutlined,
    BorderHorizontalOutlined,
    CodeOutlined,
    ItalicOutlined,
    OrderedListOutlined,
    RedoOutlined,
    StrikethroughOutlined,
    UndoOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import { ColorPicker } from "antd";

const content = "<p>Hello World!</p>";

export const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] } as any),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
    }),
];

type Props = {
    value: string;
    onChange: (value: string) => void;
};

function RichTextEditor(props: Props) {
    const MenuBar = () => {
        const { editor } = useCurrentEditor();

        if (!editor) {
            return null;
        }

        return (
            <div className="tiptap-menubar">
                <ColorPicker
                    style={{ verticalAlign: "middle" }}
                    onChange={(value, hex) => editor.chain().focus().setColor(hex).run()}
                    defaultValue={"#000"}
                    value={editor.getAttributes("textStyle").color}
                />
                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    disabled={!editor.can().chain().focus().toggleBold().run()}
                    className={editor.isActive("bold") ? "is-active" : ""}
                >
                    <BoldOutlined />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    disabled={!editor.can().chain().focus().toggleItalic().run()}
                    className={editor.isActive("italic") ? "is-active" : ""}
                >
                    <ItalicOutlined />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    disabled={!editor.can().chain().focus().toggleStrike().run()}
                    className={editor.isActive("strike") ? "is-active" : ""}
                >
                    <StrikethroughOutlined />
                </button>
                {/*
                <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                    clear marks
                </button>
                <button onClick={() => editor.chain().focus().clearNodes().run()}>
                    clear nodes
                </button>
                */}
                <button
                    onClick={() => editor.chain().focus().setParagraph().run()}
                    className={editor.isActive("paragraph") ? "is-active" : ""}
                >
                    p
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
                >
                    h1
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
                >
                    h2
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                    className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
                >
                    h3
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                    className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}
                >
                    h4
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                    className={editor.isActive("heading", { level: 5 }) ? "is-active" : ""}
                >
                    h5
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive("bulletList") ? "is-active" : ""}
                >
                    <UnorderedListOutlined />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive("orderedList") ? "is-active" : ""}
                >
                    <OrderedListOutlined />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleCode().run()}
                    disabled={!editor.can().chain().focus().toggleCode().run()}
                    className={editor.isActive("code") ? "is-active" : ""}
                >
                    <CodeOutlined />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                    className={editor.isActive("codeBlock") ? "is-active" : ""}
                >
                    {"< />"}
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleBlockquote().run()}
                    className={editor.isActive("blockquote") ? "is-active" : ""}
                >
                    <BlockOutlined />
                </button>
                <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                    <BorderHorizontalOutlined />
                </button>
                <button onClick={() => editor.chain().focus().setHardBreak().run()}>{"<br />"}</button>
                <button
                    onClick={() => editor.chain().focus().undo().run()}
                    disabled={!editor.can().chain().focus().undo().run()}
                >
                    <UndoOutlined />
                </button>
                <button
                    onClick={() => editor.chain().focus().redo().run()}
                    disabled={!editor.can().chain().focus().redo().run()}
                >
                    <RedoOutlined />
                </button>
            </div>
        );
    };

    return (
        <EditorProvider
            slotBefore={<MenuBar />}
            extensions={extensions}
            content={props.value}
            children={null}
            onUpdate={(update) => props.onChange(update.editor.getHTML())}
        />
    );
}

export default RichTextEditor;
