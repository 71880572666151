import { emptySplitApi as api } from "../../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSettingsProjectGet: build.query<GetSettingsProjectGetApiResponse, GetSettingsProjectGetApiArg>({
            query: (queryArg) => ({
                url: `/settings/Project/Get`,
                params: { projectKey: queryArg.projectKey },
            }),
        }),
        getSettingsProjectList: build.query<GetSettingsProjectListApiResponse, GetSettingsProjectListApiArg>({
            query: () => ({ url: `/settings/Project/List` }),
        }),
        putSettingsProjectUpdate: build.mutation<PutSettingsProjectUpdateApiResponse, PutSettingsProjectUpdateApiArg>({
            query: (queryArg) => ({
                url: `/settings/Project/Update`,
                method: "PUT",
                body: queryArg.existingSettingsProjectDto,
            }),
        }),
        postSettingsProjectAdd: build.mutation<PostSettingsProjectAddApiResponse, PostSettingsProjectAddApiArg>({
            query: (queryArg) => ({
                url: `/settings/Project/Add`,
                method: "POST",
                body: queryArg.addSettingsProjectDto,
            }),
        }),
        deleteSettingsProjectDelete: build.mutation<
            DeleteSettingsProjectDeleteApiResponse,
            DeleteSettingsProjectDeleteApiArg
        >({
            query: (queryArg) => ({
                url: `/settings/Project/Delete`,
                method: "DELETE",
                params: { projectKey: queryArg.projectKey },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetSettingsProjectGetApiResponse = /** status 200 Success */ ExistingSettingsProjectDto;
export type GetSettingsProjectGetApiArg = {
    projectKey?: string;
};
export type GetSettingsProjectListApiResponse = /** status 200 Success */ ExistingSettingsProjectDto[];
export type GetSettingsProjectListApiArg = void;
export type PutSettingsProjectUpdateApiResponse = /** status 200 Success */ undefined;
export type PutSettingsProjectUpdateApiArg = {
    existingSettingsProjectDto: ExistingSettingsProjectDto;
};
export type PostSettingsProjectAddApiResponse = /** status 200 Success */ string;
export type PostSettingsProjectAddApiArg = {
    addSettingsProjectDto: AddSettingsProjectDto;
};
export type DeleteSettingsProjectDeleteApiResponse = /** status 200 Success */ undefined;
export type DeleteSettingsProjectDeleteApiArg = {
    projectKey?: string;
};
export type ExistingSettingsProjectDto = {
    projectKey?: string;
    abbreviation?: string | null;
    name?: string | null;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type AddSettingsProjectDto = {
    projectKey?: string | null;
    abbreviation?: string | null;
    name?: string | null;
};
export const {
    useGetSettingsProjectGetQuery,
    useGetSettingsProjectListQuery,
    usePutSettingsProjectUpdateMutation,
    usePostSettingsProjectAddMutation,
    useDeleteSettingsProjectDeleteMutation,
} = injectedRtkApi;
