import * as React from "react";
import {
    emitShowCreateIssueModal,
    emitShowCreateReportModal,
    emitShowImportIssueModal,
    emitShowViewOptionsDrawer,
} from "../../events";
import { FilterFilled, FilterOutlined, SettingOutlined, ThunderboltOutlined, UserOutlined } from "@ant-design/icons";
import { Location } from "react-router";
import Features from "../../enum/Features";
import { FeatureDto } from "../../../store/generated/feature";
import { ItemType } from "antd/es/menu/interface";
import { Badge } from "antd";

class NavActionMenuBuilder {
    private items = [
        {
            key: "Action",
            label: <ThunderboltOutlined style={{ fontSize: 18 }} />,
            children: [] as ItemType[],
        },
    ];
    addIssueMenu(canSaveIssue: boolean, features: FeatureDto[]): NavActionMenuBuilder {
        if (canSaveIssue) {
            this.items[0].children = this.items[0].children.concat([
                {
                    key: "issue-group",
                    label: "Issues",
                    type: "group",
                    children: [
                        {
                            key: "nonav-createIssue",
                            label: "Create Issue",
                            onClick: () => emitShowCreateIssueModal({ projectIssueKeyedLabel: undefined }),
                        },
                        features.some((x) => x.featureKey === Features.Imports && x.isEnabled)
                            ? {
                                  key: "nonav-importIssues",
                                  label: "Import Issues",
                                  onClick: () => emitShowImportIssueModal(),
                              }
                            : null,
                    ],
                },
            ]);
        }
        return this;
    }
    addReportsMenu(): NavActionMenuBuilder {
        this.items[0].children = this.items[0].children.concat([
            {
                key: "report-group",
                label: "Reports",
                type: "group",
                children: [
                    {
                        key: "nonav-runReport",
                        label: "Run Report",
                        onClick: () => emitShowCreateReportModal(),
                    },
                ],
            },
        ]);
        return this;
    }
    addSettingsMenu(canAccessSettings: boolean): NavActionMenuBuilder {
        if (canAccessSettings) {
            this.items = this.items.concat([
                {
                    key: "/settings",
                    label: <SettingOutlined style={{ fontSize: 18 }} />,
                } as any,
            ]);
        }
        return this;
    }
    addUserPreferencesMenu(userBadgeCount: number): NavActionMenuBuilder {
        this.items = this.items.concat([
            {
                key: "/userpreferences",
                label: (
                    <Badge count={userBadgeCount} showZero={false}>
                        <UserOutlined style={{ fontSize: 18 }} />
                    </Badge>
                ),
            } as any,
        ]);
        return this;
    }
    addFilterMenu(location: Location, hasAnyAppliedViewOptions: boolean): NavActionMenuBuilder {
        if (location.pathname.includes("backlog-management")) {
            const filterNavItem = {
                key: "nonav-filter",
                label: hasAnyAppliedViewOptions ? (
                    <FilterFilled onClick={() => emitShowViewOptionsDrawer()} style={{ fontSize: 18 }} />
                ) : (
                    <FilterOutlined onClick={() => emitShowViewOptionsDrawer()} style={{ fontSize: 18 }} />
                ),
                children: [],
            };
            this.items.splice(0, 0, filterNavItem);
        }
        return this;
    }

    build() {
        return this.items;
    }
}

export default NavActionMenuBuilder;
