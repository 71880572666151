import { Select } from "antd";
import React from "react";
import { isNumber } from "lodash";
import IssueType from "../../types/IssueType";

const mapLabelValueToIssueType = (issueType: IssueType): string => {
    switch (issueType) {
        case IssueType.Initiative:
            return "Initiative";
        case IssueType.Epic:
            return "Epic";
        case IssueType.Story:
            return "Story";
        case IssueType.Task:
            return "Task";
        case IssueType.Bug:
            return "Bug";
    }
    throw new Error("Unsupported type");
};

const options = Object.values(IssueType)
    .filter((x) => isNumber(x))
    .map((x) => {
        return { value: x, label: mapLabelValueToIssueType(x as unknown as IssueType) };
    });

export function EditableIssueTypePill(props: {
    issueType: IssueType | undefined;
    onIssueTypeUpdate: (value: IssueType) => void;
}) {
    return (
        <Select
            options={options}
            defaultValue={props.issueType}
            popupMatchSelectWidth={false}
            defaultOpen={false}
            onSelect={props.onIssueTypeUpdate}
            style={{ maxWidth: 140, width: 140, textAlign: "center" }}
        />
    );
}
