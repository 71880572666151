import { emptySplitApi as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSprintsSprintGet: build.query<GetSprintsSprintGetApiResponse, GetSprintsSprintGetApiArg>({
            query: (queryArg) => ({
                url: `/sprints/Sprint/Get`,
                params: { sprintKey: queryArg.sprintKey },
            }),
        }),
        postSprintsSprintOpenSprint: build.mutation<
            PostSprintsSprintOpenSprintApiResponse,
            PostSprintsSprintOpenSprintApiArg
        >({
            query: (queryArg) => ({
                url: `/sprints/Sprint/OpenSprint`,
                method: "POST",
                body: queryArg.openSprintDto,
            }),
        }),
        postSprintsSprintCloseSprint: build.mutation<
            PostSprintsSprintCloseSprintApiResponse,
            PostSprintsSprintCloseSprintApiArg
        >({
            query: (queryArg) => ({
                url: `/sprints/Sprint/CloseSprint`,
                method: "POST",
                body: queryArg.closeSprintDto,
            }),
        }),
        getSprintList: build.query<GetSprintListApiResponse, GetSprintListApiArg>({
            query: (queryArg) => ({
                url: `/Sprint/List`,
                params: { teamKey: queryArg.teamKey },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetSprintsSprintGetApiResponse = /** status 200 Success */ SprintProgressSummary;
export type GetSprintsSprintGetApiArg = {
    sprintKey?: string;
};
export type PostSprintsSprintOpenSprintApiResponse = unknown;
export type PostSprintsSprintOpenSprintApiArg = {
    openSprintDto: OpenSprintDto;
};
export type PostSprintsSprintCloseSprintApiResponse = unknown;
export type PostSprintsSprintCloseSprintApiArg = {
    closeSprintDto: CloseSprintDto;
};
export type GetSprintListApiResponse = /** status 200 Success */ BacklogSprintDto[];
export type GetSprintListApiArg = {
    teamKey?: string;
};
export type SprintStatus = 0 | 1 | 2;
export type IssueType = 1 | 2 | 3 | 4 | 5;
export type StatusType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type PriorityType = 0 | 1 | 2 | 3;
export type MethodologyType = 0 | 1 | 2;
export type TenantType = 0;
export type Tenant = {
    tenantKey?: string;
    uriName?: string | null;
    name?: string | null;
    tenantType?: TenantType;
    users?: User[] | null;
};
export type AccessType = 0 | 1 | 2 | 3 | 4 | 5;
export type Role = {
    roleKey?: string;
    name?: string | null;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string | null;
    isDeleted?: boolean;
    accessType?: AccessType;
    users?: User[] | null;
};
export type Team = {
    teamKey?: string;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string | null;
};
export type User = {
    userKey?: string;
    lastName?: string | null;
    firstName?: string | null;
    email?: string | null;
    tenant?: Tenant[] | null;
    roles?: Role[] | null;
    teams?: Team[] | null;
};
export type TeamDto = {
    teamKey?: string;
    name?: string | null;
    methodology?: MethodologyType;
    members?: User[] | null;
    tenantKey?: string;
    isDeleted?: boolean;
    createDateTime?: string;
    deleteDateTime?: string;
};
export type ProjectDto = {
    projectKey?: string;
    abbreviation?: string | null;
    name?: string | null;
    description?: string | null;
    team?: TeamDto;
};
export type EpicDto = {
    issueKey?: string;
    projectIssueKey?: number;
    title?: string | null;
    description?: string | null;
    priorityType?: PriorityType;
    projectKey?: string;
    projectAbbreviation?: string | null;
    order?: number;
    team?: TeamDto;
    isDefault?: boolean;
};
export type UserDto = {
    userKey?: string;
    tenantUserKey?: string;
    fullName?: string | null;
    initials?: string | null;
    avatarUrl?: string | null;
};
export type ReleaseDto = {
    releaseKey?: string;
    name?: string | null;
    notes?: string | null;
};
export type SprintDto = {
    sprintKey?: string;
    teamKey?: string;
    name?: string | null;
    plannedStartDate?: string;
    actualStartDate?: string;
    plannedEndDate?: string | null;
    actualEndDate?: string | null;
    release?: ReleaseDto;
    status?: SprintStatus;
};
export type IssueDto = {
    issueKey?: string;
    projectIssueKeyedLabel?: string | null;
    title?: string | null;
    description?: string | null;
    issueType?: IssueType;
    status?: StatusType;
    priorityType?: PriorityType;
    order?: number;
    project?: ProjectDto;
    epic?: EpicDto;
    createdBy?: UserDto;
    assignee?: UserDto;
    storyPoints?: number | null;
    sprintDto?: SprintDto;
    teamDto?: TeamDto;
};
export type SprintProgressSummary = {
    nextStatus?: SprintStatus;
    inProgressIssues?: IssueDto[] | null;
    hasUpcomingSprint?: boolean | null;
    unpointedIssues?: number | null;
    improperlyStatusedIssues?: number | null;
};
export type OpenSprintDto = {
    sprintKey?: string;
};
export type CloseSprintActionType = 0 | 1 | 2;
export type ExistingUserBacklogHandlingType = 0 | 1;
export type CloseSprintDto = {
    sprintKey?: string;
    includedActions?: CloseSprintActionType[] | null;
    existingUserBacklogHandling?: ExistingUserBacklogHandlingType;
    releaseNoteRecipients?: string[] | null;
};
export type BacklogSprintDto = {
    sprintKey?: string;
    name?: string | null;
    plannedStartDate?: string;
    status?: SprintStatus;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export const {
    useGetSprintsSprintGetQuery,
    usePostSprintsSprintOpenSprintMutation,
    usePostSprintsSprintCloseSprintMutation,
    useGetSprintListQuery,
} = injectedRtkApi;
