import React from "react";
import { Alert, Flex } from "antd";

const InvalidFilterState = () => {
    return (
        <Flex flex={1} justify="center">
            <Alert
                message="Invalid Filter State"
                description="You must select a team to view a backlog"
                type="warning"
            />
        </Flex>
    );
};

export default InvalidFilterState;
