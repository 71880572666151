import React, { useEffect, useState } from "react";
import { Button, Layout, Modal, notification, Spin, Steps } from "antd";
import Sider from "antd/es/layout/Sider";
import { UserThemePreference } from "../../../../common/reducers/UserPreferenceSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedTheme } from "../../../../common/UserPreferenceSelectors";
import { IssueWizardSelectIssueType } from "./IssueWizardSelectIssueType";
import { EpicDto, IssueDto, ProjectDto, TeamDto } from "../../../../store/generated/issue";
import IssueType from "../../types/IssueType";
import { usePostIssueSaveMutation } from "../../api/IssueApi";
import { IssueWizardBasicInfo } from "./IssueWizardBasicInfo";
import { IssueWizardProjectSelect } from "./IssueWizardProjectSelect";
import { setDefaultEpic, setDefaultIssueType, setDefaultProject, setDefaultTeam } from "../../reducers/BacklogSlice";
import {
    getDefaultEpicKey,
    getDefaultIssueTypeKey,
    getDefaultProjectKey,
    getDefaultTeamKey,
} from "../../selectors/BacklogSelectors";
import { IssueWizardEpicSelect } from "./IssueWizardEpicSelect";
import { IssueWizardTeamSelect } from "./IssueWizardTeamSelect";

type Props = {
    isOpen: boolean;
    toggle: () => void;
};

enum CreateIssueWizardStep {
    ProjectSelect,
    TeamSelect,
    SelectIssueType,
    SelectEpic,
    AddBasicInformation,
}

export const IssueWizardModal = (props: Props) => {
    const defaultProjectKey = useSelector(getDefaultProjectKey);
    const defaultTeamKey = useSelector(getDefaultTeamKey);
    const defaultIssueTypeKey = useSelector(getDefaultIssueTypeKey);
    const defaultEpicKey = useSelector(getDefaultEpicKey);
    const activeUserThemePreference = useSelector(getSelectedTheme);
    const backgroundColor = activeUserThemePreference == UserThemePreference.Dark ? "#1f1f1f" : "white";

    const [issue, setIssue] = useState<IssueDto | undefined>(undefined);
    // TODO: Set the workflow based on the current state
    const [workflowStep, setWorkflowStep] = useState(getInitialWorkflowStep());
    const [isUpdatingDefaultProject, setUpdatingDefaultProject] = useState(true);
    const [isUpdatingDefaultTeam, setUpdatingDefaultTeam] = useState(true);
    const [isUpdatingDefaultEpic, setUpdatingDefaultEpic] = useState(true);
    const [isUpdatingDefaultIssueType, setUpdatingDefaultIssueType] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setDefaultIssueState();
    }, [defaultIssueTypeKey, defaultProjectKey, defaultTeamKey, defaultEpicKey, props.isOpen]);

    const setDefaultIssueState = () => {
        const project = defaultProjectKey ? { projectKey: defaultProjectKey } : undefined;
        const team = defaultTeamKey ? { teamKey: defaultTeamKey } : undefined;
        const epic = defaultEpicKey ? { issueKey: defaultEpicKey } : undefined;
        const title = "";
        setIssue({ title, project, teamDto: team, issueType: defaultIssueTypeKey, epic: epic } as IssueDto);
    };

    const [saveIssue, { isLoading: isSaving }] = usePostIssueSaveMutation();

    function cancelEditing() {
        setWorkflowStep(getInitialWorkflowStep());
        setDefaultIssueState();
        props.toggle();
    }

    function handleOkButtonAction() {
        areRemainingStepsOptional() ? handleSave() : goToNextWorkflowStep();
    }

    function handleSave() {
        if (!!issue) {
            const dto = { ...issue!!, projectKey: issue.project?.projectKey, teamKey: issue.teamDto?.teamKey };
            delete dto.teamDto;
            delete dto.project;
            saveIssue({ saveIssueDto: dto })
                .unwrap()
                .then((payload) => {
                    cancelEditing();
                    notification.success({
                        message: "Issue Created",
                        description: "Your issue was successfully created!",
                        placement: "topRight",
                        duration: 3,
                    });
                })
                .catch((error) => {
                    notification.error({
                        message: "Error",
                        description: "There was an error creating your issue.",
                        placement: "topRight",
                        duration: 3,
                    });
                });
        }
    }

    function handleProjectSelected(project: ProjectDto) {
        if (isUpdatingDefaultProject) {
            dispatch(setDefaultProject(project.projectKey));
        }
        setIssue({ ...issue, project });
        goToNextWorkflowStep();
    }

    function handleTeamSelected(team: TeamDto) {
        if (isUpdatingDefaultTeam) {
            dispatch(setDefaultTeam(team.teamKey));
        }
        setIssue({ ...issue, teamDto: team });
        goToNextWorkflowStep();
    }

    function handleIssueTypeSelected(issueType: IssueType) {
        if (isUpdatingDefaultIssueType) {
            dispatch(setDefaultIssueType(issueType));
        }
        setIssue({ ...issue, issueType: issueType } as IssueDto);
        goToNextWorkflowStep(null, issueType);
    }

    function handleEpicSelected(epic: EpicDto) {
        if (isUpdatingDefaultEpic) {
            dispatch(setDefaultEpic(epic.issueKey));
        }
        setIssue({ ...issue, epic: epic });
        goToNextWorkflowStep();
    }

    function updateFormValue(e: any, property: keyof IssueDto) {
        const editedIssueToUpdate = { ...issue };
        editedIssueToUpdate[property] = e.target.value;
        setIssue(editedIssueToUpdate as IssueDto);
    }

    function onProjectDefaultToggle(e: any) {
        setUpdatingDefaultProject(e.target.checked);
    }

    function onTeamDefaultToggle(e: any) {
        setUpdatingDefaultTeam(e.target.checked);
    }

    function onIssueTypeDefaultToggle(e: any) {
        setUpdatingDefaultIssueType(e.target.checked);
    }

    function onEpicDefaultToggle(e: any) {
        setUpdatingDefaultEpic(e.target.checked);
    }

    function getCurrentWorkflowFormComponent(issue: IssueDto) {
        switch (workflowStep) {
            case CreateIssueWizardStep.ProjectSelect:
                return (
                    <IssueWizardProjectSelect
                        onProjectSelected={handleProjectSelected}
                        onDefaultClick={onProjectDefaultToggle}
                        isUpdatingDefaultProject={isUpdatingDefaultProject}
                    />
                );
            case CreateIssueWizardStep.TeamSelect:
                return (
                    <IssueWizardTeamSelect
                        onTeamSelected={handleTeamSelected}
                        onDefaultClick={onTeamDefaultToggle}
                        isUpdatingDefaultTeam={isUpdatingDefaultTeam}
                    />
                );
            case CreateIssueWizardStep.SelectIssueType:
                return (
                    <IssueWizardSelectIssueType
                        onIssueTypeSelected={handleIssueTypeSelected}
                        onDefaultClick={onIssueTypeDefaultToggle}
                        isUpdatingDefaultIssueType={isUpdatingDefaultIssueType}
                    />
                );
            case CreateIssueWizardStep.SelectEpic:
                return (
                    <IssueWizardEpicSelect
                        projectKey={issue.project?.projectKey!!}
                        onEpicSelected={handleEpicSelected}
                        onDefaultClick={onEpicDefaultToggle}
                        isUpdatingDefaultEpic={isUpdatingDefaultEpic}
                    />
                );
            case CreateIssueWizardStep.AddBasicInformation:
                return <IssueWizardBasicInfo issue={issue} onIssueEdit={updateFormValue} />;
        }
    }

    function getOkButtonLabel() {
        return areRemainingStepsOptional() ? "Save and Exit" : "Next";
    }

    function areRemainingStepsOptional() {
        return workflowStep >= CreateIssueWizardStep.AddBasicInformation;
    }

    function isLastWorkflowStep() {
        return workflowStep.valueOf() === CreateIssueWizardStep.AddBasicInformation.valueOf();
    }

    function back() {
        if (
            workflowStep === CreateIssueWizardStep.AddBasicInformation &&
            (issue?.issueType === IssueType.Epic || issue?.issueType === IssueType.Initiative)
        ) {
            setWorkflowStep(workflowStep.valueOf() - 2);
        } else {
            setWorkflowStep(workflowStep.valueOf() - 1);
        }
    }

    function canSkipToNextStep(workflowStep: CreateIssueWizardStep, nextIssueType?: IssueType) {
        return (
            (workflowStep === CreateIssueWizardStep.ProjectSelect && defaultIssueTypeKey && defaultTeamKey) ||
            (workflowStep === CreateIssueWizardStep.SelectIssueType &&
                (nextIssueType === IssueType.Epic || nextIssueType === IssueType.Initiative)) ||
            (workflowStep === CreateIssueWizardStep.TeamSelect && defaultIssueTypeKey)
        );
    }

    function goToNextWorkflowStep(e?: any, nextIssueType?: IssueType) {
        if (canSkipToNextStep(workflowStep, nextIssueType)) {
            setWorkflowStep(CreateIssueWizardStep.AddBasicInformation);
        } else {
            setWorkflowStep(workflowStep.valueOf() + 1);
        }
    }

    function getInitialWorkflowStep() {
        if (defaultProjectKey && defaultIssueTypeKey) {
            return CreateIssueWizardStep.AddBasicInformation;
        } else if (defaultProjectKey) {
            return CreateIssueWizardStep.SelectIssueType;
        }
        return CreateIssueWizardStep.ProjectSelect;
    }

    const footer = () => {
        return (
            <>
                <Button onClick={cancelEditing} style={{ float: "left" }} danger={true} type="primary">
                    Cancel
                </Button>
                {workflowStep !== CreateIssueWizardStep.ProjectSelect ? (
                    <Button type="primary" onClick={back}>
                        Back
                    </Button>
                ) : undefined}
                <Button type="primary" onClick={handleOkButtonAction}>
                    {getOkButtonLabel()}
                </Button>
                {areRemainingStepsOptional() && !isLastWorkflowStep() ? (
                    <Button type="primary" onClick={goToNextWorkflowStep}>
                        Add More Details
                    </Button>
                ) : undefined}
            </>
        );
    };
    return issue === undefined ? (
        <Spin />
    ) : (
        <Modal open={props.isOpen} footer={footer} width="768px" onCancel={cancelEditing}>
            <Layout>
                <Sider style={{ backgroundColor: backgroundColor, paddingRight: 20, textAlign: "right" }}>
                    <Steps
                        progressDot
                        current={workflowStep}
                        direction="vertical"
                        items={[
                            {
                                title: "Select Project",
                                onClick: () => {
                                    setWorkflowStep(CreateIssueWizardStep.ProjectSelect);
                                },
                            },
                            {
                                title: "Select Team",
                                onClick: () => {
                                    setWorkflowStep(CreateIssueWizardStep.TeamSelect);
                                },
                            },
                            {
                                title: "Select Issue Type",
                                onClick: () => {
                                    setWorkflowStep(CreateIssueWizardStep.SelectIssueType);
                                },
                            },
                            {
                                title: "Select Epic",
                                disabled:
                                    issue?.issueType === IssueType.Initiative || issue?.issueType === IssueType.Epic,
                                onClick: () => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    issue?.issueType !== IssueType.Initiative && issue?.issueType !== IssueType.Epic
                                        ? setWorkflowStep(CreateIssueWizardStep.SelectEpic)
                                        : undefined;
                                },
                            },
                            {
                                title: "Add Required Info",
                                onClick: () => {
                                    setWorkflowStep(CreateIssueWizardStep.AddBasicInformation);
                                },
                            },
                        ]}
                    />
                </Sider>
                <Layout style={{ backgroundColor: backgroundColor }}>{getCurrentWorkflowFormComponent(issue)}</Layout>
            </Layout>
        </Modal>
    );
};
