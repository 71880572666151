import { SprintStatus } from "../../types/SprintStatus";
import { ExistingSettingsSprintDto } from "../../../../store/generated/settings/sprint";

const hasOpenSprintForTeam = (allSprints: ExistingSettingsSprintDto[], teamKey: string) => {
    return allSprints.some((x) => x.teamKey === teamKey && x.status === SprintStatus.Open);
};

export const isSprintElligibleToOpen = (allSprints: ExistingSettingsSprintDto[], sprint: ExistingSettingsSprintDto) => {
    return !hasOpenSprintForTeam(allSprints, sprint.teamKey!!) && sprint.status === SprintStatus.Planned;
};

export const isSprintElligibleToClose = (
    allSprints: ExistingSettingsSprintDto[],
    sprint: ExistingSettingsSprintDto
) => {
    return sprint.status === SprintStatus.Open;
};
