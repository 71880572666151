import IssueType from "../../types/IssueType";
import React, { useEffect, useMemo, useState } from "react";
import { IssueDetailDto } from "../../../../store/generated/issue";
import { useDeleteIssueDeleteMutation, useGetIssueGetQuery, usePostIssueSaveMutation } from "../../api/IssueApi";
import { Button, Form, FormInstance, Input, Layout, Modal, Progress, Tabs, TabsProps } from "antd";
import { IssueHistoryComponent } from "./IssueHistoryComponent";
import { CommentThreadComponent } from "./CommentThreadComponent";
import { UserThemePreference } from "../../../../common/reducers/UserPreferenceSlice";
import { useSelector } from "react-redux";
import { getSelectedTheme } from "../../../../common/UserPreferenceSelectors";
import { useSearchParams } from "react-router-dom";
import { RelatedIssuesComponent } from "./RelatedIssuesComponent";
import { cloneDeep, omit } from "lodash";
import { IssueDetailsSidebar } from "./IssueDetailsSidebar";
import RichTextEditor from "../../../../common/components/RichTextEditor";
import useFormValidation from "../../../../common/components/useFormValidation";
import { isAllowedToDeleteIssue, isAllowedToSaveIssue } from "../../../../common/selectors/RoleSelectors";
import { useGetFeatureListQuery } from "../../../../store/generated/feature";
import Features from "../../../../common/enum/Features";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import useActiveBreakpoint from "../../../../common/hooks/useActiveBreakpoint";
import { isBreakpointAtLeast } from "../../../../common/utils/BreakpointUtils";
import Sider from "antd/es/layout/Sider";

type Props = {
    issueKey?: string;
    isOpen: boolean;
    toggle: () => void;
};

const initialFormState = {
    title: "",
    description: "",
    issueType: undefined,
    status: undefined,
} as IssueDetailDto;

export const DETAILS_PANE_HEIGHT = 615;
function GeneralInfoComponent(props: {
    form: FormInstance<any>;
    initialValues: IssueDetailDto;
    onTitleChange: (e: any) => void;
    onDescriptionChange: (e: any) => void;
}) {
    return (
        <Form form={props.form} initialValues={props.initialValues} name="control-hooks" layout={"vertical"}>
            {/* Moves up above tab bar*/}

            <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                <RichTextEditor
                    value={props.initialValues.description ?? ""}
                    onChange={(value) => {
                        props.onDescriptionChange(value);
                    }}
                />
            </Form.Item>
            {/* Buttons to Switch Text fields */}
        </Form>
    );
}

function IssueDetailsModal(props: Props) {
    const activeBreakpoint = useActiveBreakpoint();
    const canDeleteIssue = useSelector(isAllowedToDeleteIssue);
    const canSaveIssue = useSelector(isAllowedToSaveIssue);
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const activeUserThemePreference = useSelector(getSelectedTheme);
    const backgroundColor = activeUserThemePreference == UserThemePreference.Dark ? "#1f1f1f" : "white";
    const { data: features } = useGetFeatureListQuery();
    const {
        data,
        error,
        isLoading = true,
    } = useGetIssueGetQuery({
        projectIssueKeyLabel: props.issueKey,
    });
    const [deleteIssue, { isLoading: isDeleting }] = useDeleteIssueDeleteMutation();
    const [saveIssue, result] = usePostIssueSaveMutation();

    useFormValidation({ form, validationResult: result as any });
    const [editedIssue, setEditedIssue] = useState<IssueDetailDto | undefined>(undefined);
    const [activeTabKey, setActiveTabKey] = useState<string | undefined>();

    function onTabChange(tabKey: string) {
        setActiveTabKey(tabKey);
    }

    useEffect(() => {
        if (
            activeBreakpoint &&
            (activeTabKey === undefined ||
                (activeTabKey === "1" &&
                    (activeBreakpoint === "lg" || activeBreakpoint === "xl" || activeBreakpoint === "xxl")))
        ) {
            setActiveTabKey(!isBreakpointAtLeast(activeBreakpoint, "lg") ? "1" : "2");
        }
    }, [activeBreakpoint, activeTabKey]);

    const tabs = useMemo(() => {
        if (editedIssue) {
            const result: TabsProps["items"] = [
                {
                    key: "2",
                    label: "Info",
                    children: (
                        <GeneralInfoComponent
                            form={form}
                            initialValues={editedIssue!!}
                            onTitleChange={(e) => updateFormValue(e, "title")}
                            onDescriptionChange={(e) => updateFormValue(e, "description")}
                        />
                    ),
                },
                {
                    key: "3",
                    label: "Epic Issues",
                    children: <RelatedIssuesComponent issues={editedIssue?.epicIssues!!} />,
                },
            ];

            if (!isBreakpointAtLeast(activeBreakpoint ?? "lg", "lg")) {
                result.push({
                    key: "1",
                    label: "Details",
                    children: (
                        <IssueDetailsSidebar
                            formInstance={form}
                            backgroundColor={backgroundColor}
                            editedIssue={editedIssue}
                            onValueChange={(value: any, field: keyof IssueDetailDto) =>
                                updateFormValueFromRaw(value, field)
                            }
                            span={
                                activeBreakpoint === "xl"
                                    ? 6
                                    : activeBreakpoint === "lg"
                                      ? 8
                                      : activeBreakpoint === "md"
                                        ? 12
                                        : 24
                            }
                            gutter={2}
                        />
                    ),
                });
            }
            if (features?.some((x) => x.featureKey === Features.IssueCommentary && x.isEnabled)) {
                result.push({
                    key: "4",
                    label: "Comments",
                    children: <CommentThreadComponent />,
                });
            }
            if (features?.some((x) => x.featureKey === Features.IssueHistory && x.isEnabled)) {
                result.push({
                    key: "5",
                    label: "History",
                    children: <IssueHistoryComponent />,
                });
            }
            return result;
        }
    }, [editedIssue, form, activeBreakpoint, features, updateFormValue, backgroundColor, updateFormValueFromRaw]);

    useEffect(() => {
        if (props.isOpen && props.issueKey && !isLoading && data) {
            setEditedIssue(data);
            form.setFieldsValue(data ?? initialFormState);
        }
    }, [props.isOpen, props.issueKey, isLoading]);

    function updateFormValue(e: any, property: keyof IssueDetailDto, dropdownType?: any) {
        const editedIssueToUpdate = { ...editedIssue };
        editedIssueToUpdate[property] = !!dropdownType ? dropdownType[e] : typeof e === "string" ? e : e.target.value;
        setEditedIssue(editedIssueToUpdate as IssueDetailDto);
    }

    function updateFormValueFromRaw(value: any, property: keyof IssueDetailDto) {
        const editedIssueToUpdate = { ...editedIssue };
        editedIssueToUpdate[property] = value;
        setEditedIssue(editedIssueToUpdate as IssueDetailDto);
    }

    function cancelEditing() {
        searchParams.delete("selectedIssue");
        setSearchParams(searchParams);
        props.toggle();
        setEditedIssue(data);
    }

    function handleDeleteIssue() {
        if (!!props.issueKey) {
            deleteIssue({ issueKey: editedIssue?.issueKey!! });
            props.toggle();
        }
    }

    function handleSave() {
        if (!!editedIssue) {
            saveIssue({
                saveIssueDto: {
                    ...cloneDeep(
                        omit(editedIssue, [
                            "epicIssues",
                            "team",
                            "assignee",
                            "project",
                            "techLead",
                            "qaLead",
                            "productLead",
                        ])
                    )!!,
                    teamKey: editedIssue.teamDto?.teamKey,
                    projectKey: editedIssue.project?.projectKey,
                    assigneeKey: editedIssue.assignee?.tenantUserKey,
                    developerKey: editedIssue.developer?.tenantUserKey,
                    qaReviewerKey: editedIssue.qaReviewer?.tenantUserKey,
                    peerReviewerKey: editedIssue.peerReviewer?.tenantUserKey,
                    sprintKey: editedIssue.sprintDto?.sprintKey,
                    epicKey: editedIssue.epic?.issueKey,
                },
            });
            props.toggle();
        }
    }

    const footer = () => {
        return (
            <>
                {canDeleteIssue ? (
                    <Button type="primary" onClick={handleDeleteIssue} danger={true} style={{ float: "left" }}>
                        Delete
                    </Button>
                ) : undefined}
                <Button className="ms-auto" type="text" onClick={cancelEditing}>
                    Cancel
                </Button>
                {canSaveIssue ? (
                    <Button type="primary" onClick={handleSave}>
                        Save
                    </Button>
                ) : undefined}
            </>
        );
    };

    return !!editedIssue ? (
        <Modal open={props.isOpen} footer={footer} onCancel={cancelEditing} width="968px">
            <Layout>
                {isBreakpointAtLeast(activeBreakpoint ?? "lg", "lg") ? (
                    <Sider
                        style={{ backgroundColor: backgroundColor, paddingRight: 20, textAlign: "right" }}
                        width={300}
                    >
                        <IssueDetailsSidebar
                            formInstance={form}
                            backgroundColor={backgroundColor}
                            editedIssue={editedIssue}
                            onValueChange={(value: any, field: keyof IssueDetailDto) =>
                                updateFormValueFromRaw(value, field)
                            }
                            span={24}
                            gutter={2}
                        />
                    </Sider>
                ) : undefined}
                <Layout style={{ backgroundColor: backgroundColor }}>
                    <Form.Item name="title" rules={[{ required: true }]} style={{ paddingRight: 20 }}>
                        <Input
                            type="text"
                            defaultValue={editedIssue.title ?? ""}
                            onChange={(e) => updateFormValue(e, "title", undefined)}
                        />
                    </Form.Item>
                    {(editedIssue?.issueType ?? IssueType.Task) === IssueType.Task ? undefined : (
                        <Tabs
                            activeKey={activeTabKey}
                            onChange={onTabChange}
                            items={tabs}
                            tabPosition="bottom"
                            type="card"
                            style={{ height: DETAILS_PANE_HEIGHT, maxHeight: DETAILS_PANE_HEIGHT }}
                        />
                    )}
                </Layout>
            </Layout>
        </Modal>
    ) : (
        <Progress />
    );
}

export default IssueDetailsModal;
