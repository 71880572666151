import { useGetSettingsProjectListQuery } from "../../../store/generated/settings/project";
import { useGetSettingsTeamListQuery } from "../../../store/generated/settings/team";
import { NavMenuProjectSelect } from "./NavMenuProjectSelect";
import { NavMenuTeamSelect } from "./NavMenuTeamSelect";
import { Space } from "antd";

export const NavMenuSelectors = () => {
    const { data: projects, isLoading: isProjectsLoading } = useGetSettingsProjectListQuery();
    const { data: teams, isLoading: isTeamsLoading } = useGetSettingsTeamListQuery();

    if (isProjectsLoading || isTeamsLoading) {
        return undefined;
    }

    if ((projects?.length ?? 0) === 1 && (teams?.length ?? 0) === 1) {
        return (
            <span style={{ color: "white" }}>
                {projects!![0].name} - {teams!![0].name}
            </span>
        );
    }

    return (
        <Space>
            <NavMenuProjectSelect projects={projects!!} />
            <NavMenuTeamSelect teams={teams!!} />
        </Space>
    );
};
