import { emptySplitApi as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getFeatureList: build.query<GetFeatureListApiResponse, GetFeatureListApiArg>({
            query: () => ({ url: `/Feature/List` }),
        }),
        putFeatureUpdate: build.mutation<PutFeatureUpdateApiResponse, PutFeatureUpdateApiArg>({
            query: (queryArg) => ({
                url: `/Feature/Update`,
                method: "PUT",
                body: queryArg.featureDto,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetFeatureListApiResponse = /** status 200 Success */ FeatureDto[];
export type GetFeatureListApiArg = void;
export type PutFeatureUpdateApiResponse = /** status 200 Success */ boolean;
export type PutFeatureUpdateApiArg = {
    featureDto: FeatureDto;
};
export type FeatureDto = {
    featureKey?: number;
    name?: string | null;
    isEnabled?: boolean;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export const { useGetFeatureListQuery, usePutFeatureUpdateMutation } = injectedRtkApi;
