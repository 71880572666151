enum Features {
    Dashboards = 1,
    Reports,
    Imports,
    IssueCommentary,
    IssueHistory,
    Onboarding,
}

export default Features;
