import { Select } from "antd";
import React from "react";
import { SprintDto } from "../../../../store/generated/issue";
import { useGetSettingsSprintListQuery } from "../../../../store/generated/settings/sprint";

export function EditableSprintInput(props: {
    sprint: SprintDto | undefined;
    onSprintUpdate: (value: SprintDto) => void;
}) {
    const { data } = useGetSettingsSprintListQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.map((x) => ({ value: x.sprintKey, label: x.name })),
            error,
            isLoading,
        }),
    });

    function onSprintUpdate(selected: any) {
        props.onSprintUpdate({ sprintKey: selected } as SprintDto);
    }

    return (
        <Select
            allowClear={true}
            onClear={() => onSprintUpdate(undefined)}
            options={data}
            defaultValue={{ value: props.sprint?.sprintKey, label: props.sprint?.name }}
            popupMatchSelectWidth={false}
            defaultOpen={false}
            onSelect={onSprintUpdate}
            style={{ maxWidth: 140, width: 140, textAlign: "center" }}
        />
    );
}
