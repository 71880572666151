import React from "react";
import { Form, Select } from "antd";
import { AvailableValue, ReportDefinitionDto } from "../../../store/generated/reports";

enum ReportType {
    User = "UserParameter",
}
function ReportParametersSubform(props: { selectedReport: ReportDefinitionDto }) {
    function onInputChange() {}
    function getInputForType(
        name: string,
        type: ReportType,
        defaultValue: any,
        availableValues?: AvailableValue[] | null | undefined
    ) {
        switch (type) {
            case ReportType.User:
                return (
                    <Select onChange={onInputChange}>
                        {availableValues!!.map((availableValue, index) => {
                            return (
                                <Select.Option key={index} value={availableValue.value}>
                                    {availableValue.label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                );
        }
    }

    return (
        <>
            {props.selectedReport.parameters?.map((x) => {
                return (
                    <Form.Item
                        key={x.name}
                        label={x.name}
                        name={x.name!!}
                        rules={[{ required: x.required, message: "Please input!" }]}
                    >
                        {getInputForType(x.name!!, (x.type as any as ReportType)!!, x.defaultValue, x.availableValues)}
                    </Form.Item>
                );
            })}
        </>
    );
}

export default ReportParametersSubform;
