import React from "react";
import { Button, Checkbox } from "antd";
import { useSelector } from "react-redux";
import { getDefaultEpicKey, getDefaultProjectKey } from "../../selectors/BacklogSelectors";
import { EpicDto, useGetIssueListEpicsQuery } from "../../../../store/generated/issue";

type Props = {
    projectKey: string;
    isUpdatingDefaultEpic: boolean;
    onEpicSelected: (epic: EpicDto) => void;
    onDefaultClick: React.MouseEventHandler<HTMLElement>;
};

export const IssueWizardEpicSelect = (props: Props) => {
    const defaultEpicKey = useSelector(getDefaultEpicKey);

    const { filteredData, error, isLoading } = useGetIssueListEpicsQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            filteredData: data?.filter((x) => x.projectKey == props.projectKey),
            error,
            isLoading,
        }),
    });

    return filteredData ? (
        <>
            {filteredData.map((x) => {
                return (
                    <Button
                        key={x.issueKey}
                        className={defaultEpicKey === x.issueKey ? "btn-current-default" : undefined}
                        onClick={() => props.onEpicSelected(x as EpicDto)}
                        style={{ marginBottom: 5 }}
                    >
                        {x.title}
                    </Button>
                );
            })}
            <Checkbox
                style={{ paddingTop: 10, alignSelf: "flex-end" }}
                onClick={props.onDefaultClick}
                checked={props.isUpdatingDefaultEpic}
            >
                Use as Default
            </Checkbox>
        </>
    ) : undefined;
};
