import { RootState } from "../../../index";
import { createSelector } from "@reduxjs/toolkit";
import { IssueDto } from "../../../store/generated/issue";
import StatusType from "../../../common/types/StatusType";
import { groupBy } from "lodash";

const getBacklog = (state: RootState) => state.backlog;
export const getFilteredProject = createSelector(getBacklog, (backlog) => backlog.filterState.project);
export const getFilteredTeam = createSelector(getBacklog, (backlog) => backlog.filterState.team);
export const getFilteredStatus = createSelector(getBacklog, (backlog) => backlog.filterState.status);
export const getFilteredEpic = createSelector(getBacklog, (backlog) => backlog.filterState.epic);
export const getFilteredUsers = createSelector(getBacklog, (backlog) => backlog.filterState.users);

export const getDefaultProjectKey = (state: RootState) => state.backlog.defaultState.projectKey;
export const getDefaultTeamKey = (state: RootState) => state.backlog.defaultState.teamKey;
export const getDefaultIssueTypeKey = (state: RootState) => state.backlog.defaultState.issueTypeKey;

export const getDefaultEpicKey = (state: RootState) => state.backlog.defaultState.epicKey;

export const hasAppliedViewOptions = createSelector(
    [getFilteredProject, getFilteredTeam, getFilteredEpic, getFilteredUsers, getFilteredStatus],
    (filteredProject, filteredTeam, filteredEpic, filteredUsers, filteredStatus) => {
        return !!filteredProject || !!filteredStatus || !!filteredTeam || !!filteredEpic || filteredUsers.length > 0;
    }
);

export const getFilteredIssues = createSelector(
    [
        getFilteredProject,
        getFilteredTeam,
        getFilteredEpic,
        getFilteredUsers,
        getFilteredStatus,
        (state, data: IssueDto[]) => data,
    ],
    (filteredProject, filteredBacklog, filteredEpic, filteredUsers, filteredStatus, data) => {
        if (!hasAppliedViewOptions) {
            return data;
        }
        return data
            .filter((issue) => {
                return (
                    issue !== undefined &&
                    (filteredStatus === undefined ||
                        ((filteredStatus as any as StatusType) == StatusType.NotDone &&
                            issue.status !== StatusType.Done) ||
                        issue.status?.toString() === filteredStatus.toString()) &&
                    (filteredEpic === undefined ||
                        filteredEpic.key === "" ||
                        issue.epic?.issueKey === filteredEpic?.key) &&
                    (filteredUsers.length === 0 || filteredUsers.some((x) => x.value === issue.assignee?.tenantUserKey))
                );
            })
            .map((issue) => {
                return { ...issue };
            });
    }
);
