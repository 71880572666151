import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { usePostSettingsTenantUserAcceptInviteMutation } from "../../../store/generated/settings/tenant";

const LinkInvitePage = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    const tenant = searchParams.get("tenant");
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate();
    const [acceptInvite, { isLoading, error, isError }] = usePostSettingsTenantUserAcceptInviteMutation();

    useEffect(() => {
        acceptInvite({ acceptSettingsUserInviteDto: { tenantKey: tenant!! } });
        setSubmitted(true);
    }, []);

    useEffect(() => {
        if (submitted && !isLoading) {
            navigate("/");
        }
    }, [isLoading]);

    // TODO: Feedback
    return <></>;
};

export default LinkInvitePage;
