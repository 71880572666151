import { emptySplitApi as api } from "../../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSettingsReleaseGet: build.query<GetSettingsReleaseGetApiResponse, GetSettingsReleaseGetApiArg>({
            query: (queryArg) => ({
                url: `/settings/Release/Get`,
                params: { ReleaseKey: queryArg.releaseKey },
            }),
        }),
        getSettingsReleaseList: build.query<GetSettingsReleaseListApiResponse, GetSettingsReleaseListApiArg>({
            query: () => ({ url: `/settings/Release/List` }),
        }),
        postSettingsReleaseAdd: build.mutation<PostSettingsReleaseAddApiResponse, PostSettingsReleaseAddApiArg>({
            query: (queryArg) => ({
                url: `/settings/Release/Add`,
                method: "POST",
                body: queryArg.addSettingsReleaseDto,
            }),
        }),
        putSettingsReleaseUpdate: build.mutation<PutSettingsReleaseUpdateApiResponse, PutSettingsReleaseUpdateApiArg>({
            query: (queryArg) => ({
                url: `/settings/Release/Update`,
                method: "PUT",
                body: queryArg.existingSettingsReleaseDto,
            }),
        }),
        deleteSettingsReleaseDelete: build.mutation<
            DeleteSettingsReleaseDeleteApiResponse,
            DeleteSettingsReleaseDeleteApiArg
        >({
            query: (queryArg) => ({
                url: `/settings/Release/Delete`,
                method: "DELETE",
                params: { ReleaseKey: queryArg.releaseKey },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetSettingsReleaseGetApiResponse = /** status 200 Success */ ExistingSettingsReleaseDto;
export type GetSettingsReleaseGetApiArg = {
    releaseKey?: string;
};
export type GetSettingsReleaseListApiResponse = /** status 200 Success */ ExistingSettingsReleaseDto[];
export type GetSettingsReleaseListApiArg = void;
export type PostSettingsReleaseAddApiResponse = /** status 200 Success */ string;
export type PostSettingsReleaseAddApiArg = {
    addSettingsReleaseDto: AddSettingsReleaseDto;
};
export type PutSettingsReleaseUpdateApiResponse = /** status 200 Success */ undefined;
export type PutSettingsReleaseUpdateApiArg = {
    existingSettingsReleaseDto: ExistingSettingsReleaseDto;
};
export type DeleteSettingsReleaseDeleteApiResponse = /** status 200 Success */ undefined;
export type DeleteSettingsReleaseDeleteApiArg = {
    releaseKey?: string;
};
export type ReleaseStatus = 0 | 1 | 2;
export type ExistingSettingsReleaseDto = {
    releaseKey?: string;
    name?: string | null;
    status?: ReleaseStatus;
    notes?: string | null;
    order?: number;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string;
    isDeleted?: boolean;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type AddSettingsReleaseDto = {
    releaseKey?: string | null;
    name?: string | null;
    status?: ReleaseStatus;
    notes?: string | null;
    order?: number;
    tenantKey?: string;
    createDateTime?: string;
    deleteDateTime?: string;
    isDeleted?: boolean;
};
export const {
    useGetSettingsReleaseGetQuery,
    useGetSettingsReleaseListQuery,
    usePostSettingsReleaseAddMutation,
    usePutSettingsReleaseUpdateMutation,
    useDeleteSettingsReleaseDeleteMutation,
} = injectedRtkApi;
