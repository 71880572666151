import React from "react";
import { Button, Drawer, Tabs, TabsProps } from "antd";
import EpicDrawerFilter from "./EpicDrawerFilter";
import ProjectDrawerFilter from "./ProjectDrawerFilter";
import StatusDrawerFilter from "./StatusDrawerFilter";
import UserDrawerFilter from "./UserDrawerFilter";
import { ClearOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { resetBacklogState } from "../../reducers/BacklogSlice";
import { hasAppliedViewOptions } from "../../selectors/BacklogSelectors";
import TeamDrawerFilter from "./TeamDrawerFilter";

type FilterDrawerParams = {
    open: boolean;
    onClose: () => void;
};

const items: TabsProps["items"] = [
    {
        key: "projects",
        label: "Projects",
        children: <ProjectDrawerFilter />,
    },
    {
        key: "teams",
        label: "Teams",
        children: <TeamDrawerFilter />,
    },
    {
        key: "epics",
        label: "Epics",
        children: <EpicDrawerFilter />,
    },
    {
        key: "status",
        label: "Status",
        children: <StatusDrawerFilter />,
    },
    {
        key: "user",
        label: "User",
        children: <UserDrawerFilter />,
    },
];

function FilterDrawer(props: FilterDrawerParams) {
    const dispatch = useDispatch();
    const hasAnyAppliedViewOptions = useSelector(hasAppliedViewOptions);
    return (
        <Drawer
            title="View Options"
            placement="left"
            closable={true}
            open={props.open}
            onClose={props.onClose}
            getContainer={false}
            extra={
                <Button disabled={!hasAnyAppliedViewOptions} onClick={() => dispatch(resetBacklogState())}>
                    Clear All <ClearOutlined />
                </Button>
            }
        >
            <Tabs items={items} />
        </Drawer>
    );
}
export default FilterDrawer;
