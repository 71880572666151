import { UserDto } from "../../store/generated/user";
import { Avatar } from "antd";
import React from "react";

type Props = {
    user: UserDto;
};

const UserAvatar = (props: Props) => {
    return (
        <Avatar src={props.user.avatarUrl} style={{ alignSelf: "center" }}>
            {props.user.initials}
        </Avatar>
    );
};

export default UserAvatar;
