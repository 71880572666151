import { Form, Modal } from "antd";
import { Header } from "antd/es/layout/layout";
import { UserThemePreference } from "../../../../common/reducers/UserPreferenceSlice";
import * as React from "react";
import { WarningOutlined } from "@ant-design/icons";
import { yellow } from "@ant-design/colors";

type OpenSprintModalProps = {
    open: boolean;
    onCancel: () => void;
    onOk: () => void;
    submittable: boolean;
    activeUserThemePreference: UserThemePreference;
    unpointedIssues?: number;
};

export function OpenSprintModal(props: OpenSprintModalProps) {
    return (
        <Modal
            open={props.open}
            okText="Open Sprint"
            onCancel={props.onCancel}
            onOk={props.onOk}
            okButtonProps={{ disabled: !props.submittable }}
        >
            <Form
                //form={props.form}
                name="control-hooks"
                style={{ maxWidth: 600 }}
                initialValues={props}
            >
                <Header
                    style={{
                        paddingLeft: 0,
                        backgroundColor:
                            props.activeUserThemePreference == UserThemePreference.Dark ? "#1f1f1f" : "white",
                    }}
                >
                    Open Sprint?
                </Header>
                {props.unpointedIssues ? (
                    <div>
                        <WarningOutlined style={{ color: yellow[5] }} /> There are {props.unpointedIssues} unpointed
                        stories, are you sure you want to open this sprint?
                    </div>
                ) : (
                    <div>Are you sure you want to open this sprint?</div>
                )}
            </Form>
        </Modal>
    );
}
