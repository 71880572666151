import { enhancedApi as api } from "../../../../store/generated/settings/team";

const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ["Team"],
    endpoints: {
        getSettingsTeamList: {
            providesTags: ["Team"],
        },
        getSettingsTeamGet: {
            providesTags: ["Team"],
        },
        postSettingsTeamAdd: {
            invalidatesTags: ["Team"],
        },
        putSettingsTeamUpdate: {
            invalidatesTags: ["Team"],
        },
        deleteSettingsTeamDelete: {
            invalidatesTags: ["Team"],
        },
    },
});

export const {
    useGetSettingsTeamListQuery,
    useGetSettingsTeamGetQuery,
    usePostSettingsTeamAddMutation,
    usePutSettingsTeamUpdateMutation,
    useDeleteSettingsTeamDeleteMutation,
} = enhancedApi;
