import { createEvent } from "react-event-hook";

const { emitShowCreateIssueModal, useShowCreateIssueModalListener } = createEvent("showCreateIssueModal")<{
    projectIssueKeyedLabel?: string;
}>({ crossTab: true });
const { emitShowImportIssueModal, useShowImportIssueModalListener } = createEvent("showImportIssueModal")({
    crossTab: true,
});
const { emitShowCreateReportModal, useShowCreateReportModalListener } = createEvent("showCreateReportModal")({
    crossTab: true,
});
const { emitShowViewOptionsDrawer, useShowViewOptionsDrawerListener } = createEvent("showViewOptionsDrawer")({
    crossTab: true,
});
const { emitShowOnboardingTasksModal, useShowOnboardingTasksModalListener } = createEvent("showOnboardingTasksModal")({
    crossTab: true,
});

export {
    emitShowCreateIssueModal,
    useShowCreateIssueModalListener,
    emitShowImportIssueModal,
    useShowImportIssueModalListener,
    emitShowViewOptionsDrawer,
    useShowViewOptionsDrawerListener,
    emitShowCreateReportModal,
    useShowCreateReportModalListener,
    emitShowOnboardingTasksModal,
    useShowOnboardingTasksModalListener,
};
