import { Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { SettingsUserInviteDto, usePostSettingsTenantUserInviteMutation } from "../../store/generated/settings/tenant";

export function UserInviteModal(props: { open: boolean; onOk: () => void; onCancel: () => void }) {
    const [disabledSave, setDisabledSave] = useState(true);
    const [formValues, setFormValues] = useState<SettingsUserInviteDto>({});
    const [form] = useForm();
    const [inviteUser, { isLoading: isSaving }] = usePostSettingsTenantUserInviteMutation();

    const handleFormChange = (values: any) => {
        const updatedValues = { ...formValues } as any;
        values.forEach((x: any) => {
            updatedValues[x.name] = x.value;
        });
        setFormValues(updatedValues);
        setDisabledSave(values.some((x: any) => x.errors?.length !== 0));
    };

    const onOk = () => {
        inviteUser({
            settingsUserInviteDto: {
                email: formValues["email"],
            },
        });
        props.onOk();
    };

    return (
        <Modal
            title="Invite User to Workspace"
            open={props.open}
            onOk={onOk}
            onCancel={props.onCancel}
            okButtonProps={{ disabled: disabledSave }}
        >
            <Form preserve={false} onFieldsChange={handleFormChange}>
                <Form.Item name={["email"]} label="Email" required={true} rules={[{ type: "email" }]}>
                    <Input type="email" required={true} />
                </Form.Item>
            </Form>
        </Modal>
    );
}
