import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Button, Table } from "antd";
import { useGetSettingsSprintListQuery } from "../api/SprintApi";
import { ExistingSettingsSprintDto, TeamDto } from "../../../../store/generated/settings/sprint";
import SuccessButton from "../../../../common/components/SuccessButton";
import { Content } from "antd/es/layout/layout";
import ManageSprintModal from "./ManageSprintModal";
import { isSprintElligibleToClose, isSprintElligibleToOpen } from "../utils/SprintUtils";
import { orderBy } from "lodash";

const SprintListPage = () => {
    const [sprintModalProps, setSprintModalProps] = useState<{ sprintKey: string | undefined }>({
        sprintKey: undefined,
    });
    const { data, error, isLoading } = useGetSettingsSprintListQuery(undefined, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: orderBy(data, "plannedStartDate"),
            error: error,
            isLoading: isLoading,
        }),
    });
    const navigate = useNavigate();
    const onEdit = (sprint?: ExistingSettingsSprintDto) => {
        if (sprint) {
            navigate(`/settings/sprint/details?sprintKey=${sprint?.sprintKey ?? ""}`);
        } else {
            navigate(`/settings/sprint/details`);
        }
    };

    function getAvailableActionButtonForStatus(
        sprints: ExistingSettingsSprintDto[],
        sprint: ExistingSettingsSprintDto
    ) {
        if (isSprintElligibleToClose(sprints, sprint)) {
            return (
                <div style={{ textAlign: "center" }}>
                    <SuccessButton
                        title="Close"
                        onClick={() => {
                            setSprintModalProps({ sprintKey: sprint.sprintKey });
                        }}
                    />
                </div>
            );
        } else if (isSprintElligibleToOpen(sprints, sprint)) {
            return (
                <div style={{ textAlign: "center" }}>
                    <SuccessButton
                        title="Open"
                        onClick={() => {
                            setSprintModalProps({ sprintKey: sprint.sprintKey });
                        }}
                    />
                </div>
            );
        }
        return undefined;
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Start Date",
            dataIndex: "plannedStartDate",
            key: "startDate",
        },
        {
            title: "End Date",
            dataIndex: "plannedEndDate",
            key: "endDate",
        },
        {
            title: "Team",
            dataIndex: "team",
            key: "team",
            render: (team: TeamDto) => team.name,
        },
        {
            title: "",
            dataIndex: "",
            key: "status",
            render: (sprint: ExistingSettingsSprintDto) => getAvailableActionButtonForStatus(data ?? [], sprint),
        },
    ];

    return (
        <Content style={{ marginTop: 15 }}>
            <div style={{ display: "flex", marginBottom: 15, justifyContent: "end" }}>
                <Button onClick={() => onEdit()}>Add</Button>
            </div>
            <Table
                dataSource={data}
                columns={columns}
                rowKey={(record) => record.sprintKey!!}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            onEdit(record);
                        }, // click row
                    };
                }}
            />
            {sprintModalProps.sprintKey ? (
                <ManageSprintModal
                    sprintKey={sprintModalProps.sprintKey!!}
                    open={!!sprintModalProps.sprintKey}
                    onClose={() => setSprintModalProps({ ...sprintModalProps, sprintKey: undefined })}
                />
            ) : undefined}
        </Content>
    );
};

export default SprintListPage;
