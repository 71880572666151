import * as React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setCurrentProject } from "../../common/reducers/ProjectSlice";

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const goToBackLog = (projectKey: string) => {
        dispatch(setCurrentProject(projectKey));
        navigate(`/backlog-management/backlog`);
    };
    return (
        <></>
        /*
        data?.map(
            (project) => {
                return <Card style={{ width: '18rem' }}>
                    <Card.Body>
                        <Card.Title>{project.name} Happenings</Card.Title>
                        <ListGroup variant="flush">
                            <ListGroup.Item><Link to={""}>Story was updated by Nate</Link></ListGroup.Item>
                            <ListGroup.Item><Link to={""}>Epic was updated by Jake</Link></ListGroup.Item>
                            <ListGroup.Item><Link to={""}>Bug was Create by Nate</Link></ListGroup.Item>
                        </ListGroup>
                        <Button variant="primary" onClick={() => goToBackLog(project.projectKey!!)}>Project Backlog</Button>
                    </Card.Body>
                </Card>
            }
        )
        */
    );
};

export default Home;
