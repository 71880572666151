export enum SprintStatus {
    Planned,
    Open,
    Closed,
}

export const mapSprintStatusToFriendlyName = (status: any) => {
    switch (status) {
        case 0:
            return "Planned";
        case 1:
            return "Open";
        case 2:
            return "Closed";
    }
};
