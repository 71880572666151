import { Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentRole } from "../../../common/reducers/CompanySlice";
import { getAvailableRoles, getSelectedRole } from "../../../common/selectors/RoleSelectors";
import { usePutIssueUpdateIssueOrderMutation } from "../../backlog-management/api/IssueApi";
import { usePutSettingsTenantUserChangeRoleMutation } from "../../../store/generated/settings/tenant";

const RoleSelect = () => {
    const selectedRole = useSelector(getSelectedRole);
    const availableRoles = useSelector(getAvailableRoles);

    const dispatch = useDispatch();
    const [changeRole, result] = usePutSettingsTenantUserChangeRoleMutation();

    const items: MenuProps["items"] = availableRoles.map((role: any) => ({
        key: role.roleKey,
        label: role.name,
    }));

    const onSelectRole = (e: any) => {
        changeRole({
            changeTenantUserRoleDto: {
                roleKey: e.key,
            },
        }).then(() => {
            dispatch(setCurrentRole(e.key));
        });
    };

    return (
        <Dropdown menu={{ items, onClick: onSelectRole, selectable: true }} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    {selectedRole?.name ?? "Select Role"}
                    <DownOutlined />
                </Space>
            </a>
        </Dropdown>
    );
};

export default RoleSelect;
