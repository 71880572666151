import { Breakpoint } from "antd";

export function isBreakpointAtLeast(breakpointA: Breakpoint, breakpointB: Breakpoint) {
    if (breakpointA === "xxl") {
        return true;
    } else if (breakpointA === "xl") {
        return breakpointB !== "xxl";
    } else if (breakpointA === "lg") {
        return breakpointB !== "xl" && breakpointB !== "lg";
    } else if (breakpointA === "md") {
        return breakpointB !== "xxl" && breakpointB !== "xl" && breakpointB !== "lg";
    } else if (breakpointA === "sm") {
        return breakpointB === "sm" || breakpointB === "xs";
    } else if (breakpointA === "xs") {
        return breakpointB === "xs";
    }
}
