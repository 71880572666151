import { enhancedApi as api } from "../../../../store/generated/settings/tenant";

const enhancedApi = api.enhanceEndpoints({
    addTagTypes: ["Tenant"],
    endpoints: {
        getSettingsTenantList: {
            providesTags: ["Tenant"],
        },
        getSettingsTenantGet: {
            providesTags: ["Tenant"],
        },
        postSettingsTenantAdd: {
            invalidatesTags: ["Tenant"],
        },
        putSettingsTenantUpdate: {
            invalidatesTags: ["Tenant"],
        },
        deleteSettingsTenantDelete: {
            invalidatesTags: ["Tenant"],
        },
    },
});

export const {
    useGetSettingsTenantListQuery,
    useGetSettingsTenantGetQuery,
    usePostSettingsTenantAddMutation,
    usePutSettingsTenantUpdateMutation,
    useDeleteSettingsTenantDeleteMutation,
} = enhancedApi;
