import React from "react";
import { Select } from "antd";
import { UserDto } from "../../../../store/generated/user";
import { TeamDto } from "../../../../store/generated/issue";
import { useGetSettingsTeamListQuery } from "../../../settings/teams/api/TeamApi";

type Props = {
    team?: TeamDto;
    onSelect: (team: TeamDto) => void;
};

export const IssueTeamSelect = (props: Props) => {
    const { data, error, isLoading } = useGetSettingsTeamListQuery();

    function onUserSelect(selected: any) {
        props.onSelect(data?.find((x) => x.teamKey === selected)!!);
    }

    return (
        <Select
            defaultValue={props.team ? { value: props.team?.teamKey, label: props.team?.name } : undefined}
            popupMatchSelectWidth={false}
            defaultOpen={false}
            optionLabelProp="label"
            onSelect={onUserSelect}
            onClear={() => onUserSelect(undefined)}
            allowClear={true}
            options={data?.map((team) => ({
                value: team.teamKey,
                label: team.name,
            }))}
            style={{ maxWidth: 80, width: 80, textAlign: "center" }}
        />
    );
};
