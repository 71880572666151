export enum PriorityType {
    Low,
    Medium,
    High,
    Critical,
}

export const getPriorityTypeColor = (priorityType: PriorityType) => {
    switch (priorityType) {
        case 0:
            return "green";
        case 1:
            return "blue";
        case 2:
            return "yellow";
        case 3:
            return "red";
    }
};

export function mapPriorityTypeToLabel(priorityType: PriorityType) {
    switch (priorityType) {
        case 0:
            return "Low";
        case 1:
            return "Medium";
        case 2:
            return "High";
        case 3:
            return "Critical";
    }
}
