import { emptySplitApi as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUserList: build.query<GetUserListApiResponse, GetUserListApiArg>({
            query: (queryArg) => ({
                url: `/User/List`,
                params: { projectKey: queryArg.projectKey },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetUserListApiResponse = /** status 200 Success */ UserDto[];
export type GetUserListApiArg = {
    projectKey?: string;
};
export type UserDto = {
    userKey?: string;
    tenantUserKey?: string;
    fullName?: string | null;
    initials?: string | null;
    avatarUrl?: string | null;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export const { useGetUserListQuery } = injectedRtkApi;
